import React, { useEffect, useMemo } from 'react';
import "./Card.scss";
import { Fragment } from "react";
import GalleryPreviewSelection from 'App/Pages/NftGallery/Component/GalleryPreviewSelection';
import GalleryCardEdit from 'App/Pages/NftGallery/Component/GalleryCardEdit';
// import logoSrc from "Assets/Images/Logo.svg";
import assetCountIcon from "Assets/Icons/asset-count-icon.svg"
import anywallImage from "Assets/Icons/anywall-logo-image.png";
import Logo from '../Logo';
import { getMetaThumbImageById } from 'App/Helper/utilities';
import { useStore } from 'App/hooks-store/store';

const Card = ({ showProfileIcon= false,page, cardProp, showCollectorName, type,tab, preview, handlePreview,selectedCollection="",setPrimaryHandler=()=>{} ,handleCardClick = false, showEdit = false, handleEdit, onClickDeleteGallery,  onCancel, hideDetails, showName, className, editGallery, displayPreview, userDetails }) => {
    const { currentUserId }= useStore(false)[0];

    const handleClick = (e, disable) => {
        if (handleCardClick && !disable) {
            handleCardClick(e.currentTarget?.dataset?.id, e.currentTarget?.dataset?.slug,e.currentTarget?.dataset?.communityslug)
        }
    }
    const onClickDelete = (e) => {
        onClickDeleteGallery && onClickDeleteGallery(e,cardProp.id);
    }
    const metaBgImage = useMemo(() => {
        return getMetaThumbImageById(cardProp?.metaBg);
    }, [cardProp?.metaBg])

    const isMyOwnGallery=useMemo(()=>{
        let createdBy=cardProp?.userId||cardProp?.user?.id;
        return ((createdBy === currentUserId) || page==="display")
    },[currentUserId,cardProp?.userId,cardProp?.user?.id,page])
    return (
        <div className={`info-cat-wrp ${className}`}>

            <div className={`flex flex-col sm:gap-0.5 gap-0 info cursor-pointer`} key={cardProp.id} data-slug={cardProp?.nameSlug} data-id={cardProp.id} data-communityslug={cardProp?.community?.slug} onClick={(e) => handleClick(e,showEdit)}>
                <Fragment>
                    <div className={`flex justify-center items-center rounded image-container relative`}
                        data-infoid={cardProp.id}
                    >
                        {(preview || showEdit || !isMyOwnGallery) && <div className={`content-overlay`}></div>}
                        < div className={`my-gallery-card-image card-home  h-full ${!cardProp?.thumbnailBackground?.imageUrl256&&"no-image"}`} style={{
                            backgroundImage: `url(${cardProp?.thumbnailBackground?.imageUrl256 ? cardProp?.thumbnailBackground?.imageUrl256 : page==="display"?anywallImage: Logo()})`
                        }}>
                             {showEdit && <GalleryCardEdit galleryID={cardProp.id} handleEdit={handleEdit} />}
                        {!!(selectedCollection==="My Collections"&&(cardProp?.priority===1 && tab==="public"))&&<button className="primary" data-id={cardProp.id} >Primary</button>}
                            <div className="screenshot-image rounded" >
                                {cardProp?.files?.sliders && (cardProp?.files?.sliders.length)>0&&(<img src={cardProp?.files?.sliders && cardProp?.files?.sliders[0]} alt='noImg' />)}
                            </div>
                        </div>
                        {!!(preview && isMyOwnGallery) && <GalleryPreviewSelection createdBy={cardProp?.userId||cardProp?.user?.id} setPrimaryHandler={setPrimaryHandler} selectedCollection={selectedCollection} 
                        priority={cardProp?.priority} isDiscoverable={cardProp?.isDiscoverable} files={cardProp?.files} 
                        thumbnailBackground={cardProp?.thumbnailBackground?.imageUrl} handlePreview={handlePreview} metaBgImage={metaBgImage} 
                        galleryID={cardProp.id} handleEdit={handleEdit} onClickDeleteGallery={onClickDelete} 
                        onCancel={onCancel} editGallery={editGallery} hideDetails={hideDetails}/>}
                        {hideDetails &&
                        <button className="absolute top-4 right-4 display-tag">{cardProp.displayName}</button>
                        }
                        {!isMyOwnGallery && <div className={`content-overlay`}> <div className='h-full w-full flex flex-col p-[1rem] justify-between'> 
                        <span className='text-[0.875rem text-left description'>
                           {cardProp?.description}
                             </span>
                             <div className='flex flex-col gap-[0.2rem] items-start text-[0.75rem] text-[#F7F8F9]'>
                             {Object.entries(cardProp?.category)?.slice(0,4)?.map(([category, { subCategories }]) => (
                                <div key={category}>
                                    <strong>{category}</strong> - {subCategories?.join(', ')}
                                </div>
                                ))}

                             </div>
                              </div> </div>}
                    </div>
                    {!hideDetails &&  <div className={` text-left flex justify-between w-full  ${showName || showCollectorName ? 'items-start' : 'items-center'} sm:px-[0.375rem] px-[2px] gap-[0.375rem] sm:py-[0.25rem] py-[0.75rem] relative date-holdr`}>
                        <div className={`grid grid-cols-1 gap-2`}>
                            <div className={`info-title flex ${showProfileIcon ? 'items-center gap-[.875rem]' : 'gap-[.875rem] items-center'} ${showEdit?"edit":""}`}>
                                {/* {!!((!cardProp?.isDiscoverable)&&(selectedCollection==="My Collections"))&& 
                                 <img src={lockIcon} className={`w-[1rem] h-[1rem] `} alt=""/>
                               } */}
                                {showProfileIcon && 
                                <div className='h-[100%] flex flex-col justify-start'>
                                <img src={cardProp?.userIcon||Logo()} className='card-profile-icon ' alt=""/>
                                </div>
                                }
                                <div className='pt-[.15rem] flex flex-col gap-1'>
                                    <span className={`h-fit align-middle sm:mt-[.1rem] mt-0`}>{cardProp?.name}</span>
                                    {showName &&
                                    <div>
                                    <span className={`h-fit align-middle sm:mt-[.1rem] mt-0 creator-named`}>{cardProp?.community?.name}</span>
                                    </div>
                                    }
                                     {showCollectorName &&
                                    <div>
                                    <span className={`h-fit align-middle sm:mt-[.1rem] mt-0 creator-named`}>{cardProp?.user?.name}</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                            <div className={`nft-gallery-head nft-gallery-count  flex items-center gap-2`}>
                            {cardProp?.assetsCount &&<img src={assetCountIcon} className='w-[1.125rem] mt-[2px]' alt=''></img>}
                                <span >{cardProp?.assetsCount}</span>
                            </div>
                    </div>}
                    {userDetails === true &&
                     <div className={` text-left flex justify-between w-full  ${showName || showCollectorName ? 'items-start' : 'items-center'} sm:px-[0.375rem] px-[2px] gap-[0.375rem] sm:py-[0.25rem] py-[0.75rem] relative date-holdr`}>
                     <div className={`grid grid-cols-1 gap-2`}>
                         <div className={`info-title flex ${showProfileIcon ? 'items-center gap-[.875rem]' : 'gap-[.875rem] items-center'} ${showEdit?"edit":""}`}>
                             {/* {!!((!cardProp?.isDiscoverable)&&(selectedCollection==="My Collections"))&& 
                              <img src={lockIcon} className={`w-[1rem] h-[1rem] `} alt=""/>
                            } */}
                             {showProfileIcon && 
                             <div className='h-[100%] flex flex-col justify-start'>
                             <img src={cardProp?.user?.profilePicUrl256||Logo()} className='card-profile-icon ' alt=""/>
                             </div>
                             }
                             <div className='pt-[.15rem] flex flex-col gap-1'>
                                 <span className={`h-fit align-middle sm:mt-[.1rem] mt-0`}>{cardProp?.name}</span>
                                 {showName &&
                                 <div>
                                 <span className={`h-fit align-middle sm:mt-[.1rem] mt-0 creator-named`}>{cardProp?.community?.name}</span>
                                 </div>
                                 }
                                  {showCollectorName &&
                                 <div>
                                 <span className={`h-fit align-middle sm:mt-[.1rem] mt-0 creator-named`}>{cardProp?.user?.username || cardProp?.user?.name}</span>
                                 </div>
                                 }
                             </div>
                         </div>
                     </div>
                         <div className={`nft-gallery-head nft-gallery-count  flex items-center gap-2`}>
                         {cardProp?.assets?.length &&<img src={assetCountIcon} className='w-[1.125rem] mt-[2px]' alt='' />}
                             <span >{cardProp?.assets?.length}</span>
                         </div>
                 </div>
                    }
                </Fragment>

            </div>

        </div>
    )
}

export default Card