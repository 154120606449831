import React, { useEffect, useRef, useState } from 'react';
import './PlacesInput.scss';
import { useDebounce } from 'App/hooks/use-debounce';
import locationIcon from 'Assets/Icons/locationIcon.svg';
import useProfileApiHelper from 'App/Pages/UserProfile/Components/Apis';

const PlacesInput = (props) => {
    const { getPlaces } = useProfileApiHelper();
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedPlace, setSelectedPlace] = useState('');
    const [placesList, setPlacesList] = useState([]);
    const [inputActive, setInputActive] = useState(false);

    const debouncedValue = useDebounce(searchValue, 600);

    const handleSearch = (e) => {
        let searchParam = e?.target?.value;
        setSearchValue(searchParam);;
        if (searchParam?.trim() && !isDropDownOpen) {
            setIsDropDownOpen(true)
        }
    }

    const handleSelectPlace = (place) => {
        const termsLength = place?.terms?.length;
        setSelectedPlace(place?.id);
        setSearchValue(`${place?.terms[termsLength - 2]?.value}, ${place?.terms[termsLength - 1]?.value}`);
        const placeOfUser = {
            country: place?.terms[termsLength - 1]?.value,
            city: place?.terms[termsLength - 2]?.value,
            cityId: place?.id,
        }
        props?.onSelectPlace(placeOfUser);
        setIsDropDownOpen(false)
    }

    const handlePlaces = async (searchedPlace) => {
        const places = await getPlaces(searchedPlace);
        setPlacesList(places);
    }

    useEffect(() => {
        if (debouncedValue?.trim() && inputActive) {
            handlePlaces(debouncedValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);
    useEffect(() => {
        if (props?.defValue) {
            setSearchValue(props?.defValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.defValue]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropDownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDropDownOpen]);

    return (
        <div className='places_input-container' ref={dropdownRef}>
            <input
                ref={inputRef}
                value={searchValue}
                className={`places_input-box`}
                onChange={handleSearch}
                placeholder={`city/country`}
                onFocus={() => setInputActive(true)}
            />
            {isDropDownOpen && placesList?.length ? <ul className='places_input_select-box'>
                {placesList?.map((place, index) => (<li
                    data-item={index}
                    key={index}
                    onClick={() => handleSelectPlace(place)}
                    className={`${selectedPlace === place?.id ? 'selected' : ''}`}
                >
                    <img src={locationIcon} alt='location' />
                    <span>{place?.name}</span>
                </li>))}
            </ul> : null}
        </div>
    )
}

export default PlacesInput