import styles from "./Popup.module.scss";
import close from "Assets/Icons/close_black.svg";
import warning from "Assets/Icons/warning.svg";
import closeIcon from "Assets/Icons/close.svg";
const Popup = (props) => {
  return (
    <div className={`${styles["popup-box"]} ${props.className}`}>
      <div onClick={props.handleClose} className={`${styles["overlay"]}`}></div>
      <div className={`${styles["box"]} popup-box-inner`}>
        {props.handleClose && !props?.warning && (
          <div className="flex items-center justify-end sticky">
            <span
              className={`${styles["close-icon"]}`}
              onClick={props.handleClose}
            >
              <img alt="x" src={close} />
            </span>
          </div>
        )}
        <div
          className={`${styles["pop-content"]} ${props?.contentClass} ${
            props.zeroPadding && styles["zero-padding"]
          } ${
            warning &&
            `${styles["warning-padding"]} items-start flex gap-4 justify-between p-4`
          }`}
        >
          {props?.warning && (
            <div className="flex items-center mt-1">
              <span className={`rotate-180`}>
                <img alt="" src={warning} />
              </span>
            </div>
          )}
          {props.children}
          {props.handleClose && props?.warning && (
            <div className="flex items-center mt-1">
              <span className={`cursor-pointer`} onClick={props.handleClose}>
                <img alt="x" src={closeIcon} />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
