import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import EternalWallet from 'Assets/Images/eternl_wallet.svg';
import FlintWallet from 'Assets/Images/flintWallet.svg';
import NamiWallet from 'Assets/Images/NamiWallet.svg';
import TyphonWallet from 'Assets/Images/TyphonWallet.svg';
import geroWalletIcon from 'Assets/Icons/gero-wallet-icon.svg';
import useWallet from './use-wallet';
import useHttp from './use-http';
import useAuth from './use-auth';
const useWalletConnect = () => {
    const walletConnector = useWallet();
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [installedWallets, setInstalledWallets] = useState([])
    const [selectedWallet, setSelectedWallet] = useState();
    const removeItem = useAuth()[5];
    const store = useStore()[0];        
    let { debugWalletAddress } = store;
    
    const connectHandler = async (installUrl, name) => {
        if (installUrl) {
          window.open(installUrl)
        } else {
          walletConnector.connectWallet(name);
          setSelectedWallet("cardano")
        }
    
      }
    const setWalletName = useCallback((availableWallet) => {
        const storedWalletName = selectedWallet ?? storage().get('currentWallet');
        const walletIndex = availableWallet.findIndex(wlt => storedWalletName === wlt.name);
        if (walletIndex !== -1) {
            availableWallet[walletIndex].status = walletConnector.connecting;
        }
        return [...availableWallet];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setActiveWallet = useCallback(() => {
        let defaultWallet = [{
          name: "eternl",
          icon: EternalWallet,
          version: "0.1.0",
          installUrl: "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka",
        },
        {
          name: "Flint Wallet",
          icon: FlintWallet,
          version: "0.1.0",
          installUrl: "https://chrome.google.com/webstore/detail/flint-wallet/hnhobjmcibchnmglfbldbfabcgaknlkj",
        }, {
          name: "Nami",
          icon: NamiWallet,
          version: "0.1.0",
          installUrl: "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo",
        }, {
          name: "Typhon Wallet",
          icon: TyphonWallet,
          version: "0.1.0",
          installUrl: "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh",
        }]
        const availableWallet = walletConnector.availableWallet();
        let installedWalletList = setWalletName(availableWallet)
        installedWalletList = installedWalletList?.filter((x, i) => installedWalletList?.findIndex((y, j) => y?.name === x?.name) === i)
        const walletDefault = installedWalletList?.map(wallet => {
          const existWallet = defaultWallet?.filter(x => x?.name === wallet?.name)
          if (existWallet?.length) {
    
            defaultWallet = defaultWallet?.filter((x) =>
              !existWallet?.some(y => y?.name === x?.name))
          }
          if(wallet?.name === "GeroWallet")
            wallet = {...wallet, icon: geroWalletIcon}
          return wallet
        })
        setInstalledWallets([...defaultWallet, ...walletDefault])
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const walletAddHandler = useCallback((walletAddress, chain, callback) => {
      if (debugWalletAddress??walletAddress) {
        dispatch('showSpinner')
        const data = {
          walletAddress: debugWalletAddress??walletAddress,
        chain: chain??'cardano'
        }
        triggerAPI({
        url: `user/connect-wallet/via/manually`, data: data, method: 'post', 
        }, (res) => {
            const { data } = res;
            dispatch('hideSpinner');
            if (!(typeof data === 'string' && data === 'Duplicate')) {
              dispatch('resetCollections');
              dispatch('setTriggerCollection', true);
              callback && callback();
              // dispatch('showSpinner')
              //   triggerAPI({
              //     url: `user/wallets/assets/get`, method: 'get', 
              //   }, () => {
              //     dispatch('hideSpinner');
              //     callback && callback();
              //   })
              dispatch('showToast', { toast: { toastMode: 'success', message: "Wallet successfully added to your collection" } })
            }            
        }, (err) => {
        dispatch('hideSpinner');
        // removeItem('currentWallet');
        // dispatch('setCurrentWallet', {})
            dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
        });
      } else {
          dispatch('showToast', { toast: { toastMode: 'error', message: "Invalid valid wallet address" } })
          dispatch('setCurrentWallet', {})
          removeItem('currentWallet');
      }
    }, [dispatch, triggerAPI, removeItem, debugWalletAddress])
   
    useEffect(() => {
        setActiveWallet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [walletConnector, selectedWallet])
    return { installedWallets, connectHandler, walletAddHandler};
}
export default useWalletConnect