import './Select.scss'
import blueDownArrow from "Assets/Icons/arrow_down_blue.svg"
import arrowDown from "Assets/Icons/arrow_down_B.svg"
import { createRef, useCallback, useEffect, useRef, useState } from 'react'
import DatePickerFilter from '../DatePickerFilter'
import { useStore } from 'App/hooks-store/store'
import DownArrow from "Assets/Images/DownArrow.svg"
import arrowGreyDown from "Assets/Icons/arrow-grey.svg"
import useScreenSize from 'App/hooks/use-screen-size'

const Select = props => {
    const [selectedItem, setSelectedItem] = useState({ value: "" })
    const [showItems, setShowItems] = useState(false)
    const [showDate, setshowDate] = useState({ mode: false })
    const [multiFilterIds, setMultiFilterIds] = useState([]);
    const [multiFilterValues, setMultiFilterValues] = useState([]);
    const [multiFilterActive, setMultiFilterActive] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [{ currentSelectedTab }] = useStore(false);
    const screenSize = useScreenSize()?.width;
    const select = createRef()
    let timeOut = useRef(null);
    useEffect(() => {
        if (props.value)
            setSelectedItem({ value: props.value })
        else if (props?.options?.length)
            setSelectedItem({ value: props.options[0]?.value })
        else setSelectedItem({ value: "" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, currentSelectedTab]);

    useEffect(() => {
        if (props?.options?.length && props?.options[0]?.isMultiFilter) {
            const defIds = []
            props?.options?.forEach((option) => {
                if (option?.defaultValue) { defIds.push(option?.id) }
            });
            setMultiFilterValues(props?.options?.filter((option) =>(option?.defaultValue)))
            setMultiFilterIds(defIds)
            setMultiFilterActive(true)
        } else {
            setMultiFilterActive(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.options])

    const applyToggle = useCallback(() => {
        const parent = select.current;
        const list = parent?.querySelector('div.select-box-items');
        if (showItems && list) {
            parent?.classList.remove('select-box-closed');
            parent?.classList.add('select-box-open');
            list.style.maxHeight = `${list?.scrollHeight}px`;
        }
        else {
            parent?.classList.remove('select-box-open');
            parent?.classList.add('select-box-closed');
            if (list)
                list.style.maxHeight = `0px`;
        }
    }, [select, showItems])

    const dropDownToggle = () => {
        setshowDate({ mode: true })
        setShowItems(prevState => !prevState);
    };

    const selectItem = e => {
        dropDownToggle()
        let item = e.currentTarget.dataset["item"]
        const label = props.controlName ?? 'value'
        const value = {
            [label]: props.options[item]?.value
        }
        setSelectedItem(props.options[item]);
        if (props.onChange) {     
                props.onChange(value)
        }
    };
    const handleMultiSelect = (e, option) => {
        if (option?.subOf === 'sort') {
            multiFilterIds[0] = option?.id
            setMultiFilterIds(multiFilterIds);
            multiFilterValues[0] = props?.options?.find((item) => (item?.id === option?.id))
            setMultiFilterValues(multiFilterValues)
        } else if (option?.subOf === 'group') {
            multiFilterIds[1] = option?.id
            setMultiFilterIds(multiFilterIds);
            multiFilterValues[1] = props?.options?.find((item) => (item?.id === option?.id))
            setMultiFilterValues(multiFilterValues)
        }
        if (props?.onChange) {
            const isMultiValue = multiFilterValues[1]?.value !== 'No Grouping';
            const filteredValue = isMultiValue ? [multiFilterValues[0]?.value, multiFilterValues[1]?.value,] : multiFilterValues[0]
            props.onChange(filteredValue, isMultiValue)
        }
    }

    const onSelectDate = (e) => {
       // setshowDate({ mode: false })
         let newDate=new Date(new Date(e).getFullYear(),new Date(e).getMonth(),new Date(e).getDate(),new Date(e).getHours(),new Date(e).getMinutes(),0,0).getTime();
         let oldDate=new Date(new Date(selectedItem.value).getFullYear(),new Date(selectedItem.value).getMonth(),new Date(selectedItem.value).getDate(),new Date(selectedItem.value).getHours(),new Date(selectedItem.value).getMinutes(),0,0).getTime();
       if(newDate!==oldDate){
        const label = props.controlName ?? 'value'
        const value = {
            [label]: e
        }
        setSelectedItem(value);
        if (props.onChange) {
            props.onChange(value)
        }  
       }
    }
    const onBlur = useCallback(() => {
        setShowItems(false)
        applyToggle()
    }, [applyToggle])

    useEffect(() => {
        const clearTimer = setTimeout(()=>setLoadingOptions(false),400);
        return ()=>{
            setLoadingOptions(true)
            clearTimeout(clearTimer);
        }
    },[])
    useEffect(() => {
        applyToggle();
        if (props.currentId) {
            const found = props?.list?.filter(val => {
                return val?.id === props.currentId
            })
            setSelectedItem(found?.length ? found[0] : { name: null, value: null })
        }
    }, [applyToggle, props?.currentId, props?.list])

    const toggleActionWhileHoveringOrLeavingMouse=(mode)=>{
        if (mode) {
            setshowDate({ mode: true })
            setShowItems(true);
        }
        else {
            onBlur()
        }
    }

    const onMouseToggle = useCallback((mode) => {
        if (screenSize > 551 && !props?.noHover) {
            if (timeOut.current) {
                clearTimeout(timeOut.current)
            }
            timeOut.current = setTimeout(() => {
                toggleActionWhileHoveringOrLeavingMouse(mode)
            }, 150)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBlur])
    const onMouseBlur = useCallback(() => {
        onMouseToggle(false)

    }, [onMouseToggle])
    const onMouseFocus = useCallback(() => {
        onMouseToggle(true)
    }, [onMouseToggle])
const ImageIcon = props?.imageIcon;
    return <>
        {props?.filterCount === 1 && screenSize < 551 ?

            <div ref={select} onBlur={onBlur} tabIndex="-1" className='select-box-sm select-boxs-items'>
                <div onClick={dropDownToggle} >
                    <ul>
                        {props?.options?.map((item, index) => (
                            <li
                                data-item={index}
                                key={index}
                                onClick={selectItem}
                                className={`${selectedItem.value === item.value ? "selected" : ""} ${props.className}`}
                            >
                                {item.value}

                            </li>
                        ))}
                    </ul></div>
            </div> :

            <div onMouseOverCapture={!props?.disableSort ? onMouseFocus : () => {}} className={`${props?.mainClassName} select-box  select-box-sm ${props?.disableSort ? 'disabled' : 'cursor-pointer'}`} ref={select} onMouseLeave={onMouseBlur} onBlur={onBlur} tabIndex="-1">
                <div className={!props.borderLess ? `select-box--container  ${props?.additionalStyles ?? ''} ${props?.disableSelect && 'cursor-not-allowed'}` : "flex items-center text-left"}
                    onClick={!props?.disableSort ? dropDownToggle : () => {}}>
                    {!props?.image && !props?.imageIcon && <div className={`select-box--selected-item ${props.className} ${selectedItem?.value&&"has-value"}`}>
                        {(screenSize < 551 && props?.title) ? props?.title : selectedItem.value || props?.placeholder
                        }
                    </div>}
                    {!props.blueArrow && !props?.smArrow && !props?.image && !props?.imageIcon && !props?.noDropArrow &&  <div className="select-box--arrow">
                        <img className={`${props?.disableSort?"cursor-no-drop":"cursor-pointer"} img-icon`} alt="img" src={props?.disableSort?arrowGreyDown:arrowDown} />
                    </div>}
                    {props.blueArrow && <div className='arrow grid place-items-center w-[.75rem] h-[.75rem]'>
                        <img className='cursor-pointer img-icon' alt="img" src={blueDownArrow} />
                    </div>}
                    {props?.smArrow && <div className='arrow grid place-items-center '>
                        <img className={`img-icon ${!showItems ? 'rot' : ''} ${props?.disableSort?"cursor-no-drop":"cursor-pointer"} w-[.75rem] h-[.75rem]`} alt="img" src={props?.disableSort?arrowGreyDown:DownArrow} />
                    </div>}

                    {props?.smArrow && <div className='arrow grid place-items-center '>
                        <img className={`img-icon ${!showItems ? 'rot' : ''} ${props?.disableSort?"cursor-no-drop":"cursor-pointer"} w-[.75rem] h-[.75rem]`} alt="img" src={props?.disableSort?arrowGreyDown:DownArrow} />
                    </div>}
                    {props?.image && <div className='arrow grid place-items-center '>
                        <img className={` imghgt `} alt="img" src={`${props?.image}`} />
                    </div>}
                    {!!props?.imageIcon &&<div className='arrow grid place-items-center '>
                    <ImageIcon className={showItems&&"activeIcon"}/>
                    </div>}
                </div>
                <div
                    className={loadingOptions ? 'hidden' :`select-box-items ${props?.itemsClassName} ${props?.listPosition}`}
                >
                    {props?.type === 'date'  ?
                        <DatePickerFilter setshowDate={setshowDate} minDate={props?.minDate} otherDate={props?.otherDate} notChangedOther={props?.notChangedOther} keyName={props?.keyName} disableDayPicker={props?.disableDayPicker} disableMonthPicker={props?.disableMonthPicker} disableYearPicker={props?.disableYearPicker} notChanged={props?.notChanged} showTime={props?.showTime} dateSelected={props?.dateSelected} value={props?.value} onDateSelect={props?.onDateSelect} show={showDate} onSelectDate={onSelectDate} /> : 
                        multiFilterActive ?  <ul className='py-2 max-h-[30rem] overflow-auto'>
                             {props?.options?.map((item, index) =>{
                                if(item?.isHeading) {
                                    return <li 
                                    data-item={index}
                                    key={index}
                                    className={`li_for_heading`}
                                    >{item?.headingLabel}</li>
                                }
                                 return (<li
                                    data-item={index}
                                    key={index}
                                    onClick={(event)=>handleMultiSelect(event, item)}
                                    className={`${multiFilterIds?.includes(item.id) ? "selected" : ""} ${props.className} ${item?.isHide && 'hidden'}`}
                                >
                                    {item.value}
                                </li>)
                            })}
                        </ul>
                         :
                        <ul className='py-2 max-h-[30rem] overflow-auto'>
                            {props?.options?.map((item, index) =>{
                                if(item?.isHeading) {
                                    return <li 
                                    data-item={index}
                                    key={index}
                                    className={`li_for_heading`}
                                    >{item?.headingLabel}</li>
                                }
                                 return (<li
                                    data-item={index}
                                    key={index}
                                    onClick={selectItem}
                                    className={`${selectedItem.value === item.value ? "selected" : ""} ${props.className} ${item?.isHide && 'hidden'}`}
                                >
                                    {item.value}
                                </li>)
                            })}
                        </ul>}
                </div>
            </div>



        }

    </>
}

export default Select