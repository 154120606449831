import { useEffect, useState } from "react"

const VideoThumbnail = ({ video = '', list = {}, active }) => {
    const [videoImage, setVideoImage] = useState('')
    const positionStyle = {
        left: list?.metaInfo?.image?.xPercent + "%",
        top: list?.metaInfo?.image?.yPercent + "%",
        width: list?.metaInfo?.image?.widthPercent + "%",
        height: list?.metaInfo?.image?.heightPercent + "%",
    }
    useEffect(() => {
        if (video) {
            const videoUrl = video?.split("/")
            const videoUrlId = videoUrl[videoUrl?.length - 1]
            setVideoImage(videoUrlId)
        }

    }, [video])
    return (
        <div
            className={`${active} flex items-center relative w-full h-full`}
            style={{ 'backgroundImage': "url(" + (list?.imageUrl) + ")" }}>
            {video?.includes("youtube") &&
                <div className="absolute" style={positionStyle}>
                    < img className="w-full h-full"
                        src={`https://img.youtube.com/vi/${videoImage}/maxresdefault.jpg`
                        } alt="Noimage" />
                </div>}
        </div>

    )
}

export default VideoThumbnail