import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";
import { memo, useCallback, useEffect, useState } from "react";
import closeIcon from "Assets/Icons/close.svg";
import LockComponent from "App/Components/Ui/LockComponent";
import { useStore } from "App/hooks-store/store";
import BackgroundWallFilter from "App/Components/Ui/BackgroundWallFilter";

const MultiBgControl = ({className = '', multiStyleList = [], multiColorList = [], bgWallFilter = null, setBgWallFilter = null, wallList=[], isMeta = false, selectedMultiBg, setSelectedMultiBg, selectedMetaBg, setSelectedMetaBg}) => {
    const [dropdown, setDropDown] = useState(false);
    const [bgColor, setBgColor] = useState("grey");
    const [selectedBg, setSelectedBg] = useState({});
    const {premiumGalleryWallEnabled} = useStore(false)[0];

    useEffect(()=>{
        setBgWallFilter(prev => {
            return {...prev, color: bgColor}
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bgColor])
    const onMouseBlur = useCallback(() => {
        setDropDown(false);
      }, []);
      const onMouseFocus = useCallback(() => {
        setDropDown(true);
      }, []);
    const onStyleHandler = (type) => {
        setBgWallFilter(prev => {
            return {...prev, style: bgWallFilter?.style === type ? "" : type}
        });
    };
    const onColorClose = ()=>{
        setBgWallFilter(prev => { return {...prev, color: ''} });
        onMouseBlur();
    }
    const onColorSelect = (e) => {
        setBgWallFilter(prev => {
            return {...prev, color: bgWallFilter?.color === e.currentTarget.dataset.id ? "" : e.currentTarget.dataset.id}
        });
        onMouseBlur();
    };
    const handleClick = useCallback((e) => {
        if(!(!premiumGalleryWallEnabled&&e.currentTarget.dataset?.types==="premium"&& !isMeta)){
            const id = parseInt(e.currentTarget.dataset?.id);
        let selected = wallList?.find(item => item?.id === id)
        isMeta ? setSelectedMetaBg(selected) : setSelectedMultiBg(selected)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallList, isMeta]);
    useEffect(() => {
        let tempBg =  selectedMultiBg;
        if(isMeta)
            tempBg =  selectedMetaBg;
        setSelectedBg(tempBg)
    }, [isMeta, selectedMetaBg, selectedMultiBg])
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 mbg-list-control w-[50rem]`}>
            <ControlBody className="mbg-list-section">
                <div className="flex gap-2 justify-between">
                    <ControlTitleCard>{isMeta ? 'Venue' : 'Background'}</ControlTitleCard>
                    {!isMeta && 
                        // <div className="mbg-filter flex gap-2 items-end">
                        //     <div className="flex items-center gap-8">
                        //         {/* <div className="flex items-center">
                        //             {multiStyleList?.map((val, i) => (
                        //                 <div
                        //                     key={i + 1}
                        //                     className={`style-box capitalize ${
                        //                         bgWallFilter?.style === val?.value && "active"
                        //                     }`}
                        //                     onClick={() => onStyleHandler(val?.value)}
                        //                 >
                        //                     {val?.name}
                        //                 </div>
                        //             ))}
                        //         </div> */}
                        //         <div
                        //             className="color-wrapper w-[4.081875rem] flex items-center relative"
                        //             onMouseOverCapture={onMouseFocus}
                        //             onMouseLeave={onMouseBlur} >
                        //             {!!bgWallFilter?.color && (
                        //             <div
                        //                 className={`color-show ${bgWallFilter?.color === "multi-color" ? "multi" : ""}`}
                        //                 style={{ backgroundColor: bgWallFilter?.color }}
                        //             ></div>
                        //             )}
                        //             {dropdown && (
                        //                 <div className="color-list">
                        //                     <div className="flex justify-end mb-[0.5rem]">
                        //                         <img src={closeIcon} onClick={onColorClose} alt="" className='cursor-pointer close-icon' />
                        //                     </div>
                        //                     <div className="color-list-wrapper">
                        //                         {multiColorList?.length ? 
                        //                             multiColorList?.map((colors) => (
                        //                                 <div
                        //                                     onClick={onColorSelect}
                        //                                     key={colors.id}
                        //                                     data-id={colors?.name}
                        //                                     className={`color-circle ${
                        //                                         bgWallFilter?.color === colors?.name ? "selected" : ""
                        //                                     } ${colors.name === "multi-color" ? "multi" : ""}`}
                        //                                     style={{ backgroundColor: colors.name }}
                        //                                 />
                        //                             ))
                        //                             :
                        //                             <p className="flex justify-center h-full w-full items-center no-color no-data">No colors found</p>
                        //                         }
                        //                     </div>
                        //                 </div>
                        //             )}
                        //         </div>
                        //     </div>
                        // </div>
                        <BackgroundWallFilter color={bgWallFilter?.color} setColor={setBgColor} />

                    }
                </div>                
                <ControlContent className="mbg-content">
                    {wallList?.length ? <div className={`grid mbg-sec-list listing-grid-control-panel`}>

                        {wallList?.map((wall, index) => (
                            <div className={`${(!premiumGalleryWallEnabled&&wall?.types==="premium"&& !isMeta)? "cursor-not-allowed":"cursor-pointer"} relative background-item ${selectedBg?.id === wall?.id ? 'active' : ''}`} data-types={wall?.types} data-id={wall?.id} key={`${wall?.id}${index}`} onClick={handleClick}>
                                <img alt="" src={wall?.imageUrl256} />
                                {!premiumGalleryWallEnabled&&wall?.types==="premium"&& !isMeta&&<LockComponent message="Premium backgrounds only available for Premium Curator subscription plans."/>}

                            </div>
                        ))
                        }</div>
                        :
                        <p className="flex justify-center h-32 w-full items-center no-color non-highlight-color">No background walls found</p>
                    }
                </ControlContent>
            </ControlBody>
        </ControlCard>
    )
}
export default memo(MultiBgControl);