import { useEffect, useMemo, useState } from "react"
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import { useStore } from "App/hooks-store/store";
import useShapes from "App/hooks/use-shapes";
import { copyClipHandler, getBlockChainLable, numberWithCommas } from "App/Helper/utilities";
import { getBlockchainIcon } from "App/Helper/blockchain";
import BlockChainIcon from "App/Components/Ui/BlockchainIcon";
import CopyIcon from "App/Components/IconsComponents/CopyIcon";
const DetailsTab = props => {
    const [shapes, setShapes] = useState(shapesEmpty);
    const {blockchains} = useStore()[0]
    const { getShapes } = useShapes();

    useEffect(() => {
        let ratios = props?.communityUserDetails?.shapes?.map(shape => Number(shape.aspectRatio));
        setShapes(getShapes(ratios));

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props?.communityUserDetails])
    

      function formatDate(dateString) {
        if (dateString === null || isNaN(new Date(dateString))) {
            return 'None';
        }
    
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }
      const handleCopy = () => {
        const policyId = props.communityUserDetails?.contractAddress ?? props?.collection?.contractAddress ?? props?.nft?.nft?.contractAddress
        copyClipHandler(policyId);
      };
      
      const blockChainLable = useMemo(() => {
          return getBlockChainLable({blockChainList: blockchains, blockChainCode: props?.collection?.blockchainType?.code})
      }, [blockchains, props?.collection?.blockchainType])
    return (<div className={`${props?.className} flex flex-col items-start overflow-auto h-[100%] bg-[#0D0F11]`}>
 <div className="flex flex-col text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">Description</div>
    <div className="flex flex-col   border-[#191D23] border-b py-[1.5rem] text-[#F7F8F9] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap ">{props.communityUserDetails?.description || props?.collection?.description || "No description"}</div>
    <div className="flex flex-col  py-[1.5rem]  text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem] ">Information</div>
    <div className="flex flex-col gap-2 text-[.875rem] w-[100%] pb-8  border-[#191D23] border-b">
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">{blockChainLable}</div>
            <div
              className={`collection-ids flex`}
            >
              <div className="value flex gap-2 align-center">
              {props?.collection?.blockchainType?.code ? <img
                className={`w-[1.2rem]`}
                alt="Img"
                src={getBlockchainIcon(props?.collection?.blockchainType?.code)}
              />
                :
                <BlockChainIcon classes={`w-[1.2rem]`} loaderH={'1rem'} policyId={props?.collection?.policy} />
              }

              {props?.collection?.contractAddress ? props?.collection?.contractAddress?.length < 8
                ? props?.collection?.contractAddress
                : props?.collection?.contractAddress?.slice(0, 4) +
                "..." +
                props?.collection?.contractAddress?.slice(-4)
              :
              props?.communityUserDetails?.contractAddress?.slice(0, 4) +
              "..." +
              props?.communityUserDetails?.contractAddress?.slice(-4)
              }
              {/* <img
                onClick={copyClipHandler}
                className="cursor-pointer"
                alt="Img"
                src={copyIcon}
              /> */}
              <CopyIcon className={"cursor-pointer"} onClick={handleCopy} />
            </div>
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Category</div>
            <div>
            {props?.communityUserDetails?.category?.name ?? '-'}
            {props?.communityUserDetails?.firstSubcategory?.name && ` - ${props?.communityUserDetails?.firstSubcategory?.name}`}
          </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Pieces</div>
            <div>{numberWithCommas(props?.communityUserDetails?.metrics?.[0]?.supply ?? props?.array?.nft?.assetCount ?? props?.communityUserDetails?.assetCount) ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Owners</div>
            <div>{numberWithCommas(props?.communityUserDetails?.metrics?.[0]?.owners ?? props?.array?.nft?.ownerCount ?? props?.communityUserDetails?.ownerCount) ?? '-'}</div>
        </div>
        <div className="flex gap-2">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Shapes</div>
            <div className={`flex w-8 justify-center`}>
                <img
                  className={`max-w-[1.3rem]`}
                  alt="Img"
                  src={shapes}
                />
              </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Formats</div>
            <div>{props?.communityUserDetails?.formats?.map(format => format.name).join('/') ?? '-'}</div>
        </div>
        <div className="flex gap-4">
        <div className="w-[5rem] text-[#B8C0CC] text-right">Types</div>
          <div>
          {props?.communityUserDetails?.types?.length
            ? props?.communityUserDetails?.types?.map((format) => format.name).join('/')
            : '-'}
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Editions</div>
            <div>{props?.communityUserDetails?.collectionEditions?.name ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Storage</div>
            <div>{props?.communityUserDetails?.collectionStorage?.name ?? '-'}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Terms</div>
            <div>{props?.communityUserDetails?.collectionTerms?.liscenceName ?? '-'}</div>
        </div>
        <div className="flex gap-4">
        <div className="w-[5rem] text-[#B8C0CC] text-right">Tags</div>
        <div>
          {props?.communityUserDetails?.tags?.length
              ? props?.communityUserDetails?.tags?.map((format) => format.name).join('/')
              : '-'}
            </div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Mint Date</div>
            <div>{formatDate(props?.communityUserDetails?.mintDate) ?? '-'}</div>
        </div>
   
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right ">Lock Date</div>
            <div>{formatDate(props?.communityUserDetails?.lockDate) ?? '-'}</div>
        </div>
    </div>
   {props?.communityUserDetails?.community?.name && <div className="pt-[1rem]">{props?.communityUserDetails?.community?.name}</div>}
    {props?.communityUserDetails?.community?.description && <><div className="flex flex-col w-full text-left  py-[1.5rem] text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">Description</div>
    <div className="flex flex-col  text-[#F7F8F9] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap pb-[2rem]">{props?.communityUserDetails?.community?.description}</div></>}
    {props?.communityUserDetails?.community?.about && <><div className="border-[#191D23] border-t flex flex-col w-full text-left  pt-[2rem] pb-[1.5rem] text-[#B8C0CC] text-[.875rem] font-normal leading-normal tracking-[.0175rem]">About Us</div>
    <div className="flex flex-col  pb-[1.5rem] text-[#F7F8F9] text-[.875rem] font-normal leading-normal tracking-[.0175rem] text-left w-[100%] h-fit whitespace-pre-wrap ">{props?.communityUserDetails?.community?.about}</div></>}
 </div>)
}

export default DetailsTab