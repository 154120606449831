import { useState, useEffect } from "react";
import { useStore } from "App/hooks-store/store";
import { useCallback } from "react";
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import useHttp from 'App/hooks/use-http'
import SolanaProvider from "./SolanaProvider";
import { getBlockchainIcon } from "App/Helper/blockchain";


const BlockchainsProvider = (props) => {    
    let { triggerAPI } = useHttp();
    const store = useStore()[0];    
    let { blockchains } = store;
    const dispatch = useStore(false)[1];
    const [web3Onboard, setWeb3Onboard] = useState(null);    
    
    const wallets = [
        injectedModule()
    ]

    const appMetadata = {
        name: process.env.REACT_APP_COMPANY == 'anywall'?'Anywall':'Cur8',
        icon: process.env.REACT_APP_COMPANY == 'anywall'?"/anywall-favicon.svg":"/cur8_favicon.svg",
        description: 'Curate The World’s Digital Assets - Discover, curate, and dis(play) your digital collections',
        recommendedInjectedWallets: [
        { name: 'MetaMask', url: 'https://metamask.io' }      
        ]
    }  
   
    if (!web3Onboard)
    {
        setWeb3Onboard(init({
                wallets,
                chains: [],
                appMetadata,
                accountCenter: {
                    desktop: {
                        enabled: false
                    },
                    mobile: {
                        enabled: false
                    }
                },
                theme: {
                    "--w3o-background-color": "var(--dark-mode-900)", 
                   "--w3o-foreground-color": "var(--dark-mode-900)", 
                   "--w3o-text-color": "#FFFFFF", 
                   "--w3o-border-color": "var(--dark-mode-300)", 
                   "--w3o-action-color": "var(--cu-black-5)", 
                   "--w3o-border-radius": "0.5rem", 
                   "--w3o-font-family": "ZenKaku", 
                   }
            }));  
    }    

    const getBlockchainsResult = useCallback((res) => {  
        let dbBlockchains = res.data.map(b=> { return {
            ...b,
            icon: getBlockchainIcon(b.code)
        }})      
        dispatch('setBlockchains', dbBlockchains)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getBlockchains = () => {
        triggerAPI({
            url: `blockchain/getall`, method: 'get'
        }, getBlockchainsResult);
    }

    useEffect(()=> {
        if (!blockchains)
        {                      
            getBlockchains();            
        }
        else
        {
            let evmchains = blockchains?.filter(b=>b.isEVM).map(b=> { return {
                label: b.name,
                icon: b.icon,
                id: b.chainId                
            }})

            setWeb3Onboard(init({
                wallets,
                chains: evmchains,
                appMetadata,
                accountCenter: {
                  desktop: {
                    enabled: false
                  },
                  mobile: {
                    enabled: false
                  }
                }
            }));            
        }
    }, [blockchains])            

    return (
        <Web3OnboardProvider web3Onboard={web3Onboard}>
            <SolanaProvider>
                {props.children}
            </SolanaProvider>
        </Web3OnboardProvider>        
    )
}
export default BlockchainsProvider;