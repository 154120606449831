import DisplayUpdate from "./Components/DisplayUpdate";
import DisplayList from "./Components/DisplayList";
import "./Display.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import useProfileApiHelper from "../UserProfile/Components/Apis";

const Display = ({isHome}) => {   
  const [pairShow, setPairShow] = useState(false);
  const [displayList, setDisplayList] = useState(null);
  const [loader, setLoader] = useState(true);
  const [anyWallGalleryCountWarningPopup, setAnyWallGalleryCountWarningPopup] = useState(false);
  const [isSubscriptionReached, setSubscriptionReached] = useState(false);
  const {getProfile} = useProfileApiHelper();
  const [{ currentUserId }, dispatch] = useStore(false);
  const { triggerAPI } = useHttp();
  const isLoggedIn = useAuth()[0];
  const tabs = useMemo(() => {
    let tabList = [
        { id: 0, element: "Galleries", url: "/curate/display/my-galleries" },
        { id: 1, element: "Displays", url: "/curate/display" }            
    ];
    
    if (process.env.REACT_APP_COMPANY !== 'anywall')
    {
        tabList.push({ id: 2, element: "Rentals", url: "/curate/rentals" });
    }
    return tabList
  }, []);
  const getDisplaysForUserResult = useCallback((res) => {
    setLoader(false)
    setDisplayList(res.data?.sort((a,b)=>b.createdAt>a.createdAt?1:-1));
    getProfileDetails(res?.data?.length,{noPairing:true})    
  }, []);

  const getDisplaysForUser = useCallback(() => {
    setLoader(true)
    triggerAPI(
      {
        url: `display/listforuserid?userId=${currentUserId}`,
        method: "get"        
      },
      getDisplaysForUserResult
    );
  }, [getDisplaysForUserResult, triggerAPI, currentUserId]);

  useEffect(() => {
    if (currentUserId)
    {
      getDisplaysForUser();
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);
  useEffect(() => {
    dispatch("setTopMenuBarOptions", {showMenuIcons:false , showTopMenus: true})
    dispatch('enableTopIcons', { userGuidePopup: !isHome });
    dispatch("setMenuOptions", isLoggedIn?tabs:[]);
    dispatch("setActiveMenuOptions", isHome?-1:isLoggedIn?1:0);
    return () => {
      //to reset all icons
      dispatch('resetAll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs,isLoggedIn]);

  const pairClick=()=> {
    setPairShow(true);
  }

  const onClose=()=> {
    setPairShow(false);
    getDisplaysForUser();
  }

  const getProfileDetails=(galleryLength,status)=>{
    getProfile((res)=>{
      setSubscriptionReached(res?.isSubscriptionLimitReached);
      let anyWallGalleryListedLength =galleryLength || displayList?.length || 0;
      if(anyWallGalleryListedLength>=res?.anywallDisplayCount){
        setAnyWallGalleryCountWarningPopup(true);
        }else{
          setAnyWallGalleryCountWarningPopup(false);
         !status?.noPairing && pairClick();
      }
    })
  }
  return (
    <>
      <div className="display-page page-shadow h-full pb-16 sm:pb-2 pl-4 pr-4 sm:pr-4 page-shadow pt-4 overflow-lg-auto">
        {/* {!spinnerShow && !viewDisplays && (<DisplaySummary pairClick={pairClick} viewDisplaysClick={displayList.length > 0 ? viewDisplaysClick : null}></DisplaySummary>)} */}
        { (<DisplayList loader={loader} anyWallGalleryCountWarningPopup={anyWallGalleryCountWarningPopup} isSubscriptionReached={isSubscriptionReached} displayList={displayList} pairClick={getProfileDetails} currentUserId={currentUserId} isHome={isHome}></DisplayList>)} 
      </div>
      {pairShow && (<DisplayUpdate onClose={onClose}></DisplayUpdate>)}
    </>
  );
};

export default Display;