import styles from "./FrameDisplayUi.module.scss"
import "./FrameDisplayUi.scss"
import NftFrame from "App/Components/NftFrame"
import RoundedButton from "App/Components/Ui/RoundedButton"
import useScreenSize from "App/hooks/use-screen-size";

const FrameDisplayUi = props => {
    const screenSize = useScreenSize()?.width;
    return (
        <div className={`frame-display-ui ${styles["frame-display-ui"]} ${!props?.applyFrameButton ? styles["no-apply-btn"]: 'flex justify-between flex-col h-full'}`}>
            <div className={`scroller grid gap-[0.45625rem] ${screenSize > 551 ? 'grid-cols-6' : 'grid-cols-3'} ${styles["side-scroller"]} relative  `}>
                {!props.hideNoFrame && 
                    <div key={`00`} className='grid'>
                        <div className={`${styles["frame-display"]} ${(props.selectedFrame === null) ? styles["selected"] : ""} ${styles['no-frame-display']}`} data-index={null} onClick={props.onFrameSelect}>
                            <div className="w-full h-full flex flex-col">
                                <div className={`${styles["no-frame-text"]}     aspect-square h-full flex justify-center items-center`}>
                                </div>

                            </div>
                        </div>
                        

                    </div>
                }
                {props?.allFrames?.list && props?.allFrames?.list.map((framesData, index) => {
                    return (
                        <div key={`${index}-${framesData.id}`} className='grid'>
                            <div key={index} data-index={index} data-id={framesData.id} onClick={props.onFrameSelect} className={`border border-[#30333A] ${styles["frame-display"]} ${(props.selectedFrame?.id === framesData.id) ? styles["selected"] : ""} aspect-square`}>
                                <div className={`${framesData.metaInfo.landscape ? 'h-full' : ''} h-full w-auto flex flex-col `}>
                                    <div className={`${framesData?.isLandscape && 'landscape-frame'} h-full w-auto`}>
                                        <NftFrame data-index={index} className={`h-full w-auto`} frame={framesData?.isLandscape ? (framesData?.s3ImageUrlLandscape256 ?? framesData?.s3ImageUrl256) : framesData?.s3ImageUrl256} skeltonClass="h-fit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {!!props?.applyFrameButton && 
                <div className={`${styles["button-bar"]} gap-2 flex`}>
                    <div className="w-[20rem] m-auto">
                        <RoundedButton Name='Apply' className={`${styles["apply-button"]} flex-1`} Type="8" OnClick={props.applyFrame}></RoundedButton>
                    </div>
                </div>
            }
        </div>
    )
}

export default FrameDisplayUi
