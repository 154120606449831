import { useCallback, useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Card from "App/Components/Card";
import backgroundImage from "../../../../../Assets/Images/display_background.png";
import useHomeApiHelper from "App/Pages/HomeV2/Components/API/apiHelper";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import AddPlusIconButton from "App/Components/AddPlusIconButton";
import useAuth from "App/hooks/use-auth";
import RentalGalleries from "../RentalGalleries";
import banner1 from "Assets/Images/display-banner-1.png";
import banner2 from "Assets/Images/display-banner-2.png";
import banner3 from "Assets/Images/display-banner-3.png";
import banner4 from "Assets/Images/display-banner-4.png";
import banner5 from "Assets/Images/display-banner-5.png";
import BannerImage from "./Components/BannerImage";
import bannerWithText from "Assets/Images/display-banner-with-text.png";
import RoundedButton from "App/Components/Ui/RoundedButton";
import SubscriptionWarning from "App/Pages/NftGallery/Component/SubscriptionWarning";

const DisplayList = (props) => {
  const navigate = useNavigate();
  const { getCreatorGallery } = useHomeApiHelper();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [
    mouseEnterOverCreateDisplayGallery,
    setMouseEnterOverCreateDisplayGallery,
  ] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  // const [seeAllRentalGalleries, setSeeAllRentalGalleries] = useState(false);
  const [displayList, setDisplayList] = useState(null);
  // const [gallery, setGallery] = useState([]);
  // const [rentalGalleryList, setRentalGalleryList] = useState([]);
  // const [totalRentalGallery, setTotalRentalGallery] = useState(0);
  // const [loading, setLoading] = useState(true);
  const isLoggedIn = useAuth()[0];

  const bannerImageLists = [
    {
      id: 1,
      description: "Discover the world's most premium digital art displays",
      image: banner1,
      logo: true,
      siteLink: true,
    },
    { id: 2, image: banner2 },
    {
      id: 3,
      description:
        "Showcase your collection in a viewing experience unlike any other.",
      image: banner3,
      logo: true,
    },
    {
      id: 4,
      description: "Experience the latest in art technology at anywall.io",
      image: banner4,
      siteLink: true,
    },
    { id: 5, image: banner5 },
  ];

  useEffect(() => {
    if (props?.displayList) {
      setDisplayList(props?.displayList);
    }
    // setDisplayList(seeAll?props?.displayList:props?.displayList?.slice(0,3))
    else setDisplayList(null);
  }, [props?.displayList, seeAll]);

  // useEffect(()=>{
  //   let tempRentalList = [];
  //   if(gallery?.length)  tempRentalList = seeAllRentalGalleries ? gallery : gallery?.slice(0,3);
  //   setRentalGalleryList(tempRentalList);
  //  },[gallery, seeAllRentalGalleries])

  const navigateToDisplay = (id) => {
    navigate(`${id}`);
  };
  //   const getData = useCallback(() => {
  //     const req = {
  //         page: 1,
  //         items: 100,
  //         filter: {
  //             showOnHomePageDisplay: true,
  //         }
  //     };
  //     setLoading(true)
  //     getCreatorGallery(req, (res) => {
  //         const temp = res?.list?.map(item => {
  //             const temp = item?.gallery;
  //             temp.community = item?.community;
  //             temp.userIcon = item?.community?.displayPicture;
  //             return temp;
  //         })
  //         setLoading(false)
  //         setGallery(temp);
  //         setTotalRentalGallery(res?.pageMeta?.totalItems);
  //     });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // useEffect(() => {
  //     getData()
  // },[getData])

  const seeAllHandler = () => {
    setSeeAll((pre) => !pre);
  };
  const onMouseBlur = useCallback(() => {
    setMouseEnterOverCreateDisplayGallery(false);
  }, []);
  const blurHandler = () => {
    setMouseEnterOverCreateDisplayGallery(false);
  };
  const onMouseFocus = useCallback(() => {
    props?.anyWallGalleryCountWarningPopup &&
      setMouseEnterOverCreateDisplayGallery(true);
  }, [props?.anyWallGalleryCountWarningPopup]);
  return (
    <div className="flex flex-col gap-[1.5rem] pb-[2rem] ">
      {!seeAll && (
        <>
          {((props?.displayList && !displayList?.length) ||
            !isLoggedIn ||
            props?.isHome) && (
            <div className="w-full h-full relative">
              {/* <img src={backgroundImage} className="w-[100%] h-[21rem] object-cover object-center" onLoad={()=>setImageLoaded(true)} alt=""/>
          {imageLoaded && (
          <a href="https://www.anywall.io/" target="_blank" rel="noreferrer" >
          <button className="display-learnmore">Learn More</button>
          </a>)}        */}
              {props?.isHome ? (
                <>
                  <BannerImage
                    className="h-[23rem] full-width-banner"
                    data={bannerImageLists[0]}
                  />
                  <div className="grid banner-sub-image-wrapper mt-[4rem]">
                    {bannerImageLists?.slice(1)?.map((list, i) => (
                      <BannerImage
                        key={list?.id}
                        className="h-[23rem]"
                        data={list}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="display-banner-wrapper flex flex-col gap-16">
                  <BannerImage skeltonCardHeight="21rem"
                    className="display-banner"
                    data={{ id: 1, description: "", image: bannerWithText }}
                  />
                  <div className="flex justify-center items-center gap-4">
                    {!!isLoggedIn && (
                      <div
                        className="relative add-display-btn-wrapper"
                        onMouseLeave={onMouseBlur}
                      >
                        <div
                          className="w-full"
                          onMouseOverCapture={onMouseFocus}
                          onBlur={blurHandler}
                        >
                          <RoundedButton
                            className={`${
                              props?.anyWallGalleryCountWarningPopup &&
                              "warning-btn"
                            }`}
                            Name="Add Display"
                            Type="14"
                            OnClick={props.pairClick}
                            disabled={
                              !props.displayList ||
                              props?.anyWallGalleryCountWarningPopup
                            }
                          ></RoundedButton>
                          <div
                            className={`warning-toolkit ${
                              mouseEnterOverCreateDisplayGallery && "active"
                            }`}
                          >
                            <SubscriptionWarning
                              content="display galleries"
                              isSubscriptionReached={
                                props?.isSubscriptionReached
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <a
                      href="https://www.anywall.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RoundedButton
                        className=""
                        Name="Learn More"
                        Type="14"
                        OnClick={() => {}}
                      ></RoundedButton>
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* <div className="title-section flex justify-between">
          <div className="title text-left text-[1.25rem] flex">
              <div>Rental Galleries</div><div className="text-[#A0ABBB]">&nbsp;({totalRentalGallery})</div>
          </div>
          <div className="see-all-text" onClick={() => setSeeAllRentalGalleries(!seeAllRentalGalleries)}>
            See { seeAllRentalGalleries ? 'less' : 'all'}
          </div>
        </div>
        <RentalGalleries loading={loading} gallery={rentalGalleryList}/> */}
        </>
      )}
      {!!isLoggedIn &&
        !props?.isHome &&
        ((props?.displayList && displayList?.length) ||
          props?.displayList === null) && (
          <div className="title-section flex justify-between items-center">
            <div
              className="title text-left text-[1.25rem] flex items-center gap-4 relative w-full"
              onMouseLeave={onMouseBlur}
            >
              <div className="flex items-center">
                <span className="mb-[3px]">Displays</span>
                <span className="text-[#A0ABBB] mb-[3px]">
                  &nbsp;({props?.displayList?.length || 0})
                </span>
              </div>
              <div onMouseOverCapture={onMouseFocus} onBlur={blurHandler}>
                <AddPlusIconButton
                  className={`flex items-center ml-auto ${
                    props?.anyWallGalleryCountWarningPopup && "warning-btn"
                  }`}
                  disabled={
                    !props.displayList || props?.anyWallGalleryCountWarningPopup
                  }
                  onClick={props.pairClick}
                />
                <div
                  className={`warning-toolkit ${
                    mouseEnterOverCreateDisplayGallery && "active"
                  }`}
                >
                  <SubscriptionWarning
                    content="display galleries"
                    isSubscriptionReached={props?.isSubscriptionReached}
                  />
                </div>
              </div>
            </div>
            {/* {!!(props?.displayList?.length>3)&&<div
            className="see-all-text"
            onClick={seeAllHandler}
          >
            {`See ${seeAll? "less" : "all"}`}
          </div>} */}
          </div>
        )}
      {!!isLoggedIn &&
        !props?.isHome &&
        ((props?.displayList && displayList?.length) ||
          props?.displayList === null) && (
          <div
            className={`w-full overflow-flex-auto flex flex-col justify-between display-list`}
          >
            <DetailsSkeltonCard
              show={!props.displayList}
              height={"100%"}
              count={3}
              inline={true}
              details={"img-2-line"}
              noAbsolute
              parentDiv="grid home-grid  aspect-pt-five-six details-gap"
              containerClassName={`flex`}
            />
            {displayList?.length ? (
              <div
                className={`sm:pt-0 pt-4 cursor-pointer grid gap-2.5 display-grid`}
              >
                {displayList?.map((info, i) => (
                  <Fragment key={i}>
                    <div
                      key={i}
                      data-id={info?.id}
                      className={`cursor-pointer`}
                      onClick={(e) => {
                        navigateToDisplay(info.id);
                      }}
                    >
                      <Card
                        key={i}
                        cardProp={{
                          ...info.gallery,
                          user: info?.gallery?.user,
                          displayName: info.name,
                        }}
                        type="Gallery"
                        preview={true}
                        userDetails={true}
                        hideDetails={true}
                        showProfileIcon={true}
                        showCollectorName={true}
                        page="display"
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            ) : (
              !!Array.isArray(displayList) && (
                <div className="no-data">No items to display.</div>
              )
            )}
          </div>
        )}
    </div>
  );
};

export default DisplayList;
