const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setMusicPlaylist: (current, newPlaylist) => {
            return {
                musicPlaylist: newPlaylist,
                musicSwitchingTrack: true
            }
        },
        setMusicPlayerVisible: (current, visible) => {
            return {
                musicPlayerVisible: visible
            }
        },
        setMusicPlaying: (current, playing) => {
            return {
                musicPlaying: playing
            }
        },
        setMusicSwitchingTrack: (current, switching) => {
            return {
                musicSwitchingTrack: switching
            }
        },
        setMusicSelectedTrack: (current, selectedTrack) => {
            return {
                musicSelectedTrack: selectedTrack
            }
        }
    }
    initStore(actions, {
        musicPlaylist: [],
        musicPlayerVisible: false,
        musicPlaying: false,
        musicSwitchingTrack: false,
        musicSelectedTrack: -1
    });
}
export default configureStore;