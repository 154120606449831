import { Fragment, useCallback } from "react";
import { useState, useEffect } from "react";
import "./WalletSelect.scss";
import { useRef } from "react";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import WalletIconNew from 'Assets/Images/wallet-manage.svg';
import activeWallet from "Assets/Icons/active-wallet.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import WalletSelectPop from "../WalletSelectPop";
import WalletIcon from "App/Components/IconsComponents/WalletIcon";
import useWalletEVM from "App/hooks/use-wallet-evm";
const WalletSelect = ({ current, availble = [], onSelect, loading = false }) => {
    const popupRef = useRef();
    const [wallets, setWallets] = useState([]);
    const walletConnectorEVM = useWalletEVM();
    const [selectedWallet, setSelectedWallet] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const isLoggedIn = useAuth()[0];
    const removeItem = useAuth()[5];
    const dispatch = useStore(false)[1];
    const { pathname } = useLocation();
    const store = useStore()[0];        
    let { blockchains, collectionLoadingStatus } = store;
    const [showWalletAuthPop, setShowWalletAuthPop] = useState(false);
    
    
    const focusHandler = () => {
        setShow(prev => !prev);
    }

    const blurHandler = () => {
        setShow(false);
    }
    const disconnectWallet = useCallback(() => {
        dispatch('setCurrentWallet', {})
        removeItem('currentWallet');
        removeItem('cwa');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const selectWallet = (e) => {
        e.stopPropagation();
        setShow(false);
        const { dataset: { value, id } } = e.currentTarget;
        if(collectionLoadingStatus !== 'finished') {
            dispatch('showToast', { toast: { toastMode: 'warning', message: 'Loading collections form all linked wallets' } })
            return;
        }
        if (id) {
            window.open(id);
        } else {
            let currentWalletName = storage().get('currentWallet') || selectedWallet?.walletName;
            const isEvmChain = blockchains?.findIndex(c => c.name === value && c.isEVM) > -1 ? true: false;
            if(currentWalletName?.toLowerCase() !== value?.toLowerCase()){
                if(value?.toLowerCase() === "cardano") {
                    setShowWalletAuthPop(true);
                } else {
                    removeItem('cwa');
                    
                    onSelect(value, isEvmChain);
                }
            } else {
                if(isEvmChain)
                    walletConnectorEVM?.disConnectWallet();
                disconnectWallet();
                if(!isLoggedIn && pathname.includes("curate/collections/my-collections")) navigate("/home")
            }           
        }        
    }

    useEffect(() => {                       
        let walletList = [];
        if (blockchains?.length)
        {
            walletList.push(...blockchains?.sort((a,b)=>a.sortOrder>b.sortOrder?1:-1).map(c => { return {
                name: c.name,
                icon: c.icon
            }}));
        }
        console.log('current', current);
        setWallets(walletList);
        if (current) {
            const { walletName, roundBalance } = current;
            const foundIcon = walletList?.find(w => w?.name?.toLowerCase() === walletName?.toLowerCase());
            setSelectedWallet({ walletName, balance: roundBalance, icon: foundIcon?.icon??blockchains?.filter(b=>b.code === 'cardano')?.[0]?.icon });
        }
    }, [availble, current, blockchains]);

    const onMouseBlur = useCallback(() => {
        setShow(false);
    }, []);

    const onMouseFocus = useCallback(() => {
        setShow(true);
    }, []);

    const navToWallet = () => {
        navigate("collectors/wallet-authentication");
    }

    const walletLinkClicked = () => {
        setShowWalletAuthPop(false);
    }
    
    return (
        <>
            <div className={`wallet-select ${show ? 'open-popup' : 'close-popup'} ${loading ? 'loading' : ''}`}
                onClick={focusHandler}
                onMouseOverCapture={onMouseFocus} onMouseLeave={onMouseBlur} onBlur={blurHandler}
                tabIndex={-1} ref={popupRef}>
                {loading ? <SkeletonLoader height={18} count={1} inline={true} /> :
                    <Fragment>
                        <div className="flex gap-2 items-center">
                        {(selectedWallet?.walletName) ?
                            <div className={`wallet-select-label wallet-icon-wrapper flex items-center justify-center  h-full  rounded-[8px] link-wallet-section`}>
                                <img src={selectedWallet?.icon} alt={selectedWallet?.name} />
                            </div> :
                            <div className={`wallet-icon-wrapper cursor-pointer flex items-center  gap-2 justify-center w-[3rem] h-full  rounded-[8px]`}>
                                {/* <img src={WalletIconWhite} alt="wallet icon" className=""/> */}
                                <WalletIcon/>
                            </div>
                        }
                        {!selectedWallet?.walletName&& <span className="connect-text">Connect</span>}
                        </div>
                        <div className="wallet-select-available walletlist-drop-drown" style={{ display: wallets.length ? 'block' : 'none' }}>
                            <ul>
                                {isLoggedIn && <><div className="link-wallet">
                                <div className="flex justify-between items-center">
                                    <span className="pl-[1rem]">Linked Wallets</span>
                                    {/* <div className="flex items-center gap-[.625rem] px-[.5rem]" onClick={handleCollectionRefresh}>
                                        <img src={refresh} alt="refresh icon" />
                                    </div> */}
                                </div>
                                <li key={''} data-id={''} onClick={navToWallet} data-value={''} className={`flex connect-wallet`}>
                                    <div className="flex items-center gap-[.625rem]">
                                        <img src={WalletIconNew} alt="wallet icon" />
                                        <span className="mb-[2px]">Manage</span>
                                    </div>
                                </li>
                                </div>
                                <hr/>
                                </>
                                }
                                <div className="pl-[1rem] pb-[.4rem] mt-[2px]">
                                    <span >Connect Wallet</span>
                                </div>  
                                {
                                    wallets?.map((wallet) => {
                                        return (
                                            <li key={wallet.name} data-id={wallet?.installUrl} onClick={selectWallet} data-value={wallet.name} className={`${wallet?.name?.toLowerCase()===selectedWallet?.walletName?.toLowerCase() ? 'activeWallet' : ''}`}>
                                                <div className="flex gap-[0.625rem]">
                                                <div className="wallet-select-available-icon">
                                                    <img src={wallet.icon} alt={wallet.name} />
                                                </div>
                                                <div className="wallet-select-available-label mb-[2px]">{wallet.name}</div>
                                                </div>
                                                {!!(wallet?.name?.toLowerCase()===selectedWallet?.walletName?.toLowerCase())&&<div className="active-icon"><img src={activeWallet} alt=""/></div>}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </Fragment>
                }
            </div>
            <WalletSelectPop show={showWalletAuthPop} onClose={() => setShowWalletAuthPop(false)} className="walletcardanopopup" linkClicked={walletLinkClicked} />
        </>
    );
};

export default WalletSelect;
