import { useCallback, useEffect, useState } from "react";
import "./BackgroundWallFilter.scss";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import SkeletonLoader from "../SkeletonLoader";

const BackgroundWallFilter = ({ setColor,setNoColor=()=>{}, color, style, setStyle, setColorFetched = '', colorFetched= false }) => {
  const {currentGalleryUpdateId} = useStore(false)[0]
  const [styleList, setStyleList] = useState([
    { value: "", name: "all" },
    { value: "interior", name: "interior" },
    { value: "exterior", name: "exterior" },
    { value: "meta", name: "meta" },
  ]);
  const [colorsList, setColorsList] = useState(null);
  const [dropdown, setDropDown] = useState(false);
  const { triggerAPI } = useHttp();
  const onStyleHandler = (type) => {
    setNoColor(true)
    setStyle(style === type ? "" : type);
  };
  const colorsApiResult = useCallback((res) => {
    const data = res.data;
    let list;
    list = data.map((clr) => {
      return { name: clr.name, id: clr.id };
    });
    //list.reverse();
    setColorsList(list);
  }, []);
  const getColors = useCallback(() => {
    triggerAPI(
      {
        url: `frame-asset/color/get`,
        method: "get",
      },
      colorsApiResult
    );
  }, [colorsApiResult, triggerAPI]);

  const getWallStyleResult = useCallback((res) => {
    const list = res?.data;
    let result = list?.map((a) => {
      return { value: a.style, name: a.style };
    });
    setStyleList(result);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getWallStyle = useCallback(() => {
    triggerAPI(
      {
        url: `background-thumbnail/style/get`,
        method: "get",
      },
      getWallStyleResult
    );
  }, [getWallStyleResult, triggerAPI]);
  useEffect(() => {
    //getWallStyle();
    getColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!color && colorsList?.length && !colorFetched) {
      setColor(colorsList[0]?.name || currentGalleryUpdateId ? '' : 'white')
      setColorFetched && setColorFetched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, colorsList, colorFetched, currentGalleryUpdateId]);
  const onColorSelect = (e) => {
    setNoColor && setNoColor(true)
    setColor(e.currentTarget.dataset.id);
    // onMouseBlur();
  };
  const onMouseBlur = useCallback(() => {
    setDropDown(false);
  }, []);
  const onMouseFocus = useCallback(() => {
    setDropDown(true);
  }, []);

  return (
    <div className="wall-filter flex items-center w-full justify-end">
      <div className="flex gap-2 items-center">
        {/* {styleList?.map((val, i) => (
          <div
            key={i + 1}
            className={`style-box capitalize ${
              style === val?.value && "active"
            }`}
            onClick={() => onStyleHandler(val?.value)}
          >
            {val?.name}
          </div>
        ))} */}
             {colorsList?colorsList?.length?colorsList?.map((colors) => (
              <div
                onClick={onColorSelect}
                key={colors.id}
                data-id={colors?.name}
                className={`color-circle ${
                  color === colors?.name ? "selected" : ""
                } ${colors.name === "multi-color" ? "multi" : ""}`}
                style={{ backgroundColor: colors.name }}
              ></div>
            )):<p className="flex justify-center items-center no-color">No colors found</p>:<div className="flex gap-2">{[...Array(12)]?.map((el, index) => (
              <div className={"h-full w-full skeleton-wrapper"} key={index + 1}>
                <SkeletonLoader
                  height={"1rem"}
                  inline={true}
                  containerClassName={""}
                />
              </div>
            ))}</div>}
      </div>
      {/* <div
        className="color-wrapper w-[4.081875rem] flex items-center relative"
        onMouseOverCapture={onMouseFocus}
        onMouseLeave={onMouseBlur}
      >
        <span className={`color-text ${color ? "w-auto" : "w-full"}`}>
          Color
        </span>
        {!!color && (
          <div
            className={`color-show ${color === "multi-color" ? "multi" : ""}`}
            style={{ backgroundColor: color }}
          ></div>
        )}
        {!!dropdown && (
          <div className="color-list">
            <div className="color-list-wrapper">
            {colorsList?.length?colorsList?.map((colors) => (
              <div
                onClick={onColorSelect}
                key={colors.id}
                data-id={colors?.name}
                className={`color-circle ${
                  color === colors?.name ? "selected" : ""
                } ${colors.name === "multi-color" ? "multi" : ""}`}
                style={{ backgroundColor: colors.name }}
              ></div>
            )):<p className="flex justify-center h-full w-full items-center no-color">No colors found</p>}
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default BackgroundWallFilter;
