import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./LayoutDesign.scss";
import LayoutImageCard from "../LayoutImageCard";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";

const LayoutDesign = ({layoutList = [], count = 1, selected = {}, setSelectedLayout}) => {
    const [selectedList, setSelectedList] = useState([]);
    const [activeLayout, setActiveLayout] = useState(0);
    const [{ currentFormValues }, dispatch] = useStore(false);
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const countList = useMemo(() => {
        return [...Array(count).keys()].map(i => i + 1)
    },[count]);
    useEffect(() => {
        setActiveLayout(selected?.count);
    }, [selected])
    useEffect(() => {
        if(activeLayout) {
            let tempList = layoutList?.filter(item => item.count === activeLayout)
            setSelectedList(tempList);
        }        
    }, [activeLayout, layoutList])
    const handleLayoutClick = useCallback((e) => {
        let id = parseInt(e.currentTarget?.dataset?.id);
        let temp = layoutList?.find(item => item?.id === id);
        dispatch('setCurrentFormValues', {
            ...currentFormValues,
            layoutId: id
        });
        dispatchRedux(setCurrentFormValues({...currentForm, layoutId: id}))
        setSelectedLayout(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[layoutList]);
    const handleCount = useCallback((e) => {
        setActiveLayout(parseInt(e.currentTarget?.dataset?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <>
            <div className="flex justify-between items-center w-full layout-design-header">
                <span className="background-text">Layouts</span>
                <div className="flex wall-filter">
                    {countList?.map((item) => 
                        <div onClick={handleCount} data-id={item}
                            className={`style-box ${activeLayout === item && 'active'}`} key={item}>
                            {item}
                        </div>
                    )}
                </div>
            </div>
            <div className="layout-design-body w-full">
                <div className="grid layout-section">
                    {selectedList?.map((design) => {
                       return <LayoutImageCard key={design?.id} design={design} selected={selected} handleLayoutClick={handleLayoutClick} />
                    })}
                </div>
            </div>
        </>
    )
}
export default LayoutDesign;