import { getURL } from './utilities';

export const aspectRatioCalc = async (src) => {
    const img = new Image();
    let ar
    const getImageDetails = () => {

        return new Promise((resolve, reject) => {
            img.onload = function () {
                if (!!this.width && !!this.height) {
                    resolve(this.width / this.height)
                }
            }
        })
    }
    img.src = getURL(src);
    ar = await getImageDetails()
    return new Promise((resolve, reject) => {
        resolve(ar)
    })
}

export const imagProperty = async (src) => {
    const img = new Image();
    let ar
    const getImageDetails = () => {

        return new Promise((resolve, reject) => {
            img.onload = function () {
                if (!!this.width && !!this.height) {
                    resolve({width: this.width, height: this.height})
                }
            }
        })
    }
    img.src = src;
    ar = await getImageDetails()
    return new Promise((resolve, reject) => {
        resolve(ar)
    })
}
export const videoProperty = async (src) => {

    const videoUrl = src;
    const video = document.createElement('video');
    video.src = videoUrl;
    let ar;
    const getImageDetails = () => {

        return new Promise((resolve, reject) => {
            // img.onload = function () {
            //     if (!!this.width && !!this.height) {
            //         resolve({width: this.width, height: this.height})
            //     }
            // }
            video.addEventListener('loadedmetadata', () => {
                const width = video.videoWidth;
                const height = video.videoHeight;
                resolve({width, height})
            });       
            video.load();
        })
    }
    ar = await getImageDetails()
    return new Promise((resolve, reject) => {
        resolve(ar)
    })
}
export const iFrameProperty = async (src) => {

    var iframe = document.createElement("iframe");
    iframe.src = src;
    let ar;
    const getImageDetails = () => {

        return new Promise((resolve, reject) => {
            // var contentWidth = iframe?.innerWidth;
            // var contentHeight = iframe?.innerHeight;

            //console.log("Content 123 Width:", contentWidth, iframe);
            //console.log("Content 123 Height:", contentHeight);
        })
    }
    ar = await getImageDetails()
    return new Promise((resolve, reject) => {
        resolve(ar)
    })
}

export const getHtmlAR = (nft) => {
    return nft?.files?.[0]?.mType?.indexOf("html") > -1 ? 1 : null;
}
export const getFrameFilterList = async (image, list, ar) => {
    let offset = .1
    if (!ar)
    {
        ar = await aspectRatioCalc(image);
    }
    const filteredList = [];
    [...list]?.forEach((x) =>  {
        if(x.metaInfo) {            
            if((ar - offset) < (x.metaInfo?.portrait?.image?.width / x.metaInfo?.portrait?.image?.height) && (x.metaInfo?.portrait?.image?.width / x.metaInfo?.portrait?.image?.height) < (ar + offset)) {
                x['isLandscape'] = false;
                filteredList.push(x);
            } else if ((ar - offset) < (x.metaInfo?.landscape?.image?.width / x.metaInfo?.landscape?.image?.height) && (x.metaInfo?.landscape?.image?.width / x.metaInfo?.landscape?.image?.height) < (ar + offset)){
                x['isLandscape'] = true;
                filteredList.push(x);
            }
        }
     })
     return filteredList;
}

export const convertRemToPixels = (rem) => {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}