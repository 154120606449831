const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCurrentWallet: (current, wallet) => {
            return {
                currentWallet: wallet
            }
        }
    }
    initStore(actions, {
        currentWallet: null
    });
}
export default configureStore;