import { useCallback, useState } from "react";
import ControlBody from "../SubComponents/ControlBody";
import ControlCard from "../SubComponents/ControlCard";
import ControlContent from "../SubComponents/ControlContent";
import ControlTitleCard from "../SubComponents/ControlTitleCard";

const SettingsControl = ({className = '',showEnhanced, showTransition, showCaption, setSettingControls, settingControls, galleryType, handleGalleryView}) => {
    const [disableAnimationClick, setDisableAnimationClick] = useState(false);
    const handlEnhancedClick = (e) => {
        let value = parseInt(e?.currentTarget?.dataset?.value);
        setSettingControls(prev => { return {...prev, enhancedContent: {...prev?.enhancedContent, onChainRenders: !!value, clickCount: (prev?.enhancedContent?.clickCount + 1)}}})
    }
    const handleAnimationChange = useCallback((e) => {
        let value = e?.currentTarget?.dataset?.value;
        if(!disableAnimationClick) {
            setDisableAnimationClick(true)
            setSettingControls(prev => { return {...prev, animation: value}})
            setTimeout(() => {
                setDisableAnimationClick(false) //should give 5sec to update animation in UI
            }, 5000);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableAnimationClick]);
    const handleCaptionChange = useCallback((e) => {
        if(!settingControls?.captionCard?.disableControl) {
            let value = Number(e?.currentTarget?.dataset?.value);
            setSettingControls(prev => { return {...prev, captionCard: {...prev?.captionCard, time: value}}})
        }       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard])
    const handleQRCodeVisibility = useCallback((e) => {
        if(!settingControls?.captionCard?.disableControl) {
            setSettingControls(prev => { return {...prev, captionCard: {...prev?.captionCard, QRCodeShow: e?.target?.checked}}})
        }       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingControls?.captionCard])
    const handlePreview = (e) => {
        let value = (e?.currentTarget?.dataset?.value);
        handleGalleryView && handleGalleryView(value);
    }
    return (
        <ControlCard className={`${className} pop flex flex-col gap-5 settings-control`}>
            {showEnhanced && 
                <ControlBody className="enhanced-section">
                    <ControlTitleCard>{`Enhanced Content`}</ControlTitleCard>
                    <ControlContent>
                        <span onClick={!settingControls?.enhancedContent?.onChainRenders ? () => {} : handlEnhancedClick} data-value={0}
                        className={`click-link-tab cursor-pointer ${!settingControls?.enhancedContent?.onChainRenders && 'text-active'}`}>{`Off`}</span>
                        <span  onClick={settingControls?.enhancedContent?.onChainRenders ? () => {} : handlEnhancedClick} data-value={1}
                        className={`click-link-tab cursor-pointer ${settingControls?.enhancedContent?.onChainRenders && 'text-active'}`}>{`On`}</span>
                    </ControlContent>
                </ControlBody>
            }
            {showTransition &&
                <ControlBody className={` transition-section`}>
                    <ControlTitleCard>{`Transition`}</ControlTitleCard>
                    <ControlContent>
                        <span data-value={'fade'} onClick={handleAnimationChange}
                        className={`click-link-tab ${disableAnimationClick ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.animation === 'fade' && 'text-active'}`}>{`Fade`}</span>
                        <span data-value={'slide'} onClick={handleAnimationChange}
                        className={`click-link-tab ${disableAnimationClick ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.animation === 'slide' && 'text-active'}`}>{`Slide`}</span>
                    </ControlContent>
                </ControlBody>
            }
            {showCaption &&
                <ControlBody className="caption-section">
                    <ControlTitleCard>{`Caption Card`}</ControlTitleCard>
                    <ControlContent>
                        <span data-value={0} onClick={handleCaptionChange}
                         className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === 0 && 'text-active'}`}>{`None`}</span>
                        <span data-value={1} onClick={handleCaptionChange}
                        className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === 1 && 'text-active'}`}>{`Cycle`}</span>
                        <span data-value={-1} onClick={handleCaptionChange}
                        className={`click-link-tab ${settingControls?.captionCard?.disableControl ? 'cursor-not-allowed' : 'cursor-pointer'} ${settingControls?.captionCard?.time === -1 && 'text-active'}`}>{`On`}</span>
                    </ControlContent>
                    {(galleryType === 'mono' && settingControls?.captionCard?.time !== 0) ? <ControlContent>
                        <label className={`inline-flex items-center gap-[0.6rem] cursor-pointer ${settingControls?.captionCard?.disableControl && '!cursor-not-allowed'}`}>
                            <input disabled={settingControls?.captionCard?.disableControl} onChange={handleQRCodeVisibility} checked={settingControls?.captionCard?.QRCodeShow} type="checkbox" value="" className="sr-only peer" />
                            <div className="relative w-6 h-3.5 bg-[var(--cu-black-10)] rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:start-0 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-blue-600"></div>
                            <span className={`mb-[1px] ${settingControls?.captionCard?.QRCodeShow && 'text-active'}`}>Display QR Code</span>
                        </label>
                    </ControlContent> : null}
                </ControlBody>
            }
            <ControlBody className="caption-section">
                    <ControlTitleCard>{`View`}</ControlTitleCard>
                    <ControlContent>
                        <span data-value={'mono'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'mono' && 'text-active'}`}>{`Mono`}</span>
                        <span data-value={'multi'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'multi' && 'text-active'}`}>{`Multi`}</span>
                        <span data-value={'meta'} onClick={handlePreview}
                        className={`click-link-tab cursor-pointer ${galleryType === 'meta' && 'text-active'}`}>{`Meta`}</span>
                    </ControlContent>
                </ControlBody>
        </ControlCard>
    )
}
export default SettingsControl;