import { SpinnerPicker } from "App/Components/Plugins/spinner_picker";
import { getMobileOperatingSystem } from "App/Helper/utilities";
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import "./TimeSelector.scss";

const TimeSelector = React.memo(({ list, onSelect, value, hideSelectBar = false, show = false, showTitle = false, selectedBrdColor='185BC3', wheelBgColor='rgba(0,0,0,.5)' }) => {
    const selectedData = useMemo(() => {
        let font = 'ZenKaku';
        const os = getMobileOperatingSystem();
        if (os === 'iOS' || os === 'Safari') {
            font = 'ZenKaku'
        }
        return {
            selection_color: selectedBrdColor, font, font_color: '#fff', wheel_background: wheelBgColor
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const hrRef = useRef();
    const minRef = useRef();
    const secRef = useRef();
    const dataList = useMemo(() => {
        return {
            hr: [...Array(24).keys()],
            min: [...Array(60).keys()],
            sec: [...Array(60).keys()]
        }
    }, [])
    const [timeValue, setTimeValue] = useState({
        hr: '0',
        min: '0',
        sec: '0'
    })
    const timeOuter = useRef()
    const applyToggle = useCallback((mode) => {
        const parent = timeOuter.current;
        const classname = 'time-selector-dropdown-open'
        if (mode) {
            parent?.classList.add(classname);
        } else {
            parent?.classList.remove(classname);
        }
    }, [timeOuter])
    useEffect(() => {
        if(hideSelectBar) {
            applyToggle(show)
        }
    },[hideSelectBar, applyToggle, show])
    const onFocusHandler = () => {
        applyToggle(true)
    }
    const onBlurHandler = () => {
        applyToggle(false)
    }
    const valueUpdateHandler = (mode, value) => {
        const obj = {
            [mode]: value
        }
        setTimeValue((prev) => {
            return {
                ...prev,
                ...obj
            }
        })
    }
    const onHourChanged = useCallback((e) => {
        valueUpdateHandler('hr', e);
    }, [])
    const onMinChanged = useCallback((e) => {
        valueUpdateHandler('min', e);
    }, [])
    const onSecChanged = useCallback((e) => {
        valueUpdateHandler('sec', e);
    }, [])
    const initHours = useCallback((initialValue) => {
        hrRef.current.instance = new SpinnerPicker(
            hrRef.current,
            (index) => {
                if (index < 0 || index >= dataList.hr.length) {
                    return null;
                }
                return dataList.hr[index];
            },
            { index: initialValue, selection_color: selectedData.selection_color, font: selectedData.font, font_color: selectedData.font_color, wheel_background: selectedData.wheel_background },
            onHourChanged
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList.hr, onHourChanged])
    const initMin = useCallback((initialValue) => {
        minRef.current.instance = new SpinnerPicker(
            minRef.current,
            (index) => {
                if (index < 0 || index >= dataList.min.length) {
                    return null;
                }
                return dataList.min[index];
            },
            { index: initialValue, selection_color: selectedData.selection_color, font: selectedData.font, font_color: selectedData.font_color, wheel_background: selectedData.wheel_background },
            onMinChanged
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList.min, onMinChanged])
    const initSec = useCallback((initialValue) => {
        secRef.current.instance = new SpinnerPicker(
            secRef.current,
            (index) => {
                if (index < 0 || index >= dataList.sec.length) {
                    return null;
                }
                return dataList.sec[index];
            },
            { index: initialValue, selection_color: selectedData.selection_color, font: selectedData.font, font_color: selectedData.font_color, wheel_background: selectedData.wheel_background },
            onSecChanged
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList.sec, onSecChanged])
    const patchValue = useCallback((value) => {
        const { hr = 0, min = 0, sec = 0 } = value;
        const secIndex = dataList.sec.findIndex(x => Number(sec) === x);
        const minIndex = dataList.min.findIndex(x => Number(min) === x);
        const hrIndex = dataList.hr.findIndex(x => Number(hr) === x);
        setTimeout(() => {
            if (secRef?.current?.instance) {
                secRef.current.instance.setIndex(secIndex);
            }
            if (minRef?.current?.instance) {
                minRef.current.instance.setIndex(minIndex);
            }
            if (hrRef?.current?.instance) {
                hrRef.current.instance.setIndex(hrIndex);
            }
        });
    }, [dataList.hr, dataList.min, dataList.sec])
    useEffect(() => {
        onSelect(timeValue)
    }, [onSelect, timeValue])
    useEffect(() => {
        if (value) {
            setTimeValue(value)
            patchValue(value)
        }
    }, [patchValue, value])
    useEffect(() => {
        initHours(0);
        initMin(0);
        initSec(0);
    }, [initHours, initMin, initSec])
    return (
        <div className={`time-selector-wrapper`} ref={timeOuter} tabIndex={-1} onFocus={onFocusHandler} onBlur={onBlurHandler}>
            {!hideSelectBar && 
                <div className="time-selector-value">{`${timeValue.hr} hours ${timeValue.min} min ${timeValue.sec} sec`}
                </div>
            }
            <div className="time-selector-dropdown">
                {showTitle && 
                    <div className="text-left time-head p-[0.71429rem] pl-0">
                        <span>Timer</span>
                    </div>
                }
                <div className="flex justify-between gap-4">
                    <div className="time-selector-menu">
                        <div className="time-selector-label">Hour</div>
                        <canvas ref={hrRef}></canvas>
                    </div>
                    <div className="time-selector-menu">
                        <div className="time-selector-label">Min</div>
                        <canvas ref={minRef}></canvas>
                    </div>
                    <div className="time-selector-menu">
                        <div className="time-selector-label">Sec</div>
                        <canvas ref={secRef}></canvas>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default TimeSelector;