import React, { useEffect, useMemo, useState } from 'react';
import ModalOuter from '../ModalOuter';
import closeIcon from "Assets/Icons/close.svg";
import { useStore } from 'App/hooks-store/store';
import CloseIcon from 'App/Components/IconsComponents/CloseIcon';

const UserGuidePopup = () => {
    const [open, setOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState(null);
    const [{ activeMenuIcons }, dispatch] = useStore();

    const paths = useMemo(() => {

        return [
            { kbKeyPath: `/my-collection/manage`, ourKey: '/curate/collections/my-collections' },
            { kbKeyPath: `/my-collection/manage`, ourKey: '/curate/collections/my-tokens' },
            { kbKeyPath: `/my-collection/display/galleries`, ourKey: '/curate/display/my-galleries' },
            { kbKeyPath: `/my-collection/display/galleries`, ourKey: '/curate/display/my-galleries/add/collection' },
            { kbKeyPath: `/my-collection/manage`, ourKey: '/curate/frame-shop' },
            { kbKeyPath: `/my-collection/activities`, ourKey: '/curate/activities/posts' },
            { kbKeyPath: `/my-collection/activities`, ourKey: '/curate/activities/events' },
            { kbKeyPath: `/my-collection/display/displays`, ourKey: '/curate/display' },
            { kbKeyPath: `/my-collection/display/rentals`, ourKey: '/curate/rentals' },

            { kbKeyPath: `/my-collection/read`, ourKey: '/read/collections/my-collections' },
            { kbKeyPath: `/my-collection/listen`, ourKey: '/listen/collections/my-collections' },

            { kbKeyPath: `/discover/communities`, ourKey: '/discover/all-communities' },
            { kbKeyPath: `/discover/collectors`, ourKey: '/discover/collectors' },
            { kbKeyPath: `/discover/curators`, ourKey: '/discover/artists' },
            { kbKeyPath: `/discover/museums`, ourKey: '/discover/museums' },

            { kbKeyPath: `/partners/the-monet-society`, ourKey: '' },
            { kbKeyPath: `/partners/splash`, ourKey: '' },

            { kbKeyPath: `/explore/art`, ourKey: '/explore/art' },
            { kbKeyPath: `/explore/avatars`, ourKey: '/explore/avatars' },
            { kbKeyPath: `/explore/literature`, ourKey: '/explore/books' },
            { kbKeyPath: `/explore/literature`, ourKey: '/explore/literature' },
            { kbKeyPath: `/explore/collectibles`, ourKey: '/explore/collectibles' },
            { kbKeyPath: `/explore/movies`, ourKey: '/explore/movies' },
            { kbKeyPath: `/explore/music`, ourKey: '/explore/music' },
            { kbKeyPath: `/explore/photography`, ourKey: '/explore/photography' },
            { kbKeyPath: `/explore/sports`, ourKey: '/explore/sports' },
        ]
    }, []);

    const handleGetKnowledgeBoxPath = (dynamicPath) => {
        if (!dynamicPath) { return };

        let urlKey = '';
        paths?.forEach((path)=>{
            if(dynamicPath === path?.ourKey){
                urlKey = path?.kbKeyPath;
                return;
            }
            if(dynamicPath?.includes(`/my-galleries/add/collection`)){
                urlKey = `/my-collection/display/galleries`;
                return;
            }
        })
        const fullURL = `https://cur8.gitbook.io/cur8-knowledge-base${urlKey}`;

        setCurrentPath(fullURL);

        // for local testing
        // window.open(fullURL, '_blank');
    }

    useEffect(() => {
        if (!!activeMenuIcons?.userGuide) {
            handleGetKnowledgeBoxPath(activeMenuIcons?.userGuide?.dynamicPath);
            setOpen(activeMenuIcons?.userGuide?.open)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenuIcons, activeMenuIcons?.userGuide]);

    const handleClose = () => {
        dispatch("setActiveIcons", { userGuide: { open: false } });
        setCurrentPath(null);
        setOpen(false);
    }

    return (<>
        {open && <ModalOuter onClose={() => { }}
            openModal={open}
            parentClass={open ? '!z-[101]' : '!hidden !z-[10]'}
            contentClass={'animated-slideIn w-[100%] min-h-[600px] h-[92%] max-h-[100vh] m-[2rem] bg-[var(--cu-black-700)] border border-[var(--cu-black-100)] p-[1rem] rounded-md overflow-y-auto'}
        >
            <div className="w-full flex items-center justify-end pb-[1rem]" >
                {/* <h2 className="text-[1.125rem]">User Guide</h2> */}
                <button className='opacity-80 hover:opacity-100' onClick={handleClose}><CloseIcon/></button>
            </div>

            <div className="w-full h-[95%]" >
                <iframe
                    className={``}
                    src={currentPath}
                    width="100%"
                    title="Knowledge box"
                    height="100%"
                ></iframe>
            </div>

        </ModalOuter>}
    </>)
}

export default UserGuidePopup