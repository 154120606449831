import React, { useCallback, useEffect, useState } from 'react'
import styles from "./ProfilePic.module.scss"
import userAvatar from 'Assets/Icons/userAvatars.svg'
import userAvatarHover from 'Assets/Icons/avatar_hover.svg'
import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http';

const ProfilePicBannerMobile = (props) => {
    const [user, setUser] = useState({})
    const dispatch = useStore(false)[1];
    const { triggerAPI } = useHttp();
    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //Get profile Api
    const getProfileResult = useCallback((res) => {
        dispatch('hideSpinner');
        setUser(res.data)
    }, [dispatch])
    const getProfile = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    }, [dispatch, getProfileResult, triggerAPI])

    // ProfilePic change handler
    const profilePicChangeResponse = useCallback((res) => {
        dispatch('hideSpinner');
        getProfile()
        dispatch("setProfilePic")
        // dispatch('showToast', { toast: { toastMode: 'success', message: "Profile Image uploaded successfully" } })
    }, [dispatch, getProfile])

    const profilePicChangeHandler = e => {
        const type = parseInt(e.currentTarget.dataset?.index);
        if (e.target.files.length) {
            dispatch('showSpinner')
            const formData = new FormData();
            formData.append("image", e.target.files[0]);
            formData.append("type", type);
            triggerAPI({
                url: `user/profile-image/update`, data: formData, method: 'patch'
            }, profilePicChangeResponse, err => {
                dispatch('hideSpinner');
                dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
            });
            e.target.value = null;
        }
    }

    return (
        <div className={`${styles['card']} flex-col  ${props?.className}`}>
            <div className={styles["profile-pic-box"]}>
                <label className='cursor-pointer' htmlFor="upload-button">
                    {!props?.readOnly && <img alt="No Img" src={userAvatarHover} className={`${styles['img-top']}`}></img>}
                    {!user.profilePicUrl && <img className={`${styles['user-avatar']}`} alt="NoImg" src={userAvatar}></img>}
                    {user.profilePicUrl && <img alt="No Img" className={`${styles['user-avatar']} ${styles['profile-pic']}`} src={user.profilePicUrl}></img>}
                </label>
                {!props?.readOnly && 
                <input
                    type="file"
                    id="upload-button"
                    className={styles["hidden-input"]}
                    data-index={'2'}
                    onChange={profilePicChangeHandler}
                />}
            </div>
            <div className={`${styles['profile-image-container']}`} style={{backgroundImage:`url(${user?.bannerPicUrl})`}}>                
                {!props?.readOnly && <div className={`${styles['upload-btn']}`}>
                    <label className='cursor-pointer' htmlFor="file-upload">
                        <span>Upload Banner</span>
                    </label>
                    <input
                        type="file"
                        id="file-upload"
                        className={styles["hidden-input"]}
                        data-index={'1'}
                        onChange={profilePicChangeHandler}
                    />
                </div>
                }
            </div>

            {/* <img onClick={profileNavToggleHandler} className={`${styles['profile-nav-toggle']} ml-auto`} alt='NoImg' src={downArrow} /> */}
        </div>
    )
}

export default ProfilePicBannerMobile
