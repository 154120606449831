import React, { useEffect, useState } from 'react';
import userGroupIcon from "Assets/Icons/TopMenu/filter-icon.svg";
import './multiDropdown.scss';
import { useLocation } from 'react-router-dom';
import selectedCheckBlue from 'Assets/Icons/greySelectedCheck.svg'
import unselectedCheckBlue from 'Assets/Icons/greyUnselectedCheck.svg'
import FilterIcon from 'App/Components/IconsComponents/FilterIcon';
import CheckedIcon from 'App/Components/IconsComponents/CheckedIcon';
import UncheckedIcon from 'App/Components/IconsComponents/UncheckedIcon';

function ReusableMultiDropdown(props) {
    const location = useLocation();
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true);
    const [isIds, setIsIDs] = useState(false)

    const handleCollectIds = (updatedArray) => {
        let ids = [];
        updatedArray?.forEach((item) => {
            const subIds = [];
            item?.subcategories?.forEach((sub) => {
                if (sub?.isSelected) {
                    subIds.push(sub?.id)
                }
            })
            if (subIds?.length) {
                ids = [...ids, ...subIds]
            }
        });
        setIsIDs(ids?.length > 0)
        props.onChange(ids);
    }

    const handleCategoryClick = (categoryId) => {
        if (!categoryId) return;
        const filterArray = dataList?.map((item) => ({
            ...item, isSelected: categoryId === item?.id ? !item?.isSelected : item?.isSelected,
        }));
        setDataList(filterArray);
    };

    function handelSubjectSelect(mainId, subId) {
        if (!mainId || !subId) return;
        const filterArray = dataList?.map((item) => ({
            ...item,
            subcategories: item?.subcategories?.map((sub) => ({
                ...sub,
                isSelected: item?.id === mainId && sub?.id === subId ? !sub?.isSelected : sub?.isSelected,
            })),
        }));
        handleCollectIds(filterArray)
        setDataList(filterArray);
    }

    const handleClear = () => {
        if (!isIds) return
        const clearedData = dataList?.map((item) => ({
            ...item,
            isSelected: false,
            subcategories: item?.subcategories?.map((sub) => ({
                ...sub,
                isSelected: false,
            })),
        }));
        setDataList(clearedData);
        props.onChange([]);
        setIsIDs(false);
    }


    useEffect(() => {
        setLoading(true)
        if (props?.options?.length) {
            setDataList(props.options);
        }
        const timeout = setTimeout(() => setLoading(false), 1500)
        return () => {
            handleClear();
            clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, props?.options]);
    const ImageIcon = props?.imageIcon;
    return (
        <div className={`r-multi-dropdown-container ${loading && 'cursor-not-allowed'}`}>
            <div className="cursor-pointer filter-icon-wrapper" >
               {!props?.imageIcon?props?.icon?<img
                    src={props?.icon || userGroupIcon}
                    alt="icon"
                    className={`icon`}
                />:<FilterIcon />
                :<div className='arrow grid place-items-center '>
                <ImageIcon />
                </div>}
            </div>

            {!loading && <div className="r-multi-dropdown top-10 -left-4">
                <div className="clear-box"> <button onClick={handleClear}>Clear all</button></div>
                <ul className='main-ul'>
                    {dataList?.length && dataList?.map((category) => (
                        <li key={category.id}>
                            <div
                                className='flex flex-col'
                                onClick={() => handleCategoryClick(category.id)}
                            >
                                <label>{category.name}</label>
                                {!category?.isSelected && <ul className="ul-2">
                                    {category?.subcategories?.length && category?.subcategories?.map((subcategory) => (
                                        <li onClick={(e) =>
                                            e.stopPropagation()
                                        }
                                            key={subcategory.id}>

                                            <button onClick={() =>
                                                handelSubjectSelect(category.id, subcategory.id)
                                            } className='flex gap-1 px-2 '>
                                                {/* <img alt='No Img' src={subcategory?.isSelected ? selectedCheckBlue : unselectedCheckBlue} /> */}
                                                {subcategory?.isSelected?<CheckedIcon/>:<UncheckedIcon/>}
                                                {subcategory.name}
                                            </button>
                                        </li>
                                    )
                                    )}
                                </ul>}
                            </div>


                        </li>
                    ))}
                </ul>
            </div>}
        </div>
    );

}

export default ReusableMultiDropdown;