import storage from "App/Helper/storage";
import { useStore } from "App/hooks-store/store";
import { useCallback, useEffect } from "react";
import { useState, useMemo } from "react";
import { useConnectWallet, useSetChain } from '@web3-onboard/react'
import useHttp from './use-http';

const useWalletEVM = () => {    
    const [walletConnected, setWalletConnected] = useState(false);    
    const [walletNameConnected, setWalletNameConnected] = useState('');
    const [connectedAddress, setConnectedAddress] = useState('');    
    const [isConnecting, setIsConnecting] = useState(false); 
    const dispatch = useStore(false)[1];
    const store = useStore()[0];    
    const { triggerAPI } = useHttp();
    let { blockchains, currentWallet, debugWalletAddress } = store;
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()    
    const [
        {
          chains, // the list of chains that web3-onboard was initialized with
          connectedChain, // the current chain the user's wallet is connected to
          settingChain // boolean indicating if the chain is in the process of being set
        },
        setChain // function to call to initiate user to switch chains in their wallet
      ] = useSetChain()  

    const getNFTs = async (_wallet) => {
        let assets = await _wallet.getAssets();
        return assets.filter(a=> a.quantity == 1).map(a => { 
            return {asset: a.unit, policy_id: a.policyId, asset_name: a.unit.replace(a.policyId,'')}
        });    
    }
    useEffect(() => {
        async function updateWalletStatus() {
            //we should be able to use this to connect on refresh, but wallet is always null and errors out
            //no idea why wallet is null??
            const name = storage().get('currentWallet');
            let address = storage().get('cwa');
            if (address)
            {
                const decoder = new TextDecoder();                
                address = decoder.decode(new Uint8Array(Object.values(JSON.parse(address))).buffer);                
            }
            address = debugWalletAddress??address;
            if (blockchains && (wallet?.provider || address))
            {   
                let chain;         
                if (wallet)
                {
                    chain = blockchains.filter(c=> c.chainId === wallet.chains[0].id)?.[0];  
                    address = wallet.accounts[0].address;
                }
                else
                {
                    chain = blockchains.filter(c=> c.name === name)?.[0];    
                }                
    
                //only reset current wallet, if the new wallet is different from the current wallet
                if((wallet?.accounts?.[0]?.address !== currentWallet?.address
                    ||chain?.code !== currentWallet?.chain))
                {
                    storage().set('currentWallet', chain.name);            
                    triggerAPI({
                        url: `user/wallet/nftids?chain=${chain.code}&wallet_address=${address}`, method: 'get'
                    }, (res)=>{
                        let assets = res.data.map(d=>{ let data = d.split("."); return { asset: data[1]+data[2], asset_name: data[2], policy_id: data[1], contractAddress: data[1], chain: data[0]}});
                        dispatch('setCurrentWallet', { walletName: chain.name, address: address, chain: chain.code, NFTs: assets})
                        dispatch('setWalletUpdatingStatus', true)            
                        setWalletNameConnected(chain.name);
                        setWalletConnected(true);
                        setConnectedAddress(address);

                        const encoder = new TextEncoder();
                        storage().set('cwa', JSON.stringify(encoder.encode(address)));
                    });                 
                //setCurrentNetwork(`EVM-${wallet.chains[0]?.id}`)
                //         if (_network === 0) setCurrentNetwork("Testnet");
                //         if (_network === 1) setCurrentNetwork("Ma  
                }                      
            }
        }

        if (!isConnecting)
        {
            updateWalletStatus();
        }
    }, [wallet, blockchains, isConnecting, debugWalletAddress])

    const connectWallet =  useCallback(async (walletId) => {        
        let _wallet;
        try {
            setWalletConnected(false);
            setWalletNameConnected("");
            setIsConnecting(true);
            await connect();
            setChain({chainId: blockchains?.filter(c=> c.name === walletId)?.[0]?.chainId})            
            setIsConnecting(false);
        } catch (error) {
            
        }        
        return _wallet;
    },[dispatch]);
    const disConnectWallet =  useCallback(async () => {        
        try {
            setWalletConnected(false);
            await disconnect(wallet);         
            setIsConnecting(false);
        } catch (error) {
            console.log('diconnecting getting error', error);
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[wallet]);
    const memoedValue = useMemo(
        () => ({
            wallet,
            connecting,
            walletNameConnected,
            walletConnected,
            connectWallet,
            disConnectWallet,
            connectedAddress,
            debugWalletAddress            
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet, connecting, walletNameConnected, walletConnected, connectedAddress, debugWalletAddress, disConnectWallet]
    );

    return memoedValue
}
export default useWalletEVM