import { useStore } from "App/hooks-store/store"
import useHttp from "./use-http";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableFrameData, setAvailableFrameData, setUserFrameList } from "App/ReduxStore/Slicers/frameSlice";
import { deepCopy } from "App/Helper/utilities";

const useFrames = () => {
    const [{ frameAssets = [] }, dispatch] = useStore();
    const avilableFrames = useSelector(getAvailableFrameData);
    const dispatchRedux = useDispatch();
    const { triggerAPI } = useHttp();

    const getFrameAPI = (frameId, callback) => {
        triggerAPI({
            url: `frame-asset/get/${frameId}`, method: 'get'
        }, callback);
    }
    
    const getFrameFromStore = useCallback((id, callback) => {
        try {
            const found = [...avilableFrames]?.find((res) => res.id === id)
            if (found) {
                callback(found)
            } else {
                getFrameAPI(id, ({ data }) => {
                    dispatch('setFrameAsset', [data])
                    dispatchRedux(setAvailableFrameData([data]))
                    callback(data)
                })
            }
        } catch (error) {
            console.log(error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[frameAssets])
    const getFrameDetails = (data, callBack) => {
        const params = new URLSearchParams({
            ids: data
        });
        triggerAPI({
            url: `frame-asset/ids/get?${params}`, method: 'get'
        }, (res) => {
            dispatchRedux(setAvailableFrameData(res?.data))
            callBack && callBack(res);
        });
    }
    const getUserFrames = useCallback((callBack, fetchFrameDetail = false) => {
        try {
            triggerAPI({
                url: `user/nft-collection/frame-it/user/get`, method: 'GET'
            }, (res) => {
                dispatchRedux(setUserFrameList(deepCopy(res?.data)))
                callBack(res?.data);
                let tempArray = [...res?.data], fetchIds = [];
                tempArray?.forEach(item => {
                    if(!fetchIds?.includes(item?.frameId)  && !avilableFrames?.some(avail => avail?.id === item?.frameId)) {
                        fetchIds.push(item?.frameId)
                    }
                })
                if(fetchFrameDetail && fetchIds?.length) {
                    getFrameDetails(fetchIds);
                }
            });
        } catch (error) {
            console.log(error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[avilableFrames])

    const getFrameList = useCallback(({framePayload}, callBack) => {
        let page = 1;
        let temp = [];
        const triggerFetchFrameList = () => {
            let data = {
                "page": page
            }
            data = {...data, ...framePayload}
            triggerAPI({
                url: `frame-asset/user/get`, data: data, method: 'post'
            }, (res) => {
                const { list, pageMeta } = res.data;
                let arr_temp = list.map(frame => {
                    return {
                        id: frame.id,
                        name: frame.name,
                        s3ImageUrl: frame.s3ImageUrl,
                        s3ImageUrlLandscape: frame.s3ImageUrlLandscape,
                        s3ImageUrl256: frame.s3ImageUrl256,
                        s3ImageUrlLandscape256: frame?.s3ImageUrlLandscape256,
                        s3ImageUrl512: frame.s3ImageUrl512,
                        s3ImageUrlLandscape512: frame?.s3ImageUrlLandscape512,
                        price: frame.price ? frame.price : "00",
                        inStock: 30,
                        metaInfo: frame.metaInfo,
                        isLandscape: false,
                        isUserAdded: frame.isUserAdded,
                        ageId:frame?.ageId,
                        shapeId:frame?.shapeId,
                        colorId: frame.colorId,
                        color: frame.color,
                        categoryId: frame.categoryId
                    }
                })
                temp = [...temp, ...arr_temp]
                if (temp.length < pageMeta.totalItems) {
                    page++;
                    triggerFetchFrameList();
                } else {
                    callBack({...res.data, list: temp});
                }

            });
        }
        triggerFetchFrameList();
    }, [triggerAPI])

    return {
        getFrameFromStore,
        getFrameAPI,
        getUserFrames,
        getFrameDetails,
        getFrameList
    }
}
export default useFrames