import { useStore } from 'App/hooks-store/store'
import useHttp from 'App/hooks/use-http'
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import DisplayMyGalleries from "./Components/DisplayMyGalleries";
import { useParams } from "react-router-dom";
import storage from "App/Helper/storage";
import useAuth from 'App/hooks/use-auth';
import closeIcon from "Assets/Images/close-circle-icon.svg"
import Border from 'App/Pages/Discover/Components/SubComponents/Border';
import GalleryPreviewPlugin from 'App/Pages/GalleryPreview/Component';
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import './DisplayItem.scss';
import useShapes from 'App/hooks/use-shapes';
import SkeletonLoader from 'App/Components/Ui/SkeletonLoader';
import Input from 'App/Components/Ui/Input'
import Label from 'App/Components/Ui/Label'
import CheckBox from 'App/Components/Ui/CheckBox'
import Logo from 'App/Components/Logo';
import { convertRemToPixels } from "App/Helper/aspectRatioCalc";
import InformationIcon from 'App/Components/IconsComponents/InformationIcon';
import { metaWallListData } from 'App/Helper/utilities';

const DisplayItem = (props) => {    
    const [activeTab, setActiveTab] = useState("mygalleries");        
    let navigate = useNavigate();
    const { id } = useParams();
    const { triggerAPI } = useHttp();
    const [display, setDisplay] = useState(null);
    const [galleryList, setGalleryList] = useState([])
    const [gallery, setGallery] = useState(null)
    const [{ currentWallet}, dispatch] = useStore(false);
    const isLoggedIn = useAuth()[0]
    const [displayGallery, setDisplayGallery] = useState(null)
    const [isSaved, setIsSaved] = useState(true);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeGallerData, setActiveGalleryData] = useState(null)
    const [standAloneDetails, setStandAloneDetails] = useState([])
    const [activeGalleryId, setActiveGalleryId] = useState(0)
    const [shapes, setShapes] = useState(shapesEmpty);
    const [modifiedGalleryControls, setModifiedGalleryControls] = useState(null)
    const galleryContainerRef = useRef(null);
    const metaWallList = metaWallListData();
    const {  getShapes } = useShapes();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };

    const onCloseHandler = ()=> {
        navigate(-1);
    }

    const onSaveHandler = ()=> {
        // modifiedGalleryControls have all the changes that user made in display gallery preview
        const userPreference = {...modifiedGalleryControls};
        setIsSaved(false)
        let data = {...displayGallery,
                    ...{display: {
                        name: display.name,
                        isRebootQueued: display.isRebootQueued,
                        isSoftwareUpdateCheckQueued: display.isSoftwareUpdateCheckQueued,
                        rebootScheduleTime: display.rebootScheduleTime,
                        rebootScheduleDaysInterval: display.rebootScheduleDaysInterval
                    }},
                    userPreference,
                }
        triggerAPI({
            url: `display/updategallery`, data: data
            , method: 'post'
        }, (res) => {
            setIsSaved(true) 
            dispatch('showToast', { toast: { toastMode: 'success', message: "Display Gallery Updated" } })
            //navigate("/display");
        }, err => {
            setIsSaved(true) 
            dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message||"Display Gallery Update Failed" } })
        });        
    }

    const onGalleryPageTypeClick = (pageType)=> {        
        setDisplayGallery({...displayGallery, type:pageType, userPreference:{...displayGallery?.userPreference, galleryType:pageType} });
        
        // updating user selected view
        setModifiedGalleryControls((prevState)=>({...prevState, galleryType:pageType}));
    }

    const getGalleryDefaults = () => {
        return {
            displayId: display?.id,
            galleryId: display?.activeGalleryId,
            //timerMs: 10000, should come from the active user pref
            //isFramed: true,
            //frameAssetColorId: 2, should be loaded as black from link table
            //captionMins: 0,
            //captionPlacement: 'right',
            //isLiveRender: false,
            //isZoomed: false,
            //twoDWallBackgroundId: gallery?.twoDWallBackgroundId,
            //transition: 'fade',
            //shapes: null,
            //venue: 1,
            type: 'mono'
        }
    };

    const getDisplayGalleryResult = useCallback((res) => {                
        if (!res.data)    {            
            res.data = getGalleryDefaults();           
        }        
        setDisplayGallery(res.data); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, gallery]);
    
    const getDisplayGallery = useCallback(() => {           
        triggerAPI(
          {
            url: `display/getgallery?displayId=${display.id}&galleryId=${display.activeGalleryId}`,
            method: "get"        
          },
          getDisplayGalleryResult
        );
    }, [getDisplayGalleryResult, triggerAPI,display?.id,display?.activeGalleryId]);    

    const gallerySelected = useCallback((id)=> {    
        setDisplay({...display, activeGalleryId:id});  
        setActiveGalleryId(id);
        setPreviewLoading(true)
        setActiveGalleryData(null)    
    }, [display]);

    const getDisplayResult = useCallback((res) => {        
        setDisplay(res.data);           
        setActiveGalleryId(res?.data?.activeGalleryId);   
    }, []);


    useEffect(()=> {
        if (display?.id && display?.activeGalleryId) {
            getDisplayGallery(); 
        }        
        if (galleryContainerRef.current)
        {            
            let maxWidth = galleryContainerRef.current.clientWidth;
            let maxHeight = window.innerHeight - convertRemToPixels(20); //20 here is to account for the thumbnails and padding on height
            let res = display.modelInformation?.resolution;
            if (!res)
            {
                res = '1920x1080';
            }
            if (res?.indexOf("x") > -1)
            {
                let x = parseInt(res.split("x")[0]);   
                let y = parseInt(res.split("x")[1]);
                if (x > 0 && y > 0)
                {
                    if (display.modelInformation?.orientation?.toLowerCase() === "portrait")
                    {
                        let tempx = x;
                        x = y;
                        y = tempx;   
                    }
                    let ratioX = 1;
                    let ratioY = 1;                    
                    
                    if (x>maxWidth)
                    {
                        ratioX = maxWidth/x;
                    }
                    
                    if ((y)>maxHeight)
                    {
                        ratioY = maxHeight/(y);
                    }                 
                    
                    let ratio = Math.min(ratioX,ratioY);
                    x *= ratio;
                    y *= ratio;
                    galleryContainerRef.current.style = `width:${x}px;height:${y}px;`
                }
            }                        
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display?.id,display?.activeGalleryId, galleryContainerRef?.current])

    const getDisplay = useCallback(() => {                
        triggerAPI(
            {
            url: `display/get/${id}`,
            method: "get"        
            },
            getDisplayResult
        );
    }, [getDisplayResult, triggerAPI, id]);

    const getGalleryResult = useCallback((res) => {
        const { list } = res.data;
        setGalleryList(list)
           
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGalleryList = useCallback(() => {
        const token = storage().get('token');
        if(token) {
            let filter = {
                sortBy: "",
                orderBy: ""
            }
            if(!!(currentWallet?.address) || isLoggedIn) {
                triggerAPI({
                    url: `gallery/user/list/get`, data: {
                        page: 1,
                        items: 1000,
                        filter
                    }, method: 'post'
                }, getGalleryResult);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGalleryResult, triggerAPI])    

    useEffect(()=>{
        getGalleryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=> {
        getDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=> {
        if (display?.activeGalleryId && galleryList.length > 0)
        {   
            let gallery = galleryList.find(g => g.id === display.activeGalleryId);
            setGallery(gallery);   
            if (displayGallery && !displayGallery.id)
            {
                displayGallery.twoDWallBackgroundId = gallery.twoDWallBackgroundId;
            }
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryList,display, displayGallery])
    const standAloneResult = useCallback((res) => {
        const data = res?.data;
        let ratios = data?.Shapes?.map(shape => Number(shape.aspectRatio));
        setShapes(getShapes(ratios));
        setStandAloneDetails(prev => [...prev, {galleryId: data?.id, data}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getStandAloneDetails = useCallback((id) => {
        const url = 'gallery/stand-alone/get/';
        triggerAPI({
            url: `${url}${id}?isVerified=0&compression=0`, method: 'get'
        }, standAloneResult, (res) => {
            setActiveGalleryId(null);
            setActiveGalleryData(null);
            setDisplay(null);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [standAloneDetails]);
    useEffect(() => {
        if(activeGalleryId) {
            let tempActiveGallery = standAloneDetails?.find(item => item?.galleryId === activeGalleryId);
            if(tempActiveGallery) {
                let selectedGallery = tempActiveGallery?.data;
                let galleryInfo = {name: selectedGallery?.name, userName: selectedGallery?.userData?.username ?? ''};
                let layoutId = selectedGallery?.layoutId;
                let multiBg = selectedGallery?.thumbnailBackground;
                let metaBg = metaWallList?.find(item => item?.id === selectedGallery?.metaBg);
                let monoStyles = {styleType: selectedGallery?.monoStyleType}
                if(selectedGallery?.monoStyleType === 'custom') {
                    monoStyles = {...monoStyles, ...selectedGallery?.monoCustomized}
                }
                let initalTimes = {
                    hr: selectedGallery?.preference?.slideTimerHours ?? "0",
                    min: selectedGallery?.preference?.slideTimerMinutes ?? "0",
                    sec: selectedGallery?.preference?.slideTimerSeconds ?? "10"
                }
                let galleryData = {...selectedGallery};
                let activeGallery =  {
                    galleryInfo, layoutId, multiBg, initalTimes, galleryData, monoStyles, metaBg
                }
                setActiveGalleryData(activeGallery); 
                setPreviewLoading(false)
            } else {
                getStandAloneDetails(activeGalleryId);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeGalleryId, standAloneDetails])
    const handleGalleryView = (type) => {
        if(type)
            onGalleryPageTypeClick(type)
    }
    const onDisplayChange = (e) => {        
        setDisplay({...display, ...e});
    }
    const metaBgImg = useMemo(() => {
        let metaWall = displayGallery?.userPreference?.selectedMetaBg ?? activeGallerData?.metaBg;
        return metaWall?.imageUrl256 ?? '/metagalleryassets/images/meta1256.jpg';
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayGallery?.userPreference?.selectedMetaBg, activeGallerData?.metaBg])
    return  (
        <div className="display-item-overlay" onClick={onCloseHandler}>        
            <div className="display-item-content" onClick={(e) => e.stopPropagation()}>
                <div className="display-gallery-edit overflow-y-scroll pr-2">
                    {display?.activeGalleryId ? (<>
                        <div className="display-gallery-header">
                            <div className="display-header-info">
                                <div className="flex gap-4 items-center h-full">
                                    <p className="text-[1rem] text-[#F7F8F9] font-medium">
                                        {gallery?.name}
                                    </p>
                                    <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className='mt-[2px]'>
                                        {/* <img src={infoSVG} alt='' /> */}
                                        <InformationIcon/>
                                    </button>
                                    <div className='display-tag'>
                                        {display?.name}
                                    </div>
                                </div>                                
                            </div>
                            <div className={`actions`} >
                                <button disabled={!isSaved} className={!isSaved && 'cursor-not-allowed'} onClick={onSaveHandler}>{isSaved ? 'Save' : 'Saving...'}</button>
                                {/* <button onClick={onCloseHandler}>Close</button> */}
                            </div>
                            <div className="close-div  absolute top-3 right-3 h-[1.5rem] w-[1.5rem]">
                                <div className="close-icon" onClick={onCloseHandler}>
                                </div>
                            </div>
                        </div>
                        {previewLoading ? <SkeletonLoader height={`calc(100% - 13rem)`} /> : <div ref={galleryContainerRef} className="display-gallery-container m-auto">
                            <GalleryPreviewPlugin
                                key={activeGallerData?.galleryData?.id}
                                className='diplay-module-gallery-preview'
                                galleryData={activeGallerData?.galleryData} 
                                galleryType={displayGallery?.userPreference?.galleryType ?? displayGallery?.type ?? 'mono'}
                                isAppV1={false}
                                noEnlargeView
                                initalTimes={displayGallery?.userPreference?.currentTimer}
                                // layoutId={activeGallerData?.layoutId}
                                layoutId={displayGallery?.userPreference?.selectedMultiLayout?.layout?.id}
                                multiLayout={displayGallery?.userPreference?.selectedMultiLayout}
                                // multiBgData={activeGallerData?.multiBg}
                                metaBgData={displayGallery?.userPreference?.selectedMetaBg ?? activeGallerData?.metaBg}
                                multiBgData={displayGallery?.userPreference?.selectedMultiBg ?? activeGallerData?.multiBg}
                                settingsData={displayGallery?.userPreference?.settingControls}
                                screenData={displayGallery?.userPreference?.screenControls ?? activeGallerData?.monoStyles}
                                galleryInformation={activeGallerData?.galleryInfo}
                                handleGalleryView={handleGalleryView}
                                onCollectChanges={(state)=>setModifiedGalleryControls(state)}
                        /></div>}
                         {(isDropdownOpen && activeGallerData) &&                              
                                    <div className='display-info-overlay'>
                                        <div className='flex flex-col w-full gap-[1.5rem]'>
                                        <div className='flex flex-col gap-[0.5rem] '> 
                                            <div className='flex w-[100%] justify-between'>
                                            <div className='flex gap-2'>
                                            <img style={{borderRadius: "50%", height:"1.5rem", width:"1.5rem"}} src={gallery?.user?.profilePicUrl256 || Logo()} alt="" />
                                            <div className='font-medium text-[#F7F8F9] text-[1rem] '>
                                                    {gallery?.name}
                                            </div>
                                            </div>
                                                <img onClick={() => setIsDropdownOpen(!isDropdownOpen)} src={closeIcon} className='cursor-pointer' alt=''/>
                                        </div>
                                                <div className='text-[0.875rem] text-[#B8C0CC] text-left pl-[2.12rem]'>
                                                {gallery?.user?.name}
                                            </div>
                                        </div>
                                            <div>
                                                <div className="flex gap-[.5rem] details-container-display max-w-[20%]">
                                                <div className="data-box">
                                                    <div className="data-title">Pieces</div>
                                                    <div className="data-value">
                                                        {gallery?.assets?.length}
                                                    </div>
                                                </div>
                                                <Border className={"right-panel-v-border hidden"} />
                                                <div className="data-box items-mob">
                                                    <div className="data-title">Shapes</div>
                                                    <div className="data-value">
                                                    <img
                                                        className="w-auto h-[1rem] ml-2.5 shapes-img"
                                                        alt="Img"
                                                         src={shapes}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className='text-left text-[#F7F8F9] text-[0.875rem]'>
                                            {gallery.description}
                                            </div>
                                        </div>
                                    </div>}
                                    {(!previewLoading && activeGallerData?.galleryData?.id) && 
                                    <div className="gallery-type">
                                        {gallery?.files?.files?.length > 0 && (
                                            <div className={`image-container mono-image ${displayGallery?.type ==='mono'?'type-selected':''}`} onClick={(e)=> {onGalleryPageTypeClick("mono")}}>
                                                <img src={gallery.files.files[0]} alt='' />                            
                                            </div>
                                        )}
                                        {gallery?.files?.sliders?.length > 0 && (
                                            <div className={`image-container multi-image ${displayGallery?.type ==='multi'?'type-selected':''}`}
                                                style={{backgroundImage: `url(${gallery?.thumbnailBackground?.imageUrl256}`}}
                                                onClick={(e)=> {onGalleryPageTypeClick("multi")}}>
                                                <img src={gallery.files.sliders[0]} alt='' />                        
                                            </div>
                                        )}
                                        <div className={`image-container meta-image ${displayGallery?.type==='meta'?'type-selected':''}`} onClick={(e)=> {onGalleryPageTypeClick("meta")}}>
                                            <img src={metaBgImg} alt='' />                    
                                        </div>   
                                    </div>}
                            </>) : (
                        <>
                        <div className="display-gallery-none">Select a gallery from the list on the right to show on your display</div>
                        <div className="close-div  absolute top-3 right-3 h-[1.5rem] w-[1.5rem]">
                                <div className="close-icon" onClick={onCloseHandler}>
                                </div>
                            </div>
                            </>
                    )}
                </div>                                    
                <div className="display-gallery-lists">
                    
                        <div className="display-gallery-headertab-list mb-[1rem]">
                            <div className={`tab-datas flex gap-[1.42857rem]`}>
                                <div className={`tabs ${activeTab === "mygalleries" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("mygalleries")}>                                
                                    My Galleries
                                </div>
                                <div className={`tabs ${activeTab === "rentals" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("rentals")}>                                
                                    Rentals
                                </div>   
                                <div className={`tabs ${activeTab === "display" ? "active" : "not-active"}`}
                                    onClick={() => handleTabChange("display")}>                                
                                    Display
                                </div>   
                            </div>                        
                        </div>
                        <div className="tab-container-details">
                        <div className="tab-content">
                            {activeTab === "mygalleries" && (<DisplayMyGalleries gallerySelected={gallerySelected} galleryList={galleryList} activeGalleryId={display?.activeGalleryId} />)}
                            {activeTab === "rentals" && (<div className="rentals-content">Coming Soon</div>)}
                            {activeTab === "display" && (<div className="display-content">  
                                <div className='display-info-title'>Maintenance</div>
                                <div className='display-info'>
                                    <div>
                                        <Label className={`label-color text-left mb-[0.375rem]`}>Name
                                        </Label>
                                    </div>
                                    <div className="light-txt">
                                        <Input className={`display-info_input`} label="name" validateOnBlur type="text" onChange={onDisplayChange} controlName="name" value={display.name}> 
                                        </Input>                                        
                                    </div>
                                </div>
                                <div className='display-info'>
                                    <div>
                                        <Label className={`label-color text-left mb-[0.375rem]`}>Reboot at
                                        </Label>
                                    </div>
                                    <div className="light-txt w-[4.5rem]">
                                        <Input className={`display-info_input`} label="rebootScheduleTime" validateOnBlur type="text" onChange={onDisplayChange} controlName="rebootScheduleTime" value={display.rebootScheduleTime}> 
                                        </Input>                                                                   
                                    </div>
                                    <div>
                                        <Label className={`label-color text-left mb-[0.375rem]`}>Every
                                        </Label>
                                    </div>
                                    <div className="light-txt w-[2.5rem]">
                                        <Input className={`display-info_input`} label="rebootScheduleDaysInterval" validateOnBlur type="text" onChange={onDisplayChange} controlName="rebootScheduleDaysInterval" value={display.rebootScheduleDaysInterval}> 
                                        </Input>                                        
                                    </div>
                                    <div>
                                        <Label className={`label-color text-left mb-[0.375rem]`}>Day(s)
                                        </Label>
                                    </div>
                                </div>
                                <div className='display-info'>
                                    <div>
                                        <Label className={`label-color text-left`}>Reboot Queued
                                        </Label>
                                    </div>
                                    <div className="light-txt">
                                        <CheckBox label="isRebootQueued" validateOnBlur type="text" onClick={(e)=>onDisplayChange({isRebootQueued:e})} controlName="isRebootQueued" value={display.isRebootQueued}> 
                                        </CheckBox>                                        
                                    </div>
                                </div>
                                <div className='display-info'>
                                    <div>
                                        <Label className={`label-color text-left`}>Software Update Check Queued
                                        </Label>
                                    </div>
                                    <div className="light-txt">
                                        <CheckBox label="isSoftwareUpdateCheckQueued" validateOnBlur type="text" onClick={(e)=>onDisplayChange({isSoftwareUpdateCheckQueued:e})} controlName="isSoftwareUpdateCheckQueued" value={display.isSoftwareUpdateCheckQueued}> 
                                        </CheckBox>                                        
                                    </div>
                                </div>
                                
                                <div className='display-info-title'>Information</div>
                                <div className='display-info'>
                                    <div className='display-keys'>
                                        {display?.modelInformation && Object.entries(display.modelInformation)?.sort((a,b)=>a[0]>b[0]?1:-1).map(i=> {
                                            return (                                        
                                                    <div key={i} className="display-key">{i[0]}</div>                                            
                                            )
                                        })}                              
                                    </div>
                                    <div className='display-values'>
                                        {display?.modelInformation && Object.entries(display.modelInformation)?.sort((a,b)=>a[0]>b[0]?1:-1).map(i=> {
                                            return (                                        
                                                <div key={i} className="display-value">{i[1]}</div>                                        
                                            )
                                        })}                              
                                    </div>
                                </div>
                            </div>)}
                        </div>                        
                    </div>                                        
                </div>
            </div>      
        </div>
    )
}

export default DisplayItem