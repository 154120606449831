import { getSplice } from "App/Helper/utilities";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import Slider from "react-slick";
import GalleryPreviewImage from "../../../Pages/NftGallery/Component/MyGallery/Components/AddedNfts/Components/GalleryPreviewImage";
import "./SliderGallery.scss"

const SliderGallery = (props) => {
    const [sliderValue, setSliderValue] = useState([])
    var settings = useMemo(() => {
        return {
            dots: false,
            infinite: true,
            speed: 700,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <NextArrow showArrow={sliderValue?.length > 1} to="next"/>,
            prevArrow: <PrevArrow showArrow={sliderValue?.length > 1} to="prev" />,
        }
    },[sliderValue]);

    useEffect(() => {

        if (props?.selectedNfts?.length) {
            const result = getSplice(props?.selectedNfts, props?.selectedLayout?.count)
            setSliderValue(result)
        }
    }, [props?.selectedNfts, props?.selectedLayout?.count])

    return (

        <div className={` w-full `} >
            <Slider {...settings} className={`gallery-slider ${props.sliderClass}`} >
                {[...sliderValue]?.map((el, i) => {
                    return <div key={i} className="rounded-[.25rem] overflow-hidden" >
                        <GalleryPreviewImage 
                            loadSmallSize={true}
                            selectedLayout={props?.selectedLayout} 
                            addedGallery={props?.addedGallery} 
                            layoutType='Card-8' 
                            layoutCount={el?.length} 
                            src={props?.src} 
                            className={props?.className} 
                            selectedNfts={el} 
                        />
                    </div>
                })}

            </Slider>
        </div>
    );
}

const PrevArrow = (props) => {
    const { className, style, onClick, showArrow } = props;
    if(!showArrow) return null
    return(
      <div onClick={onClick} className={`arrow ${className}`} >
        <button className="arrows" style={{color:"white"}}/>
      </div>
    )
    }

function NextArrow(props) {
    const { className, style, onClick, showArrow } = props;
    if(!showArrow) return null
    return(
      <div onClick={onClick} className={`arrow ${className}`} >
        <button className="arrows" style={{color:"white"}}/>
      </div>
    )
  }


export default SliderGallery