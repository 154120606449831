import { useState, useEffect, useCallback, memo } from "react";
import useWallet from "App/hooks/use-wallet";
import "./WalletSelectPop.scss";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http"
import useAuth from "App/hooks/use-auth";
import storage from "App/Helper/storage";
import useWalletConnect from "App/hooks/use-wallet-connect";
import ModalOuter from "../ModalOuter";
import closeIcon from "Assets/Icons/close.svg"
import { setShowWalletPop } from 'App/ReduxStore/Slicers/authSlice'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cardanoWalletIcon from 'Assets/Icons/Blockchains/cardano.svg';
import Slider from "react-slick";
import CloseIcon from "App/Components/IconsComponents/CloseIcon";

const WalletSelectPop = (props) => {
    const [{ currentWallet }, dispatch] = useStore(false);
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedWallet, setSelectedWallet] = useState();
    const [registrationStatus, setRegistrationStatus] = useState(true)
    const walletConnector = useWallet();
    const { triggerAPI } = useHttp();
    const handleB2CLogin = useAuth()[6]
    const { installedWallets, connectHandler, walletAddHandler } = useWalletConnect();
    const [isLoggedIn] = useAuth();
    const [isLinking, setisLinking] = useState(false);

    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4
    };

    const getRegistrationStatusResponse = useCallback(
        (res) => {

            setRegistrationStatus(res?.data?.allowNewRegistration);
        },
        []
    );

    const getRegistrationStatus = useCallback(() => {

        triggerAPI(
            {
                url: `app-preference/get`,
                method: "get",
            },
            getRegistrationStatusResponse
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getRegistrationStatusResponse, triggerAPI]);
    useEffect(() => {
        getRegistrationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const selectWallet = useCallback((walletName) => {
        walletConnector.connectWallet(walletName);
    }, [walletConnector])
    useEffect(() => {
        const name = storage().get('currentWallet');
        if (name) {
            selectWallet(name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const connectedWalletstResult = useCallback((res) => {
        const val = res?.data;
        dispatch('hideSpinner');
        let connectedWallets = [];
        val?.forEach((element, index) => {
            let temp = {
                assetCount: element?.asset_details?.length ?? 0,
                id: element?.id,
                walletName: element?.walletName,
                walletId: index,
            }
            connectedWallets = [...connectedWallets, temp];
        });
        dispatch('updateCollectionWalletData', connectedWallets)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const getConnectedWallets = useCallback(() => {
        dispatch('showSpinner')
        triggerAPI({
            url: `user/connected-wallet/get`, method: 'get'
        }, connectedWalletstResult);
    }, [triggerAPI, connectedWalletstResult, dispatch])

    useEffect(() => {
        if (!currentWallet) {
            setSelectedWallet({})
        } else {
            if (isLinking) {
                walletAddHandler(currentWallet.address, currentWallet.chain, getConnectedWallets);
                setisLinking(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallet])

    const nameHandler = (name) => {
        return name?.replace("Wallet", "")
    }
    const linkWalletHandler = useCallback((e) => {
        const installUrl = e?.currentTarget?.dataset?.id, walletName = e.target.value;
        if (!installUrl && props?.linkWallet) {
            setisLinking(true);
        }
        connectHandler(installUrl, walletName);
        props?.linkClicked?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClosePopup = () => {
        if (props?.onClose) {
            props?.onClose();
            return;
        }
        reduxDispatch(setShowWalletPop(false));
        if (!isLoggedIn) {
            navigate('/home')
        }
    };

    if(!isLoggedIn){ return null}

    return (
        <ModalOuter openModal={props?.show}
            parentClass={props?.show ? '!z-[101]' : '!hidden !z-[10]'}
            customBGEffect={isLoggedIn ? 'modal-outer-container-custom-bg' : 'none-bg'}
            contentClass={'animated-slideIn w-[480px] max-w-[90%] h-auto max-h-[600px] bg-[var(--cu-black-550)] border border-[var(--cu-black-100)] rounded-lg wallet-select-pop flex flex-col items-center justify-center p-[2rem]'} onClose={()=>{}}>
            <div className={`w-full flex items-center justify-between `}>
                <span />
                <div className="head flex flex-col gap-4">
                    <div className="flex items-center gap-[0.6rem]">
                        <img src={cardanoWalletIcon} alt="cardano" className="w-[30px]" />
                        <p className="text-[1.2rem] text-[var(--cu-white-700)] mb-[0.32rem]">Cardano</p>
                    </div>
                </div>
                <button onClick={onClosePopup}>
                    {/* <img src={closeIcon} alt="close" /> */}
                    <CloseIcon/>
                </button>
            </div>
             <div>
                <p className="text-[0.8rem] text-[var(--cu-white-100)] pt-[1.6rem] pb-[2.2rem]">Connect Wallet</p>
            </div>
            <Slider {...settings} className={`flex gap-4 wallet-pop-container`} >
                {installedWallets?.map((wallet, index) => {
                    return <div className="wallet_box" key={index}>
                        <div className="wallet-icon">
                            <img src={wallet.icon} alt={wallet.name} />
                        </div>
                        <div className="wallet-label capitalize">
                            {(wallet.active && !isLinking) ? selectedWallet?.balance : nameHandler(wallet.name)}
                        </div>
                        <button className="wallet-button" data-id={wallet?.installUrl} onClick={linkWalletHandler} value={wallet.name}>
                            {((wallet?.status) ? 'Connecting' : 'Connect')}
                        </button>
                    </div>
                })}
            </Slider>
        </ModalOuter>
    )
}

export default WalletSelectPop