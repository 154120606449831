import React from 'react';
import styles from "./SelectedInformation.module.scss"

const SelectedInformation = ({ selectedData }) => {
  const positionStyle = {
    left: selectedData?.metaInfo?.image?.xPercent + "%",
    top: selectedData?.metaInfo?.image?.yPercent + "%",
    width: selectedData?.metaInfo?.image?.widthPercent + "%",
    height: selectedData?.metaInfo?.image?.heightPercent + "%",
  }
  return (
    <div className="h-full select-none">
      <div className={`relative w-full`}>
        <img src={selectedData.imageUrl} alt="Information Details" className={`${styles.image} relative`} />
        <div className="absolute" style={positionStyle}>
          {selectedData.videoUrl &&
            <iframe width="100%" height="100%" src={selectedData.videoUrl} title="Information"></iframe>}
        </div>
      </div>

    </div>
  )
}

export default SelectedInformation