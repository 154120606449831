/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/Ui/Pagination";
import AnnouncementsList from "../CommunityCollection/Community/Components/Announcements/Components/AnnouncementLists";
import "./Announcements.scss";
import useAuth from "App/hooks/use-auth";
import WalletSelectPop from "App/Components/Ui/WalletSelectPop";
import { useDispatch, useSelector } from "react-redux";
import { getLoggingOutStatus, getShowWalletPop,setShowWalletPop } from "App/ReduxStore/Slicers/authSlice";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";

const SCREENSIZE = window.screen.width;
const paginationConfig = {
  perPage:  8
};
const sortOptions = [
  // { id: 0, key: "", keyVal: "", value: "None" },
  { id: 1, key: "createdAt", keyVal: "DESC", value: "Latest to Earliest" },
  { id: 2, key: "createdAt", keyVal: "ASC", value: "Earliest to Latest" },
  { id: 3, key: "community", keyVal: "ASC", value: "Community (A-Z)" },
  { id: 4, key: "community", keyVal: "DESC", value: "Community (Z-A)" },
];
const Announcements = ({communityIds}) => {
  const [announcementList, setAnnouncementList] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const { triggerAPI } = useHttp();
  const [{activeMenuIcons,collectionWalletData,collectionLoadingStatus, currentWallet }, dispatch] = useStore();
  const isLoggedIn = useAuth()[0]
  const reduxDispatch = useDispatch();
  const showAuthPop = useSelector(getShowWalletPop);
  const loggingOutStatus=useSelector(getLoggingOutStatus)
  const [postCategories, setPostCategories] = useState([]);
  useEffect(()=>{
    if(!loggingOutStatus) reduxDispatch(setShowWalletPop((!isLoggedIn || (collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress"))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loggingOutStatus,isLoggedIn,collectionWalletData,collectionLoadingStatus])

  const onPageChangeHandler = (currentPage) => {
    setPage(currentPage);
  };

  const getPostCategories = useCallback(() => {
    triggerAPI(
      {
        url: `community/announcements/categories/get`,
        method: "get",
      },
      (res)=>setPostCategories(res?.data)
    );
  }, [triggerAPI]);

  const announcementListApiResult = useCallback(
    (res) => {
      const { list, pageMeta } = res.data;
      const updatedData = list?.map((val) => {
        return {
          ...val,
          createdAt: new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        };
      });
      setTotalPage(pageMeta.totalItems);
      setAnnouncementList(updatedData);
      setLoader(()=>false)
    },
    [dispatch]
  );

  useEffect(()=>{
    setPageLoaded(true)
  },[announcementList])
  const getAnnouncementList = useCallback(
    (pageNo) => {
      setLoader(()=>true)
      let filter = {};

      let sortBy = "";
      let orderBy = "";
      sortOptions.forEach((val) => {
        if (val?.value === activeMenuIcons?.sort) {
          sortBy = val?.key;
          orderBy = val?.keyVal;
        }
      });
      filter = {
        ...filter,
        sortBy: sortBy,
        orderBy: orderBy,
        categoryIds: activeMenuIcons?.CategoryActiveIds ?? [],
        communityIds:communityIds
      };

      if (activeMenuIcons?.displayType) {
        filter = { ...filter, status: ["all","read","unread"].includes(activeMenuIcons?.displayType)?activeMenuIcons?.displayType:"all" };
      } else {
        filter = { ...filter, status: "all" };
      }
      triggerAPI(
        {
          url: "user/announcements/get",
          data: {
            page: pageNo || page,
            items: paginationConfig.perPage,
            filter: filter,
            search: "",
          },
          method: "post",
        },
        announcementListApiResult
      );
    },
    [dispatch, SCREENSIZE,communityIds, announcementListApiResult, triggerAPI, page]
  );

  useEffect(() => {
    if(isLoggedIn && communityIds) getAnnouncementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.sort, currentWallet?.address,communityIds, activeMenuIcons?.CategoryActiveIds, page]);

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.CategoryActiveIds]);

  useEffect(() => {
    if(isLoggedIn) getPostCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if(SCREENSIZE>550){
      if(isLoggedIn && collectionWalletData?.length){
        dispatch("enableTopIcons", {
          all: false,
          read: false,
          sorting: true,
          unread: false,
          filterCategoryDropdown: true,
          userGuidePopup: true
        });
      } else {
        dispatch("enableTopIcons", {
          userGuidePopup: true
        });
      }
    dispatch("setTopMenuBarOptions", {
      showMenuIcons: true,
      showTopMenus: false,
    });
    dispatch("setSortingOptions", sortOptions);
    dispatch("setActiveIcons", {
      sort: sortOptions[0]?.value,
      displayType: "all",
    });
  // }
    return ()=> dispatch("resetAll");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn,collectionWalletData]);

  useEffect(()=>{
    dispatch("setCategoryDropdown", postCategories);
  },[postCategories])


  return (
    <>
      {/* <AuthPop show={showAuthPop} onTrialClick={handleTrialClick} onClose={setShowAuthPop} /> */}
      {showAuthPop && <WalletSelectPop show={showAuthPop} />}
      {!!loader&& <div className={`posts-skeleton-wrap ${(((!isLoggedIn||(collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress"))&&"hidden"}`}>
      <DetailsSkeltonCard 
        show={loader}
        height={"100%"}
        count={8}
        inline={true}
        noAbsolute
        details="posts"
        containerClassName={` flex pt-[1rem]`}
       />
       </div>}
      <div className={`list-announcement pt-4 sm:mb-0 mb-[3.5rem]community-announcements flex flex-col justify-between h-full ${(((!isLoggedIn||(collectionWalletData !== null && !collectionWalletData?.length))&&collectionLoadingStatus !== "in-progress")||loader)&&"hidden"} `}>
        <div className={`${pageLoaded ? '' : 'hidden'} overflow-y-auto announcements-listing pr-4`}>
          <AnnouncementsList
            //readApi={readAnnouncement}
            data={announcementList}
            normalAccordion={true}
            page="main"
            from="main-page"
          />
        </div>
        <div className="purchase-pagination mt-4 mb-5 ">
          {!!announcementList?.length && (
            <div className="purchase-pagination mb-5">
              <Pagination
                itemsPerPage={paginationConfig.perPage}
                rows={announcementList?.length}
                total={totalPage}
                onPageChange={onPageChangeHandler}
                active={page}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Announcements;
