import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setArtBank: (current, artBank) => {
            let currentData = current?.artBank ?? [] ;
            const data = [...currentData, ...artBank.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                artBankLoaded: 0,
                artBankLoading: artBank.loading,
                artBank: finalData,
                artBankCount: artBank.count
            }
        },
        setArtBankAssetsList: (current, assetsList) => {
            return {
                collectionAssetList: assetsList,

            }
        },
        updateArtBankCount: (current) => {
            if (current.artBankLoaded < current.artBankCount)
            return {
                artBankLoaded: current.artBankLoaded + 1
            }
        },
        updateArtBankLoadingStatus: (current,status) => {
            return {
                artBankLoadingStatus: status
            }
        },         
        updateArtBankRawData: (current,data) => {
            return {
                artBankRawData: data
            }
        },        
        updateArtBankAssetCount: (current, data) => {
            return {
                artBankAssetCount: data
            }
        },
        reFetchArtBank: (current) => {
            return {
                reFetchArtBank:  {mode:true}
            }
        },
        favArtBankToggle: (current, object) => {
            let find = current.artBank.find(collec => collec.policy === object.policy)
            if (find)
                find.isCollectionFavourite = object.value
            return {
                artBank: [...current.artBank]
            }
        },
        favArtBankAssetToggle: (current, object) => {
            let find = current.artBank.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                artBank: current.artBank
            }
        }
    }
    initStore(actions, {
        collectionAssetList: [],
        artBankLoading: false,
        artBankLoaded: 0,
        artBank: [],
        artBankCount: 0,
        artBankAssetCount: 0,
        artBankRawData: [],
        artBankLoadingStatus:'',
        reFetchArtBank: {mode:false}
    });
}
export default configureStore;