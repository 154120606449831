import { useEffect, useState } from "react";
import { db } from "../Helper/db";

let globalState = {};
let listeners = [];
let actions = {};

export const cachedkeys = [
    'allUserCollection',
    'allCollectionData',
    'collectionAssetCount',
    'collectionRawData',
    'collectionWalletData',
    'collections',    
    'schemaVersionNumber',
    'tokenAssetCount',
    'tokenRawData',
    'tokens',
    'username' ,
    'overAllAssets',
    'blockchains'
]

export const useStore = (shouldListen = true) => {
    const setState = useState(globalState)[1];

    const dispatch = (actionIdentifier, payload) => {
        const newState = actions[actionIdentifier](globalState, payload);

        for (const [id, value] of Object.entries(newState)) {  
            if (cachedkeys.findIndex(key => key===id)>-1)
            {
                if (id === 'username')
                {
                    if (!!globalState.username && (value !== globalState.username))
                    {
                        console.trace(`Clearing DB - Username changed old-${globalState.username??""} new-${value??""}`)
                        //if we have old data and you've logged in as someone else, clear that data
                        db.state.clear();
                        globalState = { ...globalState, triggerCollection: true };  //to trigger collection
                    }
                    else
                    {
                        //add the existing state including all the collection data as user is now set
                        globalState = { ...globalState, ...document.cur8dbstate };
                        globalState.isUserSet = true;
                    }
                    
                }
                db.state.put({id,value});
            }                      
        }

        globalState = { ...globalState, ...newState };               
        
        for (const listener of listeners) {
            listener(globalState)
        }
    }
    useEffect(() => {
        if (shouldListen) {
            listeners.push(setState)
        }
        return () => {
            if (shouldListen) {
                listeners = listeners.filter(li => li !== setState);
            }
        }
    }, [setState, shouldListen]);
    return [globalState, dispatch];
}

export const initStore = (userActions, initialState) => {
    if (initialState) {        
        // Request persistent storage for site
        // if (navigator.storage && navigator.storage.persist) {
        //     const isPersisted = navigator.storage.persist();
        //     console.log(`Persisted storage granted: ${isPersisted}`);
        // }

        if (initialState.schemaVersionNumber && (initialState.schemaVersionNumber !== document.cur8dbstate?.schemaVersionNumber))
        {
            console.trace("Clearing DB - Schema changed")
            db.state.clear();
            document.cur8dbstate = {};
            document.cur8dbstateminimal = {};
            db.state.put({id:"schemaVersionNumber",value:initialState.schemaVersionNumber});            
        }
        //by default only add the minimal dbstate, so as not to include collection data for a different user
        globalState = { ...globalState, ...initialState, ...document.cur8dbstateminimal??{} }                
    }
    actions = { ...actions, ...userActions }
}
export const destructStore = () => {
    globalState = {};    
}
export const clearDB = async (fullyClearDB) => {    
    console.trace("Clearing DB - clearDB fn")
    document.cur8dbstate = {};   
    document.cur8dbstateminimal = {};   
    globalState = {};  
    if (fullyClearDB)
    {
        db.state.clear();
    }
}