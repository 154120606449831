import RoundedButton from "App/Components/Ui/RoundedButton";
import "./NoContent.scss";
import CustomImage from "App/Components/Ui/CustomImage";
import MultiOptionsDropDown from "App/Pages/Discover/Components/SubComponents/MultiOptionsDropDown";

const NoContent = ({
  text,
  brief,
  buttonText,
  className,
  banner,
  onClick,
  multiCollectionCreateOptions,
}) => {
  return (
    <div className={`no-content-wrapper pt-16 pb-4 ${className}`}>
      <div className="flex flex-col gap-4">
      <h1>{text}</h1>
      <p>{brief}</p>
      </div>
      <CustomImage src={banner} skeltonCardHeight={"100%"} />
      {!!buttonText &&
        !multiCollectionCreateOptions &&
        !multiCollectionCreateOptions?.length && (
          <RoundedButton
            className="mt-4"
            Name={buttonText}
            Type="14"
            value={buttonText}
            OnClick={onClick}
          />
        )}
      {!!buttonText &&
        multiCollectionCreateOptions &&
        multiCollectionCreateOptions?.length && (
          <MultiOptionsDropDown
            dropDownPositionTop="0.2rem"
            dropDownPositionLeft={`0`}
            options={multiCollectionCreateOptions}
            mainButtonContent={
              <RoundedButton
                className="mt-4"
                Name={buttonText}
                Type="14"
                value={buttonText}
                OnClick={() => {}}
              />
            }
          />
        )}
    </div>
  );
};

export default NoContent;
