import "./SearchFilter.scss";
import SearchField from "./Components/SearchField";
import LgSearchIcon from "App/Components/IconsComponents/LgSearchIcon";

const SearchFilter = (props) => {
  return (
    <div className={`${props?.className ?? ""} flex items-center`}>
      <LgSearchIcon
        onClick={
          !props?.topMenuIcons?.searchDisable ? props?.onClick : () => {}
        }
        className={`img-icon ${
          props?.topMenuIcons?.searchDisable ? "cursor-not-allowed" : ""
        }`}
      />
      {!!props?.showSearchBar && (
        <SearchField
          hideLabel={props?.hideLabel}
          onSearch={props?.onSearch}
          value={props?.value}
          onClick={props?.onClick}
        />
      )}
    </div>
  );
};

export default SearchFilter;
