import { useStore } from "App/hooks-store/store"
import useHttp from "./use-http";
import shapesThree from "Assets/Images/shapesThree.svg";
import shapesSquare from "Assets/Images/shapesSquare.svg";
import shapesLandscape from "Assets/Images/shapesLandscape.svg";
import shapesPortrait from "Assets/Images/shapesPortrait.svg";
import shapesSquareLandscape from "Assets/Images/shapesSquareLandscape.svg";
import shapesSquarePortrait from "Assets/Images/shapesSquarePortrait.svg";
import shapesLandscapePortrait from "Assets/Images/shapesLandscapePortrait.svg";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import useAuth from "./use-auth";

const useShapes = () => {
    const [store] = useStore();
    const { collections } = store;
    const { triggerAPI } = useHttp();
    const isLoggedIn = useAuth()[0]
    const saveCollectionShape = async (collectionId, ratio, callback, collection) => {                
        if (!collection)
        {
            collection = collections.filter(c => c.collectionId === collectionId);         
            if (collection.length > 0)
            {            
                collection = collection[0];  
            }
        }      
        if (collection)
        {                        
            let aspectRatios = collection.aspectRatios?.split(',')?.filter(item => item);                         
            if (!aspectRatios?.includes(ratio.toString()))
            {   
                if (collection.aspectRatios?.length === 0)
                {
                    collection = {...collection, aspectRatios: ratio.toString()};
                }
                else
                {
                    collection = {...collection, aspectRatios: collection.aspectRatios += `,${ratio}`};
                }                         
                if(isLoggedIn)
                    triggerAPI(
                        {
                        url: `user/shape/add`,
                        data: [
                                { 
                                    collectionId: collection?.id || collection?.collectionId,
                                    aspectRatio: ratio                        
                                } 
                            ],
                        method: "post",
                        },
                        (res) => {                                                                        
                            if (callback)
                            {
                                callback();
                            }
                        }
                    );
            }    
        }
    }

    const getShapes = (ratios) => {
        let hasSquare = ratios?.filter(ar => ar===1).length>0;
        let hasLandscape = ratios?.filter(ar => ar>1).length>0;
        let hasPortrait = ratios?.filter(ar => ar!=="" && ar<1).length>0;    
        let colShape = shapesEmpty;
        if (hasSquare && !hasLandscape && !hasPortrait)
        {
            colShape = shapesSquare;
        }
        if (hasLandscape && !hasSquare && !hasPortrait)
        {
            colShape = shapesLandscape;
        }
        if (hasPortrait && !hasSquare && !hasLandscape)
        {
            colShape = shapesPortrait;
        }
        if (hasSquare && hasLandscape && !hasPortrait)
        {
            colShape = shapesSquareLandscape;
        }
        if (hasSquare && hasPortrait && !hasLandscape)
        {
            colShape = shapesSquarePortrait;
        }
        if (hasPortrait && hasLandscape && !hasSquare)
        {
            colShape = shapesLandscapePortrait;
        }
        if (hasPortrait && hasLandscape && hasSquare)
        {
            colShape = shapesThree;
        }
        return colShape;   
    }
        
    return {
        saveCollectionShape,
        getShapes
    }
}
export default useShapes