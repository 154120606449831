import { useState } from "react";
import Mono from "Assets/Images/mono.png";
import Multi from "Assets/Images/multi.png";
import "./GalleryPreviewSelection.scss";
import PreviewDummy from "Assets/Images/PreviewDummy.svg";
import editIcon from "Assets/Icons/edit-svg.svg"
import trashIcon from "Assets/Images/Bin.svg";
import editHover from "Assets/Icons/edit_hover.svg";
import trashHover from "Assets/Images/BinHover.svg";
import { useLocation } from "react-router-dom";
import { useStore } from "App/hooks-store/store";

const GalleryPreviewSelection = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [previewType, setPreviewType] = useState("");
  const { currentUserId }= useStore(false)[0];
  const handlePreview = (e) => {
    e.stopPropagation();
    if(props?.createdBy===currentUserId){
    setPreviewType(e?.currentTarget?.dataset?.type);
    props?.handlePreview&&props?.handlePreview(
      parseInt(e?.currentTarget?.dataset?.id),
      e?.currentTarget?.dataset?.type,
      e
    );
  }
  };
  const setPrimary=(e)=>{
    e.stopPropagation();
    props?.setPrimaryHandler && props?.setPrimaryHandler(e?.currentTarget?.dataset?.id);
  }
  const location = useLocation()
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isTrashHovered, setIsTrashHovered] = useState(false);

  const handleEditMouseEnter = () => {
    setIsEditHovered(true);
  };

  const handleEditMouseLeave = () => {
    setIsEditHovered(false);
  };

  const handleTrashMouseEnter = () => {
    setIsTrashHovered(true);
  };

  const handleTrashMouseLeave = () => {
    setIsTrashHovered(false);
  };
  return (
    <>
      <div className={`content-details fadeIn-left h-full ${props?.sidebar&&"sidebar-section"}`}>
        <div className="content-wrapper relative">
        {!!(props?.selectedCollection==="My Collections"&&(props?.priority!==1)&&(props?.isDiscoverable))&&<button data-id={props?.galleryID} onClick={setPrimary} className="set-primary">Set as primary</button>}
     
        {(location.pathname === '/curate/display/my-galleries' || props.editGallery) && (
            <div className="icons-overlay">
              <div
                onMouseEnter={handleEditMouseEnter}
                onMouseLeave={handleEditMouseLeave}
                onClick={(e) => props?.handleEdit(e, props?.galleryID)}
              >
                <img src={isEditHovered ? editHover : editIcon} alt="Edit" />
              </div>
              <div
                onMouseEnter={handleTrashMouseEnter}
                onMouseLeave={handleTrashMouseLeave}
                onClick={(e) => props?.onClickDeleteGallery(e, props?.galleryID)}
              >
                <img src={isTrashHovered ? trashHover : trashIcon} alt="Trash" />
                              </div>
            </div>
          )}

        {!props.hideDetails && <div className="gallery-selection justify-between gap-[0.875rem]">
          <div
            className={`option basis-0 grow shrink ${props?.createdBy!==currentUserId&&"!cursor-not-allowed"}`}
            data-type="2d"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="img py-[0.1875rem] mono">
              <img src={(props?.files?.files&&props?.files?.files[0])??Mono} alt="mono" />
            </div>
            {/* <p>Mono</p> */}
          </div>
          <div
            className={`option basis-0 grow shrink  ${props?.createdBy!==currentUserId&&"!cursor-not-allowed"}`}
            data-type="2ds"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="my-gallery-card-image card-home img  h-full" style={{
                            backgroundImage: `url(${props?.thumbnailBackground ? props?.thumbnailBackground : PreviewDummy})`
                        }}>
              <img src={(props?.files?.sliders&&props?.files?.sliders[0])??Multi} alt="multi" className="object-cover" />
            </div>
            {/* <p>Multi</p> */}
          </div>
          <div
            className={`option basis-0 grow shrink  ${props?.createdBy!==currentUserId&&"!cursor-not-allowed"}`}
            data-type="3d"
            data-id={props?.galleryID}
            onClick={handlePreview}
          >
            <div className="img">
              <img src={props?.metaBgImage} alt="meta" className="object-cover" />
            </div>
            {/* <p>Meta</p> */}
          </div>
        </div>}
        </div>
        {!props?.noOpenBtn &&
        <button data-id={props?.galleryID}  className="absolute bottom-4 open-btn">Open</button>
        }
      </div>
    </>
  );
};

export default GalleryPreviewSelection;