
import "./WalletAuthMesh.scss";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useCallback } from "react";
import useHttp from "App/hooks/use-http";
import WalletList from "./Components/WalletList";
import Panel from "App/Components/Ui/Panel";
import { useStore } from "App/hooks-store/store";
import BanerSm from "App/Layouts/Baner-sm";
import RoundedButton from "App/Components/Ui/RoundedButton";
import useScreenSize from "App/hooks/use-screen-size";
import useWalletConnect from "App/hooks/use-wallet-connect";
import PageLoader from "App/Components/PageLoader";
// import walletGuideImage from "Assets/Images/link-wallet-guide-image.png";
import walletGuideImage from "Assets/Images/wallet-guide-image.png";
import { useNavigate } from "react-router-dom";
import EditIcon from "App/Components/IconsComponents/EditIcon";
import { clearDB } from 'App/hooks-store/store'
import Input from 'App/Components/Ui/Input'
import Label from 'App/Components/Ui/Label'

const WalletAuthMesh = () => {
  const [isEdit, setisEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLinking, setisLinking] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [{ currentWallet ,collectionWalletData, debugWalletAddress}, dispatch] = useStore(false);
  const tabs = ["Add wallet", "Connected"]
  const [tabSelected, setTabSelected] = useState(tabs[0])
  const { triggerAPI } = useHttp();
  const screenSize = useScreenSize()?.width;
  const navigate = useNavigate()
  const { walletAddHandler } = useWalletConnect();
  const changeTabHandler = (e) => {
    setTabSelected(e?.currentTarget?.dataset?.id)
  }
  
  const connectedWalletstResult = useCallback((res) => {
    const val = res?.data;  
    let connectedWallets = [];
    val?.forEach((element, index) => {
      let temp = {
        assetCount: element?.asset_details?.length ?? 0,
        id: element?.id,
        walletName: element?.walletName,
        walletId: index,
        walletAddress: element?.walletAddress,
        blockchainTypeId: element?.blockchainTypeId
      }
      connectedWallets = [...connectedWallets, temp];
    });    
    // console.log('connectedWallets', connectedWallets);
    // dispatch('updateCollectionWalletData', connectedWallets)
    let walletPromise = [];
    val.forEach((wallet) => {
      // return callAPI(wallet.walletAddress, wallet.stakeAddress, wallet.blockchainTypeId);
      walletPromise = [...walletPromise, {...wallet, nftCount: wallet?.asset_details?.length ?? 0}]
    });   
    setWalletList(walletPromise)
    setShowLoader(false)
    // Promise.all(apiPromise).then((res)=>{
    //   const walletPromise = res.map((elem, index) => {
    //     return {...val[index],nftCount: elem?.nftCount ? elem?.nftCount : 0 }
    //   })       
    //   
    //   setShowLoader(false)
    //   setWalletList(walletPromise)
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const getConnectedWallets = useCallback(() => {
    setShowLoader(true)
    triggerAPI({
      url: `user/connected-wallet/get`, method: 'get'
    }, connectedWalletstResult);
  }, [triggerAPI, connectedWalletstResult])

  const updateListHandler = () => { 
    setisEdit(false);
  }
  const applyEditMode = () => {
    setisEdit(true);
  }
  
  const deleteWalletFromStore = useCallback((id) => {
    let tempArray = collectionWalletData ? [...collectionWalletData] : [];
    let updatedWallets = tempArray?.filter(item => item?.id !== Number(id))
    dispatch('updateCollectionWalletData', updatedWallets)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionWalletData])
  const getDeleteResponse = useCallback((res, id) => {
      setShowLoader(false)
      dispatch('setWalletUpdatingStatus', true)
      getConnectedWallets()
      dispatch("reFetch")
      dispatch('showToast', { toast: { toastMode: 'success', message: "Wallet deleted successfully" } })
      deleteWalletFromStore(id);
      dispatch('resetCollections');
      dispatch('setTriggerCollection', true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getConnectedWallets, collectionWalletData])
  const deleteWallet = (id) => {
    dispatch('resetCollections')
    setShowLoader(true)
    triggerAPI({
      url: `user/connected-wallet/remove`, data: { id }, method: 'delete'
    }, (res) => {getDeleteResponse(res, id)}, err => {
      setShowLoader(false)
      dispatch('showToast', { toast: { toastMode: 'error', message: err?.response?.data?.error?.message } })
    });
  }
 
  useEffect(() => {
    getConnectedWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
const editWallet = (data,resCallback) => {
  let payload= {
    walletName: data?.walletName,
    walletAddress: data?.walletAddress,
  }
  setShowLoader(true)
  triggerAPI(
    {
      url: `user/edit/connect-wallet-name`,
      data:payload,
      method: "patch",
    },
    (res) => {
      dispatch("hideSpinner");
      getConnectedWallets()
      resCallback()
      setShowLoader(false)
      let updateWallet=collectionWalletData?.map((el)=>{
        if(el?.id===data?.id){
          return {...el,walletName:data?.walletName}
        }
        return el
      })
      dispatch("updateCollectionWalletData", updateWallet)  
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },(err)=>{
      setShowLoader(false)
      dispatch("hideSpinner");
      dispatch("showToast", {
        toast: { toastMode: "error", message: err?.response?.data?.error?.message },
      });
    }
  );
};
 
useEffect(() => {
  if (currentWallet?.address) {
      const { address, chain } = currentWallet;
      if (isLinking) {
          walletAddHandler(address, chain, getConnectedWallets);
          setisLinking(false);
      }      
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentWallet])

const manageCollectionHandler=()=>{
  navigate("/curate/collections/my-collections")
}
const reinitWalletsHandler= async ()=>{
  await clearDB(true);
  window.location.reload();  
}

const debugWalletChange = (e) => {
  console.log("e",e)
  dispatch("setDebugWalletAddress", e.value === ''?null:e.value)
}
  return (
    <>
      {showLoader && <div className="relative h-full w-full"><PageLoader /></div>}
      {!showLoader && 
        <>
          <BanerSm className="wallet-mob-head">
            {tabs && tabs.map((tabs) => {
              return (
                <div key={tabs} data-id={tabs} onClick={changeTabHandler}
                  className={`font-14 text-[#B8C0CC] cursor-pointer custome-tab mr-3 ${tabSelected === tabs ? 'active' : ''}`}>
                  {tabs}
                </div>
              )
            })}
          </BanerSm>
          {
            screenSize < 551 ? tabSelected === 'Add wallet' &&
              <Panel className="add-wallet-sm">
                <div className="wallet-connectors">
                  <ul className="flex justify-center text-sm p-7 items-center">
                    No wallets found
                  </ul>
                </div>
              </Panel> : <Panel>
                {/* removed for now, needs to support multichain */}
              {/* <div className="wallet-connectors">
                {!installedWallets?.length ? <p className="flex justify-center items-center h-full">Please install any of the Cardano wallets.</p> :
                  <p>Link your wallet</p>}
                <ul className="wallet-connectors-list">
                  {
                    installedWallets.map((wallet) => (
                      <li key={wallet.name}>

                        <div className="wallet-connectors-group">
                          <img src={wallet.icon} alt={wallet.name} />
                          <div>{nameHandler(wallet.name)}</div>
                        </div>
                        <button className="mt-6" data-id={wallet?.installUrl} onClick={linkWalletHandler} value={wallet.name}>
                          {(wallet?.status ? 'Linking' : 'Link')}
                        </button>
                      </li>
                    ))
                  }
                </ul>
              </div> */}
            </Panel>
          }
          {
            screenSize < 551 ? tabSelected === 'Connected' &&
              <Fragment>
                <Panel className="add-wallet-sm">
                  <div className="flex flex-col py-2 items-center justify-between text-left w-full order-1">
                    <div className="flex px-4 justify-between w-full items-center">
                      <div className="justify-center w-full">Linked Wallets</div>
                      <div className="justify-end flex text-end">
                        {!!walletList?.length && ( isEdit ? <RoundedButton Name="Update" Type="9" OnClick={updateListHandler}></RoundedButton> :
                          <RoundedButton Name="Edit" Type="9" OnClick={applyEditMode}></RoundedButton>)}
                      </div>
                    </div>
                    <div className="w-full grid">
                      <WalletList list={walletList} isEdit={isEdit} onDelete={deleteWallet} editWallet={editWallet}/>
                    </div>
                  </div>                  
                </Panel></Fragment> :
              <Fragment>
                <div className="py-2  items-center justify-between text-left w-full order-1 flex">
                  <div className="flex items-center gap-2">
                  <div>Linked Wallets</div>
                  {!!walletList?.length && <div className={`edit-wrapper btn-manage flex items-center justify-center ${isEdit&&"active"}`}>
                      <EditIcon className={`${isEdit&&"activeIcon"}`} onClick={isEdit?updateListHandler:applyEditMode}  />
                    </div>}
                  </div>
                 <div className="flex gap-2">
                 <button onClick={reinitWalletsHandler} className='manage-collection-btn btn-manage'>
                    {' '} Reinitialize Wallets</button>
                 {process.env.REACT_APP_COMPANY !== 'anywall' && <button onClick={manageCollectionHandler} className='manage-collection-btn btn-manage'>
                    {' '} Manage Collection</button>}
                 </div>
                </div>                
                <Panel>
                  <div className="w-full">
                    <WalletList list={walletList} isEdit={isEdit} onDelete={deleteWallet} editWallet={editWallet}/>
                  </div>
                  {process.env.REACT_APP_ENV !== 'production' && <div className={`w-full editform mt-[1rem]`}>
                    <Label className={`text-left  mb-[0.375rem]`}>Debug Wallet Address
                    </Label>
                    <div>
                      <Input placeholder="Debug Wallet" type="text" value={debugWalletAddress} onChange={debugWalletChange}></Input>
                    </div>
                  </div>}
                </Panel>                
              </Fragment>
          }
          <div className="wallet-guide-image order-1 mt-4">
            <img src={walletGuideImage} alt=""/>
          </div>
        </>
      }
      
    </>
  );
}
export default WalletAuthMesh;