import { useStore } from "App/hooks-store/store";
import copyIcon from "Assets/Icons/copy_icon.svg";
import Twitter from "Assets/Icons/social/Twitter.svg";
import { TwitterShareButton } from "react-share";
import "./ShareSocialMedia.scss";
import CopyIcon from "../IconsComponents/CopyIcon";

const ShareSocialMedia = ({ share }) => {
  const dispatch = useStore(false)[1];
  const copyClipHandler = () => {
    let textArea = document.createElement("textarea");
    textArea.value = share;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    dispatch("showToast", {
      toast: { toastMode: "success", message: "Copied to clipboard" },
    });
    document.body.removeChild(textArea);
  };
  return (
    <div className="flex items-center gap-2 share-wrapper">
      <div>
        <div className="copy-box">
          {/* <img
            onClick={copyClipHandler}
            className="cursor-pointer w-4 ml-2.5"
            alt="Img"
            src={copyIcon}
          /> */}
          <CopyIcon className={"cursor-pointer"} onClick={copyClipHandler} />
        </div>
        <p>Copy</p>
      </div>
      <div className="twitter-box">
        <TwitterShareButton url={share}>
          <img src={Twitter} alt="" />
        </TwitterShareButton>
        <p>Twitter</p>
      </div>
    </div>
  );
};
export default ShareSocialMedia;
