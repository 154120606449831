import { useCallback, useEffect, useState } from "react";
import useHttp from "App/hooks/use-http";
import AnnouncementsList from "./Components/AnnouncementLists";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/Ui/Pagination";
import { useParams } from "react-router-dom";
import "./Announcements.scss";
import { capitalizeFirstLetter, oddAndEvenSplitHandler } from "App/Helper/utilities";
import plus from "Assets/Icons/plus.svg"
import CreatePostModal from "./Components/CreatePostModal";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import AddPlusIconButton from "App/Components/AddPlusIconButton";

const SCREENSIZE = window.screen.width;
const paginationConfig = {
  perPage: SCREENSIZE > 550 ? 10 : 50,
};
const Announcements = (props) => {
  const { communityid } = useParams();
  const [announcementList, setAnnouncementList] = useState([]);
  const [announcementListFirstCol, setAnnouncementListFirstCol] = useState([]);
  const [announcementListSecondCol, setAnnouncementListSecondCol] = useState([]);
  const [add, setAdd] = useState(false);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [subjects, setSubjects] = useState([])
  const { triggerAPI } = useHttp();
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useStore(false)[1];

  const onPageChangeHandler = (currentPage) => {
    setPage(currentPage);
  };

  const announcementListApiResult = useCallback(
    (res) => {
      // dispatch("hideSpinner");
      setLoader(false)
      const { list, pageMeta } = res.data;
      const updatedData = list?.map((val) => {
        return {
          ...val,
          createdAt: new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        };
      });
      setTotalPage(pageMeta.totalItems);
      setAnnouncementList(updatedData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getAnnouncementList = useCallback(
    (pageNo) => {
     // dispatch("showSpinner");
     setLoader(true)
      triggerAPI(
        {
          url: "community/announcement/get",
          data: {
            page: pageNo ? pageNo : page,
            items: paginationConfig.perPage,
            filter: {
              communityId: communityid,
            },
            search: "",
          },
          method: "post",
        },
        announcementListApiResult
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, announcementListApiResult, triggerAPI, page, communityid]
  );

  const getPostSubjects = useCallback(() => {
    triggerAPI(
      {
        url: `community/announcements/categories/get`,
        method: "get",
      },
      (res)=>setSubjects(res?.data)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, triggerAPI]);

  useEffect(() => {
    getAnnouncementList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getDetailedCommunity();
    getPostSubjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailedCommunityResult = useCallback(
    (res) => {
      const { data } = res;
      dispatch("setCommunityDetails", data);
    },
    [dispatch]
  );

  const getDetailedCommunity = useCallback(() => {
    triggerAPI(
      {
        url: `community/get/${communityid}`,
        method: "get",
      },
      getDetailedCommunityResult
    );
  }, [getDetailedCommunityResult, triggerAPI, communityid]);


  const newAnnouncementHandler = () => {
    if (add) {
      setSelectedData({});
      getPostSubjects();
    }
    setAdd((pre) => !pre);
    props?.createEveAnnHandler && props?.createEveAnnHandler()
  };

  const saveAnnouncementApiResult = useCallback(
    (res) => {
      dispatch("showToast", {
        toast: {
          toastMode: "success",
          message: capitalizeFirstLetter(res.message),
        },
      });
      dispatch("hideSpinner");
      newAnnouncementHandler();
      getAnnouncementList(1);
      props?.callBackApi && props?.callBackApi()
      setPage(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const saveAnnouncement = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: "community/announcement/create",
          data,
          method: "post",
        },
        saveAnnouncementApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: err?.response?.data?.error?.message||"Postt creation failed",
            },
          });
        }
      );
    },
    [dispatch, saveAnnouncementApiResult, triggerAPI]
  );

  const updateAnnouncementApiResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      newAnnouncementHandler();
      getAnnouncementList();
      props?.callBackApi && props?.callBackApi()
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const updateAnnouncement = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `community/announcement/${selectedData?.id}`,
          data,
          method: "put",
        },
        updateAnnouncementApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Announcement edit failed" },
          });
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, updateAnnouncementApiResult, triggerAPI, selectedData]
  );

  const deleteAnnouncementApiResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      getAnnouncementList();
      props?.callBackApi && props?.callBackApi()
      dispatch("showToast", {
        toast: { toastMode: "success", message: res?.message },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const deletePost = useCallback(
    (data) => {
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `community/announcement/delete/${data?.id}`,
          method: "delete",
        },
        deleteAnnouncementApiResult,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: { toastMode: "error", message: err?.response?.data?.error?.message||"Announcement edit failed" },
          });
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, deleteAnnouncementApiResult, triggerAPI]
  );

  const onSaveHandler = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
      let value = Array.isArray(val) ? JSON.stringify(val) : val;
      formData.append(key, value);
    });
    if (Object.keys(selectedData)?.length) {
      updateAnnouncement(formData);
    } else {
      formData.append("communityId", Number(communityid));
      saveAnnouncement(formData);
    }
  };
  const editHandler = (val) => {
    let ids = [];
    val?.categories?.filter((item) => (item?.isSelected && item?.subcategories?.forEach((sub) => (sub?.isSelected ? ids = [item?.id, sub?.id] : ''))));
    val.selectedCategoryId = ids;
    newAnnouncementHandler();
    setSelectedData(val);
  };

//   useEffect(()=>{
// if(SCREENSIZE<=550){
//   const discoverContent = document.getElementById("discover-content")
//   const discoverOverview = document.getElementById("discover-overview")
//   const discoverTab= document.getElementById("discover-tab")
//   if(add){
//     discoverOverview.style.display="none";
//     discoverTab.style.display="none";
//     discoverContent.style.padding="0rem";
//   }else{
//     discoverOverview.style.display="block";
//     discoverTab.style.display="block";
//     discoverContent.style.padding="1.25rem 1rem 0.5rem 1rem";
//   }
// }
//   },[add,selectedData])
useEffect(() => {
  if (props?.notFull) setLoader(false);
}, [props?.notFull]);

useEffect(()=>{
let data = props?.notFull ? props?.data : announcementList;
const {oddSet,evenSet} = oddAndEvenSplitHandler(data)
  setAnnouncementListFirstCol(oddSet||[]);
  setAnnouncementListSecondCol(evenSet||[]);
},[props?.notFull , props?.data ,announcementList])
  return (
    <div
      className={`list-announcement ${
        add && "mt-4 sm:mt-0"
      } flex flex-col gap-4`}
    >
      <CreatePostModal
        subjects={subjects}
        setSubjects={setSubjects}
        onSaveHandler={onSaveHandler}
        selectedData={selectedData}
        newAnnouncementHandler={newAnnouncementHandler}
        add={add}
      />
      <>
        {!props?.notFull && (
          <AddPlusIconButton onClick={newAnnouncementHandler} className={"ml-auto sm:flex hidden"}/>
        )}
        {props?.postLoader || loader ? (
          <div className={`posts-skeleton-wrap`}>
            <DetailsSkeltonCard
              show={true}
              height={"100%"}
              count={props?.notFull ? 4 : 8}
              inline={true}
              noAbsolute
              details="posts"
              parentDiv={`${props?.from!=="main-page"&&"split-row"}`}
              containerClassName={` flex pt-[1rem]`}
            />
          </div>
        ) : props?.from==="main-page"?
            <AnnouncementsList
              page="creator"
              type={props?.type}
              from={props?.from}
              data={props?.notFull ? props?.data : announcementList}
              editHandler={editHandler}
              deleteHandler={deletePost}
              normalAccordion={true}
            />
        :  !!(announcementListFirstCol?.length || announcementListSecondCol?.length ) ? (
          <div className="split-row">
            <AnnouncementsList
              page="creator"
              type={props?.type}
              from={props?.from}
              data={announcementListFirstCol}
              editHandler={editHandler}
              deleteHandler={deletePost}
              normalAccordion={true}
            />
            {!!announcementListSecondCol?.length &&
              <AnnouncementsList
                page="creator"
                type={props?.type}
                from={props?.from}
                data={announcementListSecondCol}
                editHandler={editHandler}
                deleteHandler={deletePost}
                normalAccordion={true}
            />}
          </div>
        ): 
        <AnnouncementsList
          page="creator"
          type={props?.type}
          from={props?.from}
          onCreatePostsHandler={newAnnouncementHandler}
          data={[]}
          tab={props?.tab}
          editHandler={editHandler}
          deleteHandler={deletePost}
          normalAccordion={true}
      />}
        {!props?.notFull && (
          <AddPlusIconButton  onClick={newAnnouncementHandler} className="sm:hidden flex"/>
        )}
        {!props?.notFull && (
          <div className="purchase-pagination sm:mt-6 mt-0 mb-5 sm:block hidden">
            {!!announcementList?.length && (
              <div className="purchase-pagination sm:mt-6 mt-0 sm:mb-5 mb-[2.75rem] ">
                <Pagination
                  itemsPerPage={paginationConfig.perPage}
                  rows={announcementList?.length}
                  total={totalPage}
                  onPageChange={onPageChangeHandler}
                  selected={page - 1}
                />
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default Announcements;
