import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from "./InformationSlider.module.scss"
import VideoThumbnail from "./VideoThumbail";

const InformationSlider = ({ categoryData, handleSelected, activeSlideId }) => {

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 3,
        initialSlide: 0,
        arrows: true,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    infinite: false,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    infinite: false,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    infinite: false,
                    initialSlide: 0,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    initialSlide: 0,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    initialSlide: 0,
                    dots: false
                }
            }
        ]
    }
    const handleClick = (e) => {

        const contentId = parseInt(e.currentTarget.attributes['data-info'].value);
        // setActiveSlide(contentId);
        const found = categoryData.find(categoryContent => categoryContent.id === contentId);
        if (found) handleSelected(found)
    }


    return (
        <div className="info-slider-nav">
            {categoryData.length && <Slider {...sliderSettings}>
                {
                    categoryData?.map((info) => {
                        let active = (activeSlideId === info.id) ? `${styles['slider-image-cls']} ${styles['slider-image-cls-active']}` : styles['slider-image-cls'];
                        return (
                            <div
                                data-info={info.id}
                                key={info.id} id={info.id}
                                onClick={handleClick}
                                className={`${styles['slider-image-wrp']}`}>
                                <VideoThumbnail video={info.videoUrl} list={info} active={active} />

                            </div>
                        )
                    })
                }
            </Slider>}
        </div>
    )
}

export default InformationSlider