import Card from "App/Components/Ui/Card"
import { useCallback, useEffect, useMemo, useState } from "react"
import GallerySettings from "../GallerySettings"
import "./CreateGallery.scss"
import GalleryPreview from "../GalleryPreview"
import NFTFrameGallery from "../NFTFrameGallery"
import { useStore } from "App/hooks-store/store"
import RoundedButton from "App/Components/Ui/RoundedButton"
import useHttp from "App/hooks/use-http"
import { scrollToTop } from "App/Helper/utilities"
import useAuth from 'App/hooks/use-auth';
import useScreenSize from "App/hooks/use-screen-size"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice"
import MonoSettings from "../MonoSettings"
import MetaSettings from "../MetaSettings"
import GalleryPieces from "../GalleryPieces"
import useGalleryAutoAddFrame from "App/hooks/use-galleryAutoAddFrame"
import { useNavigate } from "react-router-dom"

const CreateGallery = (props) => {
    const screenSize = useScreenSize()?.width;
    const isLoggedIn = useAuth()[0]
    const [sampleName, setSampleName] = useState(`Gallery ${new Date()?.toLocaleString("en-US", { month: "short", day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}`)
    const [colorFetched, setColorFetched] = useState(false);
    const [style, setStyle] = useState("");
    const [color, setColor] = useState("grey");
    const [{currentSelectedNfts},dispatch] = useStore();
    const navigate = useNavigate()
    const { autoAddFramesToAssets } = useGalleryAutoAddFrame();
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)

    const steps = useMemo(() => { 
        return [ {
                    id: 1,  key: 'Pieces', label: 'Pieces'
                }, {
                    id: 2,  key: 'Frames', label: 'Frames'
                }, {
                    id: 3,  key: 'Mono', label: 'Mono'
                }, {
                    id: 4,  key: 'Multi', label: 'Multi'
                }, {
                    id: 5,  key: 'Meta', label: 'Meta'
                }, {
                    id: 6, key: 'Settings', label: 'Settings'
                }
            ] 
    },[]);
    const [activeStep, setActiveStep] = useState(steps[0]);
    useEffect(() => {
        let tempStep = steps[0];
        if(props?.pgaction === 'save')
            tempStep = steps[1];
        setActiveStep(tempStep);
    }, [props?.pgaction, steps])
    const { triggerAPI } = useHttp();
    const [list, setList] = useState([])
    const autoFrame = useCallback(() => {
        autoAddFramesToAssets({assetList: currentSelectedNfts}, (autoAddedFrameAssetList) => {
            dispatch('setCurrentSelectedNfts', autoAddedFrameAssetList)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedNfts]);
    console.log('props?.pageType', props?.pageType);
    const handleNext = () => {
        const index = steps.findIndex(x => x.key === activeStep.key);
        let proceedNext = true;
        if(activeStep.key === 'Pieces') {
            if (currentSelectedNfts.length) {
                if(currentSelectedNfts.length <= 30) {
                    autoFrame();
                    navigate(`${props?.galleryBaseUrl}/${props?.pageType}/save`)
                } else {
                    dispatch("showToast", { toast: { toastMode: "warning", message: 'Select up to 30 pieces.' } });
                    proceedNext = false;
                }                
            } else {
                proceedNext = false;
                dispatch('showToast', { toast: { toastMode: 'error', message: 'Please Select atleast one Asset' } })
            }                
        }
        if(proceedNext)
            setActiveStep(steps[index + 1]);
    }
    const handleBack = () => {
        const index = steps.findIndex(x => x.key === activeStep.key);
        if (index === 0) return;
        if(index === 1)
            navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`)
        setActiveStep(steps[index - 1]);
    }
    const getProfileResult = useCallback((res) => {
        setList(res.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getProfile = () => {
        triggerAPI({
            url: `user/profile/get`, method: 'get'
        }, getProfileResult);
    }

    useEffect(() => {
        if(isLoggedIn) {
            getProfile()
        }
        if (screenSize < 551) {
            setTimeout(() => {
                scrollToTop();
            }, 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        
        dispatchRedux(setCurrentFormValues({...currentForm, 
            twitter: currentForm?.twitter ? currentForm?.twitter : list?.twitter ? list?.twitter : undefined,
            website: currentForm?.website ? currentForm?.website : list?.website ? list?.website : undefined,
            youtube: currentForm?.youtube ? currentForm?.youtube : list?.youtube ? list?.youtube : undefined,
            aboutMe: currentForm?.aboutMe ? currentForm?.aboutMe : list?.description ? list?.description : undefined,
            discord: currentForm?.discord ? currentForm?.discord : list?.discord ? list?.discord : undefined
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list])

    const onTabClick=(data)=>{
        
        if(activeStep.id+1===data?.id||activeStep.id>data?.id) {
            if(data?.key === 'Pieces') {
                navigate(`${props?.galleryBaseUrl}/${props?.pageType}/collection`)
            } 
            if(activeStep.key === 'Pieces') {
                if (currentSelectedNfts.length) {
                    if(currentSelectedNfts.length <= 30) {
                        autoFrame();
                        navigate(`${props?.galleryBaseUrl}/${props?.pageType}/save`)
                    } else {
                        dispatch("showToast", { toast: { toastMode: "warning", message: 'Select up to 30 pieces.' } });
                        return ;
                    } 
                } else {
                    dispatch('showToast', { toast: { toastMode: 'error', message: 'Please Select atleast one Asset' } })
                    return ;
                }                
            }
            setActiveStep(data);
        }
    }

    const cursorHandler = (step) =>{
        return ((activeStep?.id+1 === step.id)||activeStep.id>step?.id||activeStep.id===step?.id)?"cursor-pointer":"cursor-not-allowed"
    }
    const navigateToHome = () => {
        props?.onToGallery();
    }
    return (
        <div className="w-full curate-form-sm">
            <Card className={` card-gallery`}>
                <div className="wizard flex">
                    <ul>
                        {steps.map((step, i) => {
                            return <li key={i} className={`${activeStep.key === step.key ? 'active-wizard' : ''}`}>
                                <div className={`gallery-create text-[0.75rem] ${cursorHandler(step)}`} onClick={()=>onTabClick(step)}><span>{step.label}</span></div>
                            </li>
                        })}
                    </ul>
                </div>
                <div className={` ${activeStep?.key === 'Settings' && screenSize < 551 ? 'settings-gal' : 'create-gallery'}  side-scroller  create-gallery-continer-sm sm:pt-3 pt-0 mt-0 step-component flex flex-col h-full gap-[1.25rem]`}>
                    {activeStep?.key === 'Pieces' && 
                        <GalleryPieces action={props?.action}
                        />
                    }
                    {activeStep?.key === 'Frames' && 
                        <NFTFrameGallery
                            onChangeFrame={props?.onChangeFrame}
                            frameAssetNew={props?.frameAsset}
                            selectedNfts={props?.selectedNfts}
                            onSmFramClick={props?.onSmFramClick}
                            setFrameAsset={props?.setFrameAsset}
                            onScreenCaptureNfts={props?.onScreenCaptureNfts}

                        />
                    }
                    {activeStep?.key === 'Mono' && 
                        <MonoSettings
                            frameAssetNew={props?.frameAsset}
                        />
                    }
                    {activeStep?.key === 'Multi' &&
                        <GalleryPreview colorFetched={colorFetched} 
                            setColorFetched={setColorFetched} 
                            style={style} setStyle={setStyle}
                            color={color} setColor={setColor}
                            selectedWall={props?.selectedWall} 
                            OnSelectWall={props?.OnSelectWall} 
                            selectedNfts={props?.selectedNfts} 
                        />
                    }
                    {activeStep?.key === 'Meta' &&
                        <MetaSettings frameAssetNew={props?.frameAsset}
                        />
                    }
                    {activeStep?.key === 'Settings' &&
                        <GallerySettings
                            setFormVaildState={props?.setFormVaildState}
                            setSampleName={setSampleName}
                            sampleName={sampleName}
                            showPrimaryDiscoverable={props?.showPrimaryDiscoverable}
                            isPremiumSubscription={props?.isPremiumSubscription}
                        />
                    }
                </div>

                <div className="flex items-center justify-between gap-3 px-3 pr-[1.125rem] pt-2 pb-3 create-gallery-btn">
                {activeStep.key === 'Pieces' && 
                    <RoundedButton Name="Cancel" Type="15" value="Back"  OnClick={navigateToHome}></RoundedButton>     }
                    {activeStep.key !== 'Pieces' &&

                        <RoundedButton Name="Previous" Type="15" value="Back" disabled={steps[0].key === activeStep.key} OnClick={handleBack}></RoundedButton>}

                    <RoundedButton Name={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Complete'} Type="14" value={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} OnClick={steps[steps.length - 1].key === activeStep.key ? props?.onClickAdd : () => handleNext()}></RoundedButton>

                </div>

            </Card>
        </div>
    )
}

export default CreateGallery