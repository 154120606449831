import { useEffect, useState,forwardRef } from "react"
import './input.scss';
import regex from "App/Configs/regex";
const Input = forwardRef((props,ref) => {
    const [values, setValues] = useState('')
    const { value = '', sampleName = '' } = props
    const classes = props.className + ' form-input w-full'

    const onBlurHandler = (e) => {
        if (props.validateOnBlur) {
            e.target.value = e.target.value ? e.target.value : props?.sampleName ?? '';
            onChangeHandler(e);
        }

    }
    let onChangeFn=(data)=>{
        const label = props.controlName ?? 'value'
        const value = {
            [label]: data
        }
        if (props.onChange) {
            props.onChange(value)
            setValues(data)
        }
    }
    const onChangeHandler = (e) => {
        let val=e.target.value?.trimStart()
        if(props?.regex){
          if(regex[props?.regex].test(val)){
            onChangeFn(val)
          }
        }else{
            onChangeFn(val)   
        }
     
    }
    const onFocusHandler = (e) => {
        if (props?.sampleName === values) {
            setValues('')
        }
    }
    useEffect(() => {
        setValues(value || value===0 ? value : sampleName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sampleName, value])

    return <div className={`form w-full input-field ${props.icon && 'input-icon'}`}>
        {props.icon && <img src={props.icon} alt='icon' />}
        <input ref={ref}
            className={`${classes} `}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            readOnly={props.readonly}
            type={props.type}
            data-a={props.dataA}
            data-b={props.dataB}
            onChange={onChangeHandler}
            maxLength={props?.maxlength}
            value={values}
            disabled={props?.disabled}
            placeholder={props.placeholder}></input>
        <div className="error-box mt-2">
            {props.children}
        </div>
    </div>

})

export default Input