import { useEffect, useState } from "react";
import"./ImagesTab.scss"
import { useStore } from "App/hooks-store/store";
import { MediaType, getMediaImage } from "App/Helper/utilities";

const ImagesTab = (props) => {
  const [filesToView, setFilesToView] = useState([])  
  const dispatch = useStore(false)[1];
  const [toolTipId, setToolTipId] = useState('');
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  
  useEffect(()=> {
    setFilesToView(props?.nft?.files?.filter(f=>(f.mediaType??"")?.indexOf("audio") === -1) ?? []);
  }, [props?.nft?.files])
  
  const setAsDefaultImage = (image) => {  
    if(props?.onCaptureImageIndex){
      props.onCaptureImageIndex(image);
    }
    if (!props?.isUploadList)    
    {
      let file = filesToView[image];    
      if (file?.mediaType?.indexOf('audio') > -1)
      {                 
        let newPlaylist = [{ ...props.nft, files: [file] }];
        dispatch('setMusicPlaying', false);
        dispatch('setMusicPlayerVisible', true);
        dispatch('setMusicPlaylist', newPlaylist);         
      }
      else
      { 
        props.setPreviewUrl(file?.nftcdnfile ?? props?.nft?.nftcdnimage1024);    
      }    
    }    
  }
  
  const handleMouseHover = (e, id) => {
    // if(id === toolTipId){
    //   return;
    // }
    const x = e?.clientX;
    setCoords({ x });
    setToolTipId(id);
  }


  const imgFunction = (item, index,showFileType) => {
    let alternativeFileType =MediaType(item.mediaType);
    return  <div onMouseLeave={()=>{
    setToolTipId(''); 
    setCoords({ x:0 });}} onMouseOver={(e)=>handleMouseHover(e, index)}  className={`${props?.previewUrl === item.nftcdnfile ? 'highlight' : ''} aspect-square object-contain square-container cursor-pointer image-tab-item ${showFileType&&"file-type-box"}`} key={index} onClick={() => setAsDefaultImage(index)}>      
            {(props?.isUploadList && item.type?.indexOf("image")>-1) ?        
              <img src={URL.createObjectURL(item)} alt="data"></img> 
            :
              <div className='data-type'>
              {!!item?.name && <div className={`${index === toolTipId ? 'tooltip-box' : 'hidden'}`} style={{left: coords?.x}}>{item?.name}</div>}
                  <div className='media-file-type'>
                      <img src={getMediaImage(item?.mType || alternativeFileType)} alt=""/>
                  </div>
              </div>
            }
            </div>
  }
  return (
    <div className={`images-tab-container ${props?.className}`}>      
      {filesToView?.map((item, index) => (                
        imgFunction(item, index,props?.showFileType)        
      ))}
      {!filesToView?.length && imgFunction({ nftcdnfile: props?.previewUrl, mType: 'image' }, 0, true)}
      {/* {filesToView?.length <= 0 &&!props?.nft?.image? <p className="no-data">No Images</p> : <></>} */}
    </div>
  );
};
export default ImagesTab;
