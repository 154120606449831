import React, { useEffect, useState } from 'react'
import DetailsTab from '../DetailsTab'
import useHttp from 'App/hooks/use-http';
import SkeletonLoader from 'App/Components/Ui/SkeletonLoader';

const CollectionDetailsTab = (props) => {
    const { triggerAPI } = useHttp();

    const [communityUserDetails, setCommunityUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    const getCollectionDetails = (collectionId) => {
        const params = new URLSearchParams({
            collectionId, otherCommunityCollections: true
        });
        triggerAPI({
            url: `community/collection/get-one?${params}`,
            method: "get",
        }, (res) => {
            // const { communityId, otherCommunityCollections } = res?.data;
            setCommunityUserDetails(res?.data ?? {});
            setLoading(false)
        }, (err) => { setLoading(false) }
        );
    };
    useEffect(() => {
        if (props?.collectionDetails?.policy) {
            getCollectionDetails(props?.collectionDetails?.policy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.collectionDetails]);

    return (
        <div className={`w-full h-[100%] overflow-auto`}>
            {loading ? <div className={`w-full`}>
                <div className="w-full flex flex-col">
                    <SkeletonLoader className={`w-[10%] h-3 mr-[90%] rounded-lg mb-4`} count={1} />

                    <SkeletonLoader className={`w-[70%] mr-[30%] h-3 rounded-lg`} count={1} />
                    <SkeletonLoader className={`w-[99%] mr-[1%] h-3 rounded-lg`} count={3} />
                    <SkeletonLoader className={`w-[90%] mr-[10%] h-3 rounded-lg`} count={1} />
                    <SkeletonLoader className={`w-[80%] mr-[20%] h-3 rounded-lg`} count={1} />
                </div>

            </div>
                : <DetailsTab
                    communityUserDetails={communityUserDetails}
                    collection={props?.collectionDetails}
                    details={props.collectionDetails}
                    array={[]}
                    className={`overflow-hidden !pl-0 !pt-0 pr-2.5`}
                />}
        </div>
    )
}

export default CollectionDetailsTab