import React, { Fragment, useLayoutEffect, useRef } from "react";
import "./GalleryFrameImages.scss"
import { useStore } from "App/hooks-store/store";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { getBlobFromUrl, getBLOBUrl, getFrameURL, getFrameURLFromCDN, getURL } from "App/Helper/utilities";
import html2canvas from "html2canvas";
import useFrames from "App/hooks/use-frames";
import useScreenSize from "App/hooks/use-screen-size";
import NftFrame from "App/Components/NftFrame"
import { getMetadata } from "App/Helper/utilities";
import NftZoomed from "App/Components/NftZoomed";


const GalleryImages = React.memo(({ onScreenCaptureNfts, isDetailedView = false, clearCache = false,
    fileName, frameName, nftFrames = false, noFrame = false, frameClass = '',
    onNFTLoad, onFrameLoaded, addedNfts = false, nftGalleryNew = false, galleryPreview = false,
    galleryNew = '', className, src, policy, onClick, dataId, gallery,displaySize,
    action, actionImage = false, showZoom, frameToggle = true, setShowName, compress = false, setLoaderPreview, framePreview, 
    hoverlayClass ='', displayChange, imageRatioCalculated, showBorder, files = null, onChainRenders=false, clicked=true, imageFileToUse=null, onChainReRender=0, playAudio=false }) => {
    const [frameDetails, setFrameDetails] = useState('')
    const { getFrameFromStore } = useFrames();
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [images, setImages] = useState('')
    const [spinnerShowImage, setSpinnerShowImage] = useState(true)
    const exportRef = useRef();
    const timer = useRef(null);
    const [{ currentFreeFrames, leftMenuCollapse }, dispatch] = useStore(false);
    const screenSize = useScreenSize()?.width;    
    const [imageFile, setImageFile] = useState(null);
    const getFrameResult = useCallback((res) => {
        const setFrameData = (data) => {
            let isLandscape = galleryNew?.isLandscape ?? data?.isLandscape;
            let temp = {
                name: data.name,
                image: displaySize==="sm" ? isLandscape ? (data.s3ImageUrlLandscape256 ?? data.s3ImageUrl256) : data.s3ImageUrl256 : isLandscape ? (data.s3ImageUrlLandscape512 ?? data.s3ImageUrl512) : data.s3ImageUrl512 ,
                metaInfo: data?.metaInfo,
                frameId: data.id,
                isLandscape: isLandscape ?? false
            }
            temp.metaInfo = temp.isLandscape ? data?.metaInfo?.landscape :  data?.metaInfo?.portrait;
            setFrameDetails({ ...temp })
        }
        const data = res
        if (clearCache) {
            setSpinnerShow(true)
            const frameURL = getFrameURL(data.s3ImageUrl512)
            getBlobFromUrl(frameURL, (url) => {
                setFrameData({ ...data, s3ImageUrl: url })

            })
        }
        else {
            let frmInjData = {}
            if (isDetailedView) {
                frmInjData = {
                    s3ImageUrl: getFrameURLFromCDN(data.s3ImageUrl512)
                }
            }
            setFrameData({ ...data, ...frmInjData })
        }
        setSpinnerShow(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,displaySize])

    const getFrameById = (id) => {
        setSpinnerShow(true)
        getFrameFromStore(id, (res) => {
            getFrameResult(res)
        })
    }

    useEffect(() => {
        if (galleryNew.frameId && !noFrame) {
            const array = [...currentFreeFrames ?? []]
            const foundFrame = array.find((x) => x.id === galleryNew.frameId)
            if (foundFrame) {
                getFrameResult(foundFrame)
            }
            else {
                getFrameById(galleryNew.frameId)

            }
        }
        else {
            setFrameDetails(null)
        }
        if (imageFileToUse)
        {
            setImageFile([imageFileToUse]);
        }
        else if (onChainRenders)
        {
            setImageFile(galleryNew?.details?getMetadata(galleryNew?.details)?.onchain_metadata?.files:(galleryNew?.files));
        }
        else
        {
            setImageFile(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryNew,onChainRenders,imageFileToUse,displaySize])

    const screenshotImage = useCallback((timer) => {
        const htmlCanvas = () => {
            //const input = exportRef.current; 
            const input  = document.getElementById(`id-${fileName}`)
            try {
                html2canvas(input, {
                    logging: true,
                    allowTaint: false,
                    useCORS: true,
                    backgroundColor: null,
                    scale: 4
                }).then((canvas) => {
                    canvas.toBlob(async blob => {
                        const obj = {
                            [fileName]: { blob, url: getBLOBUrl(blob) },
                        }
                        addedNfts && emitScreenshotNft(obj)
                    });
                }).catch(error => {
                    // console.error('error on taking screen shot:', error);
                })
            } catch (error) {
                // console.error('error on taking screen shot:', error);
            }
            
        }
        setTimeout(() => {
            htmlCanvas();
        }, timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const emitScreenshotNft = (obj) => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
        timer.current = setTimeout(() => {
            addedNfts && onScreenCaptureNfts && onScreenCaptureNfts(obj);
        }, 1000);
    }

    const onNftLoaded = useCallback((e) => {
        if (imageRatioCalculated && e?.target)
        {
            let ratio = e.target.naturalWidth/e.target.naturalHeight
            imageRatioCalculated(ratio);
        }
        setSpinnerShowImage(false)
        if (setShowName) {
            setShowName(false)
        }
        onNFTLoad && onNFTLoad(e)
        if (addedNfts) {
            screenshotImage(5000)
        }
        detailsSpinner(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const setImageLoaded = useCallback(() => {
        onNftLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onFrameLoad = useCallback((e) => {
        setLoaderPreview && setLoaderPreview(false)
        onFrameLoaded && onFrameLoaded(e)
        setSpinnerShow(false)
        if (addedNfts) {
            screenshotImage(5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const detailsSpinner = (mode) => {
        if(isDetailedView){
            setSpinnerShowImage(mode)
        }
    }
    useEffect(() => {
        if (src) {
            const formattedURL = isDetailedView ? src : getFrameURL(getURL(src));
            if (clearCache) {
                getBlobFromUrl(formattedURL, (url) => {
                    setImages(url)
                })
            }
            else {
                setImages(formattedURL)               
            }
            detailsSpinner(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src])
    const updateHeight = () => {
        if(gallery && exportRef.current) {
            const root = document.documentElement;
            root?.style.setProperty(
                "--collection-card-height", `${exportRef.current.offsetWidth}px`
            );
        }
    }
    useLayoutEffect(() => {
            updateHeight()
            window.addEventListener("resize", () => {
                setTimeout(() => {
                    updateHeight()            
                }, 1000);
            }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[gallery]);
    useEffect(() => {
        if(displayChange)
            updateHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[displayChange]);
    
    useEffect(() => {
        setTimeout(() => {
            updateHeight()            
        }, 1000);
        updateHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[leftMenuCollapse]);

const nftFrameHandler2 = (frameInfo) =>{    
    return (<NftFrame
                skeltonClass='flex h-full w-full'                      
                isDetailView={isDetailedView}
                frameDetails={frameInfo}      
                nft={images}
                onFrameLoaded={onFrameLoad}    
                setImageLoaded={setImageLoaded}     
                imageRatioCalculated={imageRatioCalculated}
                imageFile={imageFile} 
                onClick={onClick}
                onChainReRender={onChainReRender}
                playAudio={playAudio}
            />)
}

const nftFrameHandler = (frameInfo) =>{
    return ( <NftZoomed
        nft={images}
         />)
}

    return (
        <div ref={exportRef} id={`id-${fileName}`} key={`id-${fileName}`} className={`${showBorder ? 'border border-[1px} border-[#20232A] rounded-[.28rem] aspect-square object-contain' : 'w-full h-full'} w-full   relative  flex items-center justify-center card-layout-container`}>
            {!frameDetails && !spinnerShow ?
                <Fragment>
                    {showZoom && screenSize <= 550 ? (
                        <Fragment>
                            < div className={`${actionImage ? 'flex' : ''} ${nftGalleryNew ? '' : 'h-full'} gallery-images-position `}>
                                <div className={`${className} gallery-images `} data-policy={policy} onClick={onClick}>                                    
                                    {nftFrameHandler(frameDetails)}
                                </div>
                            </div>
                            {!spinnerShowImage && actionImage && actionImage !== 'false' && <div className="flex close cursor-pointer selected-gallery-zoom" >
                                <img alt="NoImg" className="gallery-close " data-id={dataId} onClick={onClick} data-action={action} src={actionImage} />
                            </div>}
                        </Fragment>

                    ) :
                        <Fragment>
                            < div className={`${actionImage ? 'flex' : ''} ${className} gallery-images-position h-[100%]`}>
                                <div className={`${className} gallery-images `} data-policy={policy} onClick={
                                    onClick}>
                                    {hoverlayClass && <div className={hoverlayClass}></div>}                                    
                                   {clicked  ? nftFrameHandler2(framePreview ? frameName : frameDetails) : nftFrameHandler()}
                                </div>
                                {/* {isDetailedView && <SkeltonCard show={imagesLoaded.image} noAbsolute={true} height="100%" inline={true}
                            containerClassName="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex frame-spinner justify-center items-center z-9" /> } */}
                            </div>

                            {
                                !spinnerShowImage && actionImage && actionImage !== 'false' && <div className={`close ${actionImage && actionImage !== 'false' ? 'flex' : 'hiddens'}  cursor-pointer`}  >
                                    <img alt="NoImg" className="gallery-close" data-id={dataId} onClick={onClick} data-action={action} src={actionImage} />
                                </div>
                            }

                        </Fragment>}
                </Fragment>
                : <div className={`${spinnerShow ? 'frame-loading' : 'w-full h-full flex justify-center items-center nft-frame-background '} ${frameClass? 'gallery-preview-popup':''}`} >
                    
                    
                    {frameToggle ?
                    <> 
                        {nftFrameHandler2(framePreview ? frameName : frameDetails)}
                    </> :
                              <>                       
                            {nftFrameHandler2(null)}  {/*   in gallery details page , frame is not removing when we click on remove frame icon if the framedetails has value               */}
                         </> 
                    }
                    {!spinnerShow && actionImage && actionImage !== 'false' && <div className={`close ${actionImage && actionImage !== 'false' ? 'flex' : 'hiddens'}  cursor-pointer`} >
                        <img alt="NoImg" className="gallery-close" data-id={dataId} onClick={onClick} data-action={action} src={actionImage} />
                    </div>}
                </div>
            }
        </div >


    )

})

export default GalleryImages