import './InputColor.scss';
import { useEffect, useRef, useState } from "react";
const InputColor = ({className = '', onChange, value}) => {
    const [colorValue, setColorValue] = useState('');
    const inputRef = useRef(null)
    useEffect(() => {
        setColorValue(value);
    }, [value])
    const handleClick = () => {
        inputRef?.current?.click();
    }
    const colorChange = (e) => {
        let value = e?.target?.value;
        setColorValue(value);
        onChange(value);
    }
    return (
        <div className={`input-color cursor-pointer relative ${className}`} style={{background: colorValue}} onClick={handleClick}>
            <input type="color" className="opacity-0"  ref={inputRef} onChange={colorChange}
                value={colorValue}
            />
        </div> 
    )
}
export default InputColor;