import storage from "App/Helper/storage";
import { deepCopy, getMetadata } from "App/Helper/utilities";
import useHttp from 'App/hooks/use-http';
import useWalletConnect from "App/hooks/use-wallet-connect";

const { useStore } = require("App/hooks-store/store");

const useCollectionLoader = () => {
    const [store, dispatch] = useStore();    
    const { collections, tokens, overAllMarketplaceAssets, currentWallet } = store
    let { triggerAPI } = useHttp();
    const { walletAddHandler } = useWalletConnect();
        
    const triggerFetch = async (assetsList, collectionsetfunction, loadingstatusfunction, type) => { 
        let assetList = assetsList ? [...assetsList] : [];
        let assetids = assetList;
        let functionToExclude = ['setCurrentGalleryAssets', 'setSingleCollection']
        if (!functionToExclude?.includes(collectionsetfunction)) { 
            let collectionData = collections ? [...collections] : [];
            if(type === 'token')
                collectionData = tokens ? [...tokens] : [];
            if(collectionsetfunction === 'setOverAllMarketplaceAssets')
                collectionData = overAllMarketplaceAssets ? [...overAllMarketplaceAssets] : []
            //to eliminate existing assetid
            // eslint-disable-next-line array-callback-return
            const eliminated = [...assetList]?.filter(item => {
                let temp = collectionData?.find(aData => aData.asset === item.asset)
                if(!temp)
                    return item;
            })   
            assetids = eliminated;
        }
        // eslint-disable-next-line no-unused-vars
        const newAssetList = assetids?.map(item => { return {
            asset_name: item?.asset_name?.toLowerCase(), 
            policy_id: item?.policy_id?.toLowerCase(), 
            contractAddress: item?.contractAddress, 
            chain: item?.chain,
        }}) // for new API
        if (newAssetList?.length> 0)
        {
            try {
                await getImageDetailsChunked({ newAssetList, collectionsetfunction, loadingstatusfunction, assetList, type });    
            } catch (error) {
                console.log("Error in getting collection chunks")
                console.log(error)
            }
        }
        console.log('collectionsetfunction', collectionsetfunction, loadingstatusfunction);
        dispatch(loadingstatusfunction, 'finished') 
        if (type === 'connectedWallet' && (collections?.length ?? 0) === 0)
        {            
            walletAddHandler(currentWallet.address, currentWallet.chain, () => {
                dispatch('setTriggerCollection', true);
            });  
        } 
    }    

    const sliceCollectionAPI = (assetsList, collectionsetfunction="setCollections", loadingstatusfunction="updateCollectionLoadingStatus", type = '') => { 
            //dispatch(loadingstatusfunction,"in-progress");
        triggerFetch(assetsList, collectionsetfunction, loadingstatusfunction, type)   
    }

    const getImageDetailsChunked = async ({newAssetList, collectionsetfunction, loadingstatusfunction, assetList = [], type = ''}) => {                        
        const data = {            
            assetids: newAssetList
        }

        if (newAssetList?.length > 0)
        {
            const token = storage().get('token')
            let headers = {}
            if(token)
            headers = {
                Authorization: `Bearer ${token}`,
                isb2c: true
            }
    
            headers['Content-Type'] = 'application/json';
    
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            };
            
            if (type === 'connectedWallet')
            {
                dispatch("updateCollectionLoadTotal", newAssetList?.length);
                dispatch("updateCollectionLoadProgress", 0)    
            }
            

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/nft-collection/asset/detail?networkType=MAINNET&blockchainTypeId=1`, requestOptions);    
            
            const reader = response.body.getReader();

            dispatch(loadingstatusfunction, 'in-progress')

            let onlycommas = new RegExp(
                '^[,]+$'
            );
            const decoder = new TextDecoder();
            let chunks = [];
            let collectionretreived = [];
            let lastupdate = new Date().getTime();
            let progress = 0;
            while (true) {                
                const {done, value} = await reader.read();                                    
                if (value)
                {                                       
                    let str = decoder.decode(value);                    
                    try {                           
                        if (str !== "" && !onlycommas.test(str))
                        {                               
                            chunks.push(str);

                            //test for valid collection
                            try {
                                str = chunks.join('');
                                if (str.startsWith(","))
                                {
                                    str=str.substring(1);
                                }
                                
                                if (!str.startsWith("["))
                                {
                                    str = '[' + str;    
                                }
                                if (!str.endsWith("]"))
                                {
                                    str = str + ']';                        
                                }
                                
                                let col = JSON.parse(str);
                                
                                let colmeta = col.map(x => {
                                    if(assetList?.length) {
                                        //reassign walletid based on the client wallet list, with new ids
                                        x.walletId = assetList?.find(assetData=> x.asset === `${assetData.chain==='cardano'?'':assetData.chain}${assetData.policy_id}${assetData.asset_name}`)?.walletId;
                                    }
                                    return getMetadata(x, 'isFavouriteNFTCollection')
                                })
                                chunks = [];
                                collectionretreived = collectionretreived.concat(colmeta);
                                //append items into collection
                                //dispatch("setCollections", { loading: false, data: colmeta, count: 0 })                                  
                            } catch (error) {
                                if (!error.message.startsWith("Unterminated string in JSON") 
                                    && !error.message.startsWith("Unexpected token")
                                    && !error.message.startsWith("Expected ',' or '}'"))
                                {
                                    console.error(error);
                                }                                
                                //wasn't valid for parsing, so let the next loop add another chunk and retry
                                //console.log("Not valid for parsing yet...")
                            }
                            

                                                                                    
                        }
                        else
                        {
                            console.log("empty str");
                        }
                    } catch (error) {
                        console.log("ERROR parsing json" + error)
                        console.log(str)
                    } 
                    
                }                
                
                //code to only update the UI every 500ms, as otherwise the max update depth is exceeded
                if (lastupdate < new Date().getTime()-500)
                {
                    lastupdate = new Date().getTime();
                    dispatch(collectionsetfunction, { loading: true, data: collectionretreived, count: 0 }) 
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    if (type === 'connectedWallet')
                    {
                        progress += collectionretreived?.length;
                        dispatch("updateCollectionLoadProgress", progress)                    
                    }
                    collectionretreived = [];
                }
                if (done) {
                    //append items into collection
                    dispatch(collectionsetfunction, { loading: false, data: collectionretreived, count: 0 })
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    if (type === 'connectedWallet')
                    {
                        progress += collectionretreived?.length;
                        dispatch("updateCollectionLoadProgress", progress)
                    }
                    break;                
                }                
            }
            if (type === 'connectedWallet')
            {
                dispatch("updateCollectionLoadTotal", 0);
                dispatch("updateCollectionLoadProgress", 0);    
            }
            dispatch(loadingstatusfunction, 'finished')                   
        }
        else 
        {            
            console.log("ERROR - convert call to getImageDetails to use assetids")
        }
    }
    

    const sliceArtBankAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setArtBank", "updateArtBankLoadingStatus");        
    }

    const sliceSingleCollectionAPI = (assetList, type) => {
        sliceCollectionAPI(assetList, "setSingleCollection", "updateSingleCollectionLoadingStatus", type);    
    }

    const sliceTokenAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setTokenCollection", "updateTokenLoadingStatus", 'token');        
    }

    const refreshWallets = () => {        
        dispatch("updateCollectionLoadingStatus","in-progress")
        triggerAPI({
            url: 'user/wallets/assets/get', method: 'get'
        }, async (res) => {            
            let wallets = Object.keys(res?.data?.wallets);
            let deletedCount = 0;
            let addedCount = 0;
            let totalCount = collections?.length;         
            for (let i = 0; i < wallets?.length; i++) {
                const wallet = res.data.wallets[wallets[i]];  
                let chain = wallet.blockchainType?.code;
                let assets = wallet.asset_list.map(a=> { return {
                    asset_name: a.asset_name?.toLowerCase(),
                    policy_id: a.policy_id?.toLowerCase(),
                    chain: chain,
                    walletId: wallet.walletId
                }});                
                let existingAssets = collections.filter(a=>
                    a.walletId === wallet?.walletId && chain === a.blockchainType.code
                );
                let newAssets = assets.filter(na=>{
                    let index = existingAssets.findIndex(ea=>
                        ea.asset === `${na.chain==='cardano'?'':na.chain}${na.policy_id}${na.asset_name}`)                        

                    return index===-1;
                });

                let deletedAssets = existingAssets.filter(ea=>{
                    let index = assets.findIndex(na=>
                        ea.asset === `${na.chain==='cardano'?'':na.chain}${na.policy_id}${na.asset_name}`)                        

                    return index===-1;
                });
                                
                if (deletedAssets?.length > 0)
                {
                    deletedCount += deletedAssets.length;
                    dispatch("deleteCollections", deletedAssets);                    
                }                
                if (newAssets?.length > 0)
                {                    
                    await triggerFetch(newAssets, "setCollections", "updateCollectionLoadingStatus", '');                                 
                    addedCount += newAssets?.length;                    
                }                
            }
            dispatch("updateCollectionLoadingStatus","finished");
            let message = `Collections refresh complete.\r\n${totalCount} pieces checked.`;
            if (addedCount===0 && deletedCount===0)
            {
                message += "\r\nNo changes found.";   
            }
            else
            {
                if (addedCount===1)
                {
                    message += "\r\n1 new piece added.";   
                }
                else if (addedCount > 1)
                {
                    message += `\r\n${addedCount} new pieces added.`;
                }
                if (deletedCount===1)
                {
                    message += "\r\n1 piece removed.";   
                }
                else if (deletedCount > 1)
                {
                    message += `\r\n${deletedCount} pieces removed.`;   
                }
            }
            dispatch('showToast', { toast: { toastMode: 'success', message } })
        }, (er) => {
            console.log("ERROR ",er)            
        })
    }
    
    return {          
        sliceCollectionAPI,
        sliceArtBankAPI,
        sliceSingleCollectionAPI,        
        sliceTokenAPI,
        refreshWallets
    }
}
export default useCollectionLoader;