import React, { useEffect, useState } from 'react';
import userGroupIcon from "Assets/Icons/TopMenu/user-group-icon.svg";
import './userGroupDropdown.scss';
import { useLocation } from 'react-router-dom';
import UserGroupIcon from 'App/Components/IconsComponents/UserGroupIcon';

function UserGroupDropdown(props) {
    const [loading, setLoading] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const location = useLocation();

    const handleSetValue = (id) => {
        const option = props?.options?.find((option) => id === option?.id);
        if (option?.value === selectedOption?.value) {
            return;
        }
        setSelectedOption(option);
        props.onChange(option);
    };

    useEffect(() => {
        setLoading(true)
        if (props?.options?.length) {
            setSelectedOption(props?.options[0]);
            props.onChange(props?.options[0]);
        }
        const timeout = setTimeout(() => setLoading(false), 1500)
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname]);
    const ImageIcon = props?.imageIcon;
    return (
        <div className={`user-group-container ${loading && 'cursor-not-allowed'}`}>
            <div className="cursor-pointer filter-icon-wrapper" >
               {props?.imageIcon? props?.icon? <img
                    src={props?.icon}
                    alt="icon"
                    className={`icon`}
                /> :
                <UserGroupIcon /> :
                <ImageIcon/>}
            </div>

            {!loading && <div className="user-group-dropdown top-10 -left-4">
                <ul>
                    {props?.options?.length && props?.options?.map((option) => <li key={option?.id} onClick={() => handleSetValue(option?.id)} className={`${option?.id === selectedOption?.id ? 'active' : ''}`}>{option?.label}</li>)}
                </ul>
            </div>}
        </div>
    );
}

export default UserGroupDropdown;