import "./MyGallery.scss"
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "App/hooks-store/store";
import { getPreviewGalleryLink } from "App/Helper/utilities";
import SubscriptionWarning from "../SubscriptionWarning";
import { useNavigate } from "react-router-dom";
import { setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import { useDispatch } from "react-redux";
import AddPlusIconButton from "App/Components/AddPlusIconButton";
import GalleriesList from "./Components/GalleriesList";

const MyGallery = ({ galleryList = [], setPrimaryHandler=()=>{}, showGallerySkelton = false,
    selectedCollection = '', setGalleryUpdateId,onNavigateToExploreRentals, onClick = ()=>{}, wall = [],warningPopup=false,
    isSubscriptionReached=false, setGalleryPreview, handleEdit, onClickDeleteGallery, onCancel }) => {
    const [gallery, setGallery] = useState([]);
    const navigate = useNavigate()
    const dispatch = useStore()[1];
    const [mouseEnter, setMouseEnter] = useState(false);
   const [tab ,setTab] = useState("private");
        const dispatchRedux = useDispatch();
    const onMouseBlur = useCallback(() => {
        setMouseEnter(false)
    }, []);
    const blurHandler = () => {
        setMouseEnter(false)
    }
    const onMouseFocus = useCallback(() => {
        warningPopup && setMouseEnter(true)
    }, [warningPopup]);
    useEffect(() => {
        setGallery(galleryList)
    }, [galleryList])
    
    const onGalleryPreviewHandler = (e, disable) => {
        if(!disable) {
            const galleryId = e?.currentTarget?.dataset?.id;
            let currentArray = gallery?.find(item => item.id === parseInt(galleryId))
            dispatch('setCurrentGallery', currentArray)
            navigate(`/curate/display/my-galleries/view/${galleryId}`);
            dispatchRedux(setFormInitiated(true));
        }
    }
    
    const handlePreview = (id, previewType, e) => {
        e.stopPropagation();
        getPreviewGalleryLink(id, previewType);
    }

    const discoverableGalleries = useMemo(()=>{
     let filteredDiscoverableGalleries = isSubscriptionReached ? gallery?.filter((gallery) => gallery.isDiscoverable === true):[];
     return filteredDiscoverableGalleries.sort((a, b) => b.priority - a.priority)
    },[gallery,isSubscriptionReached]);

    const nonDiscoverableGalleries = useMemo(()=>{
      return isSubscriptionReached? gallery?.filter((gallery) => gallery.isDiscoverable === false):gallery;
    },[gallery,isSubscriptionReached]);

    const menuTabs = useMemo(()=>{
      let galleryTabs=  [
        { id: "private", element: 'Private',count:nonDiscoverableGalleries?.length },
        { id: "my-rentals", element: 'My Rentals',count:0 },
        // { id: "rentals", element: 'Rentals',count:rentalsGalleries?.length }
    ]

    if(isSubscriptionReached&&discoverableGalleries?.length){
        galleryTabs?.splice(1,0,{ id: "public", element: 'Public',count:discoverableGalleries?.length })
    }
    return galleryTabs
}
,[discoverableGalleries,nonDiscoverableGalleries,isSubscriptionReached])


    const handleTabs = (e) => {
        const selectTab = e.currentTarget?.dataset?.id;
        setTab(selectTab)
        const root = document.getElementById('gallery-list');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

    }

    return (
            <>
                 <div className={`w-full flex flex-col justify-between h-full  pt-4 my-gallery-banner`}>
                    <div className="pt-0 px-4 sm:pb-4 pb-0 pr-sm  flex gap-4 items-center relative" onMouseLeave={onMouseBlur}>
                        <div className="flex flex-col justify-center text-[1.125rem] font-medium mb-[3px]"><p className="text-[1.25rem]">Galleries</p></div>
                        <div onMouseOverCapture={onMouseFocus}
                        onBlur={blurHandler}>
                         <AddPlusIconButton className={`flex items-center ml-auto ${warningPopup&&"warning-btn"}`} disabled={showGallerySkelton} onClick={onClick}/>
                           <div className={`warning-toolkit ${mouseEnter&&"active"}`}><SubscriptionWarning content="galleries" isSubscriptionReached={isSubscriptionReached}/></div>
                        </div>
                    </div>   
                    {process.env.REACT_APP_COMPANY !== 'anywall' && <div id="discover-tab" className="tab-container mb-2">
                        <div className="tab-list flex sm:gap-[1.42857rem] gap-[0.625rem] border-[#30333A] border-b">
                        {menuTabs?.map((menu, index) => {
                            return (
                            <div
                                onClick={handleTabs}
                                data-id={menu?.id}
                                key={menu?.id}
                                className={`tab-data ${menu?.id === tab ? "active":"not-active"}`}
                            >
                                {menu?.element}{" "}
                                    <span className=" inline-block">{`(${menu?.count})`}</span>
                            </div>
                            );
                        })}
                        </div>
                    </div>}                  
                        {/* {(gallery?.length||showGallerySkelton)&&<div className=" text-left pl-4 mt-[1.5rem] mb-[0.5rem]">
                            <p>Public <span className="text-[var(--cu-black-5)]">({ discoverableGalleries?.length})</span></p>
                        </div>}  */}

                 <div id="gallery-list" className="overflow-y-auto h-full">
                <GalleriesList   
                        gallery={gallery}
                        showGallerySkelton={showGallerySkelton}
                        galleryList={process.env.REACT_APP_COMPANY !== 'anywall' ? (tab==="public"?discoverableGalleries:tab==="private"?nonDiscoverableGalleries:[]) : galleryList}
                        tab={tab==="my-rentals"?"rentals":tab}
                        onGalleryPreviewHandler={onGalleryPreviewHandler}
                        setPrimaryHandler={setPrimaryHandler}
                        selectedCollection={selectedCollection}
                        onCancel={onCancel}
                        onNavigateToExploreRentals={onNavigateToExploreRentals}
                        onGalleryCreateClick={onClick}
                        handleEdit={handleEdit}
                        handlePreview={handlePreview}
                        onClickDeleteGallery={onClickDeleteGallery}
                    />
                 </div>
                    {/* {(gallery?.length||showGallerySkelton)&&<div className=" text-left pl-4 mb-[0.5rem]">
                            <p>Private <span className="text-[var(--cu-black-5)]">({nonDiscoverableGalleries?.length})</span></p>
                        </div>}
                        {gallery &&!showGallerySkelton&&<div className={`pt-0 p-4 pr-sm grid gap-2.5 ${screensize > 550 ? 'home-grid' : ''}  gallery-top-sm`}>
                             
                                {nonDiscoverableGalleries?.map((info, i) => {
                                    info = { ...info, createdAt: info?.nftDropStartedAt }
                                    return (
                                        <Fragment key={i}>
                                            <div key={i} data-id={info?.id} onClick={(e) => onGalleryPreviewHandler(e, info?.disableGallery)} className='cursor-pointer'>
                                                <Card
                                                    key={i}
                                                    cardProp={info}
                                                    type="Gallery"
                                                    setPrimaryHandler={setPrimaryHandler}
                                                    selectedCollection={selectedCollection}
                                                    preview={!info?.disableGallery}
                                                    showEdit={info?.disableGallery}
                                                    handleEdit={handleEdit}
                                                    handlePreview={handlePreview}
                                                    onClickDeleteGallery={onClickDeleteGallery}
                                                    onCancel={onCancel}
                                                /></div>

                                        </Fragment>)
                                })

                            }
                          
                    </div>} 
                    <div className={`pt-0 p-4 pr-sm`}>
                        <DetailsSkeltonCard
                            show={showGallerySkelton}
                            height={"100%"}
                            count={3}
                            inline={true}
                            details={"1-line"}
                            noAbsolute
                            parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
                            containerClassName={`flex`}                   
                        />
                    </div> */}
                </div>
            </>
    )
}

export default MyGallery