import GalleryImages from "../../../../../../Components/Ui/GalleryFrameImages";
import {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import "./PreviewGallery.scss";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import ViewGallery from "../../../ViewGallery";
import zoomIcon from "Assets/Icons/enlargeIcon.png";
import zoomOutIcon from "Assets/Icons/minimize.svg";
import { deepCopy, getMetaThumbImageById, popupMobileScrollAdj, scrollToTop } from "App/Helper/utilities";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import "App/Pages/NftGallery/NftGalleries.scss";
import PreviewDetailRight from "./Components/PreviewDetailRight";
import useScreenSize from "App/hooks/use-screen-size";
import { useDispatch } from "react-redux";
import { setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import DetailsGalleryTab from "./DetailsGalleryTab";
import { useNavigate } from "react-router-dom";
import useCollectionLoader from "App/hooks/use-collectionLoader";
import Tilt from "react-parallax-tilt";
import PreviewGalleryCollections from "./DetailsGalleryTab/Components/PreviewGalleryCollections";
const PreviewGallery = ({
  OnClick,
  onClickDeleteGallery,
  selectedWall,
  previewGalleryFromList,
  onClickExit,
  galleryUpdateId,
  viewFromOther = false,
  enableEdit = false,
  galleryType = 0,
  standalone = false,
  fromDiscover,
  page,
  onClose,
  type,
  setSelectedNft,
  imageRatioCalculated,
  selectedGallery,
  applyFrameButton,
  communityData,
  className, backUrl = '', fromMainPage = false
}) => { 
  const { triggerAPI } = useHttp();
  const [  { currentGallery, activeMenuIcons, leftMenuCollapse },  dispatch, ] = useStore(false);
  const [displayChange, setDisplayChange] = useState("lg");
  const [showDetails, setShowDetails] = useState({ mode: false });
  const [selectedNfts, setSelectedNfts] = useState({});
  const [zoom, setZoom] = useState(false);
  const [currentNft, setCurrentNft] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [spinnerShow, setSpinnerShow] = useState(false);
  const [captionToggle, setCaptionToggle] = useState(false);
  const [frameToggle, setFrameToggle] = useState(true);
  const [files, setFiles] = useState({});
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [activeTab, setActiveTab] = useState("pieces");
  const targetRef = useRef();
  const allDiv = useRef();
  const screenSize = useScreenSize()?.width;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const dispatchRedux = useDispatch();
  const navigate = useNavigate()
  const { sliceCollectionAPI } = useCollectionLoader();
  const isOwn = useMemo(() => {
    return viewFromOther ? false : galleryUpdateId ? true : false
  },[viewFromOther, galleryUpdateId]);
  useEffect(() => {
    if(fromMainPage) {
      dispatchRedux(setFormInitiated(false));
      return () => {
          dispatch('resetAll')
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [fromMainPage])
  const settingAsset = useCallback((assets) => {
    return assets.map((assetData) => {
      return {
        nftcdnimage: assetData?.details?.nftcdnimage,
        nftcdnimage256: assetData?.details?.nftcdnimage256,
        nftcdnimage512: assetData?.details?.nftcdnimage512,
        asset: assetData?.details?.asset,
        frameId: assetData?.frameId,
        name: assetData?.details?.name,
        policy: assetData?.details?.policy,
        policy_id: assetData?.policy_id,
        asset_name: assetData?.asset_name,
        isLandscape: assetData?.isLandscape,
        assetMetadata: assetData?.details?.onchain_metadata,
        contractAddress: assetData?.contractAddress,
      };
    });
  },[]);
  useEffect(() => {
    if (type === "collectors" && isPortrait && screenSize <= 550) {
      setDisplayChange("sm");
    }
  }, [type, isPortrait, screenSize]);
  
  useEffect(()=> {
    if(currentGallery?.files)
      setFiles(currentGallery?.files)
  }, [currentGallery?.files])
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    dispatch("enableTopIcons", { frame: activeTab === "pieces", caption: activeTab === "pieces"}); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])
  useEffect(() => {
    if (!viewFromOther) {
      setGallery(currentGallery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGallery, viewFromOther]);

  // useEffect(() => {
  //     if (!viewFromOther) {
  //         setFormValue(currentFormValues);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentFormValues, viewFromOther])

  const getPageResult = useCallback((res) => {
    const data = res.data;
    setFormValue({
      name: data.name,
      assets: data?.assets,
      collectionSize: data.collectionSize,
      dropTypeId: data.dropTypeId,
      description: data.description,
      nftDropStartedAt: data.nftDropStartedAt,
      twoDWallBackgroundId: data.thumbnailBackground?.id,
      price: data.price,
      profileData: data.userData,
      Shapes: data.Shapes,
    });
    setSelectedLayout(data.Layout);
    let tempAssets = settingAsset(data?.assets)
    setCurrentNft(tempAssets);
    dispatch("setCurrentSelectedNfts", tempAssets);
    if(isOwn)
        sliceCollectionAPI(tempAssets, 'setCurrentGalleryAssets', 'updateCurrentGalleryAssetsLoadingStatus');
    dispatch('setCurrentGallery', data)
    dispatchRedux(setCurrentFormValues(deepCopy(data)));
    setSpinnerShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageData = useCallback(() => {
    setSpinnerShow(true);
    const url = "gallery/stand-alone/get/";
    triggerAPI(
      {
        url: `${url}${galleryUpdateId}?isVerified=0&compression=0`,
        method: "get",
      },
      getPageResult
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageResult, triggerAPI]);

  useEffect(() => {
    if (standalone) getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standalone]);
  useEffect(() => {
    if (!currentNft?.length) {
      setSpinnerShow(true);
    } else setSpinnerShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNft]);

  const imageOnSelect = (e) => {
    setShowDetails({ mode: true });
    const choosenNft = currentNft?.filter(
      (x) => x.asset === e?.currentTarget?.dataset?.asset
    );
    setSelectedNfts(choosenNft);
    if (setSelectedNft) setSelectedNft(choosenNft);
    setZoom(false);
    
  };
  const onCloseHandler = () => {
    setShowDetails({ mode: false });
    popupMobileScrollAdj(false);
    setSelectedNfts({});
  };

  const onZoomHandler = (e) => {
    setZoom(!zoom);
  };

  useEffect(() => {
    popupMobileScrollAdj(zoom);
  }, [zoom]);

  useEffect(() => {
    if (fromDiscover && currentNft?.length) {
      // setShowDetails({ mode: true });
      const choosenNft = [currentNft[0]];
      !(isPortrait && screenSize <= 550 && type === "collectors") &&
        setSelectedNfts(choosenNft);
      setZoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDiscover, currentNft]);
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      setTimeout(() => {
        scrollToTop();
      }, 1000);
    }
    
    
    dispatch("enableTopIcons", {
      favourite: false,
      sorting: false,
      dropDown: false,
      caption: (activeTab === 'pieces'),
      frame: (activeTab === 'pieces'),
      edit: isOwn,
      delete: isOwn,
      lgView: false,
      smView: false,
    });
    dispatch("setActiveIcons", { frame: false, edit: false });
    return () => {
      dispatch("setActiveIcons", { delete: false, edit: false });
      dispatch("enableTopIcons", {
        delete: false,
        edit: false,
        caption: false,
        frame: false,
        dropDown: false,
        sorting: false,
        lgView: false,
        smView: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryUpdateId]);
  useEffect(() => {
    dispatch('setTopMenuBarOptions', {showMenuIcons: (isOwn) || (activeTab === 'pieces'), showTopMenus: false});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwn, activeTab])
  useEffect(() => {
    if (activeMenuIcons?.back) {
      dispatch("setActiveIcons", { delete: false });
      // onClickExit && onClickExit();
      if(backUrl){
        navigate(backUrl);
      } else {
         onClickExit && onClickExit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.back]);

  useEffect(() => {
    setFrameToggle(activeMenuIcons?.frame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.frame]);

  useEffect(() => {
    setCaptionToggle(activeMenuIcons?.caption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.caption]);

  useEffect(() => {
    if (activeMenuIcons?.delete) {
      onClickDeleteGallery('', parseInt(galleryUpdateId));
      dispatch("setActiveIcons", { delete: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.delete]);

  useEffect(() => {
    if (activeMenuIcons?.edit) {
      OnClick();
      dispatch("setActiveIcons", { edit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuIcons?.edit]);
  const updateHeight = () => {
    const root = document.documentElement;
    if (targetRef.current) {
      root?.style.setProperty(
        "--collection-gallery-previewcard-height",
        `${targetRef.current.offsetWidth}px`
      );
    }
  };
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1000);
    updateHeight();
  }, [
    leftMenuCollapse,
    captionToggle,
    frameToggle,
    currentNft?.length,
    spinnerShow,
  ]);
  const selectNextNft = () => {
    const currentIndex = currentNft.findIndex((nft) => nft.asset === selectedNfts[0].asset);
    const lastIndex = currentNft.length - 1;
  
    if (currentIndex < lastIndex) {
      const nextNft = currentNft[currentIndex + 1];
      setSelectedNfts([nextNft]);
      console.log("Selected Next NFT:", nextNft);
    } else {
      // If at the last item, go to the first one
      const nextNft = currentNft[0];
      setSelectedNfts([nextNft]);
      console.log("Selected Next NFT:", nextNft);
    }
  };
  
  const selectPreviousNft = () => {
    if (selectedNfts && selectedNfts.length > 0 && currentNft && currentNft.length > 0) {
      const currentIndex = currentNft.findIndex((nft) => nft.asset === selectedNfts[0].asset);
  
      if (currentIndex > 0) {
        const previousNft = currentNft[currentIndex - 1];
        setSelectedNfts([previousNft]);
      } else {
        const previousNft = currentNft[currentNft.length - 1];
        setSelectedNfts([previousNft]);
      }
    }
  };
  const escPressed = () => {
    onCloseHandler()
  };
  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'Escape') {
        event.preventDefault();

        escPressed();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const metaBgImage = useMemo(() => {
    return getMetaThumbImageById(currentGallery?.metaBg);;
}, [currentGallery?.metaBg])
  return (
    <>
      {type === "collectors" &&
      selectedNfts?.length &&
      selectedNfts[0]?.asset ? (
        <PreviewDetailRight
        selectedLayout={selectedLayout}
        currentNft={currentNft}
        spinnerShow={spinnerShow}
        galleryUpdateId={galleryUpdateId}
        files={files}
        galleryType={galleryType}
        displayChange={displayChange}
        formValue={formValue}
        captionToggle={captionToggle}
        gallery={gallery}
        createdBy={currentGallery?.userId||currentGallery?.user?.id}
        selectedWall={currentGallery?.thumbnailBackground?.imageUrl256}
        metaThumb={metaBgImage}
        images={currentGallery?.thumbnailBackground?.imageUrl256}
        toGallery={onClose}
        fromCommunity={fromDiscover}
        communityData={communityData}
        setActiveTab={setActiveTab}
      />
      ) : (
        <div>
          <div className={`sm:sticky ${className} pt-[1rem] sm:z-[9] bg-[#0D0F11]`}>
              <div className={`   bg-[#0D0F11]`}>
                <PreviewDetailRight
                  metaThumb={metaBgImage}
                  selectedLayout={selectedLayout}
                  currentNft={currentNft}
                  spinnerShow={spinnerShow}
                  createdBy={currentGallery?.userId||currentGallery?.user?.id}
                  galleryUpdateId={galleryUpdateId}
                  files={files}
                  galleryType={galleryType}
                  displayChange={displayChange}
                  formValue={formValue}
                  captionToggle={captionToggle}
                  gallery={gallery}
                  selectedWall={currentGallery?.thumbnailBackground?.imageUrl256}
                  images={currentGallery?.thumbnailBackground?.imageUrl256}
                  toGallery={onClose}
                  fromCommunity={fromDiscover}
                  communityData={communityData}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="tab-container-details2 mobileTab pb-2">
            <div className="tab-list ">
            <div
                  className={`tab-data flex gap-[1.42857rem] pt-[1rem]`}
              >
              <div
                className={`tab ${activeTab === "pieces" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("pieces")}
              >
                Pieces
              </div>
              <div
                className={`tab ${activeTab === "collection" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("collection")}
              >
                Collections
              </div>
              <div
                className={`tab ${activeTab === "details" ? "active" : "not-active"}`}
                onClick={() => handleTabChange("details")}
              >
                Details
              </div>
            </div>
            </div>
            </div>
            </div>
          {showDetails?.mode &&
            !(isPortrait && screenSize <= 550) &&
            type !== "collectors" && (
              <div className="gallery-overlay" onClick={onCloseHandler}>
              <div className={`${zoom ? "zoom-nft" : ""} slider-user2`}  onClick={(e) => e.stopPropagation()}>
                <ViewGallery
                  page={page}
                  onAssetClose={onClose}
                  className={`${
                    zoom ? "h-full zoom-preview-gallery" : "preview-frame-img"
                  } preview-nft-view`}
                  galleryNew={selectedNfts}
                  fromDiscover={fromDiscover}
                  frameToggle={frameToggle}
                  zoomIcon={zoomIcon}
                  zoomOutIcon={zoomOutIcon}
                  onZoom={onZoomHandler}
                  zoom={zoom}
                  selectedListView={selectedNfts}
                  onClose={onCloseHandler}
                  selectedGallery={selectedGallery}
                  currentNft = {currentNft}
                  onClick={imageOnSelect}
                  selectNextNft={selectNextNft}
                  selectPreviousNft={selectPreviousNft}  
                  applyFrameButton={applyFrameButton}
                />
              </div>
              </div>
            )}
 {activeTab === "pieces" && (
      (!showDetails?.mode &&         
        ((screenSize > 650 ||
            (isPortrait && screenSize <= 550 && type === "collectors")) && (
            <div className={`nfts-selected  mt-[0.5rem] ${className}`}>
              <div className="flex flex-col added-nfts nfts-selected-box w-full overflow-y-auto overflow-x-hidden gallery-details">
                <SkeltonCard
                  show={spinnerShow}
                  height={"100%"}
                  count={5}
                  noAbsolute={true}
                  inline={true}
                  containerClassName={` ${
                    displayChange === "lg" ? "grid-lg" : "grid"
                  } pl-0  pb-4 gap-2 side-scroller  h-full`}
                />

                {!showDetails.mode && !spinnerShow && (
                  <div
                    className={` ${
                      displayChange === "lg" ? "grid-lg" : "grid-lg"
                    }  pb-4   gap-2 `}
                  >
                    {currentNft?.map((selectedNft, i) => ( //maping1
                      <div
                        ref={allDiv}
                        key={i}
                        className={`flex flex-col gap-[0.75rem]  preview-card-sq ${
                          selectedNft?.isLandscape && ""
                        }`}
                      >
                            <Tilt glareEnable={false} glareMaxOpacity={0} tiltEnable={ true} tiltMaxAngleX={10} 
                                tiltMaxAngleY={10} perspective={1500} 
                                glareColor={"rgba(0,0,0,1)"}
                                >
                        <div
                          ref={targetRef}
                          className={`nft-sq-card collection-card relative flex aspect-square flex-col 

                          // {selectedNfts.length &&
                          //   selectedNfts[0]?.asset === selectedNft?.asset
                          //     ? "active"
                          //     : ""}
                          `}
                          data-asset={selectedNft?.asset}
                          onClick={imageOnSelect}
                        >
                        
                          <GalleryImages
                            compress={true}
                            frameToggle={frameToggle}
                            nftGalleryNew={true}
                            index={i}
                            galleryNew={selectedNft}
                            src={selectedNft?.nftcdnimage256}
                            className="nft-gallery-card-image h-[100%]"
                            imageRatioCalculated={imageRatioCalculated}
                          />
                          
                        </div>
                        </Tilt>
                        {captionToggle && (
                          <div className="flex nft-selected-name">
                            <span
                              className={`${
                                displayChange === "sm"
                                  ? "name name-sm"
                                  : "name name-lg"
                              }`}
                            >
                              {selectedNft?.name
                                ? selectedNft?.name
                                : "No Name"}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
     ))))}

     {activeTab === "collection" && 
              (<PreviewGalleryCollections
                parentScrollContainerQuery={`.full-gallery`}
                galleryId={galleryUpdateId}/>)
     }

     {activeTab === "details" && (

           <DetailsGalleryTab
           galleryDetails={formValue}
           communityData={communityData}
           />

     )}
        </div>
      )}
    </>
  );
};

export default PreviewGallery;