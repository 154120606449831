import { useState, useCallback, useLayoutEffect } from "react";
import useHttp from "App/hooks/use-http";
import GalleryPreviewPlugin from "./Component";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./GalleryPreview.scss";
import { metaWallListData } from "App/Helper/utilities";
const GalleryPreview = () => {
    const { triggerAPI } = useHttp();
    const [searchParams] = useSearchParams();
    const [layoutId, setLayoutId] = useState(null);
    const [multiBg, setMultiBg] = useState(null);
    const [metaBg, setMetaBg] = useState(null);
    const [initalTimes, setInitalTimes] = useState(null);
    const [galleryData, setGalleryData] = useState(null);
    const [galleryInfo, setgalleryInfo] = useState(null);
    const [monoStyles, setMonoStyles] = useState(null);
    const [loading, setLoading] = useState(true);
    const metaWallList = metaWallListData();
    const navigate = useNavigate();
    let isAppV1 = searchParams.get("source") === 'appv1';
    const getPageResult = useCallback((res) => {
        const { data } = res;
        setgalleryInfo({name: data?.name, userName: data?.communityName ?? data?.userData?.username ?? ''})
        setLayoutId(data?.layoutId);
        setMultiBg(data?.thumbnailBackground);
        setMetaBg(metaWallList?.find(item => item?.id === data?.metaBg));
        const timer = {
            hr: data?.preference?.slideTimerHours ?? "0",
            min: data?.preference?.slideTimerMinutes ?? "0",
            sec: data?.preference?.slideTimerSeconds ?? "10"
        }
        let tempMonoStyles = {styleType: data?.monoStyleType}
        if(data?.monoStyleType === 'custom') {
            tempMonoStyles = {...tempMonoStyles, ...data?.monoCustomized}
        }
        setMonoStyles(tempMonoStyles)
        setInitalTimes(timer);
        setGalleryData(data);
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getPageData = () => {
        setLoading(true)
        const url = 'gallery/stand-alone/get/';
        const id = searchParams.get("id");
        triggerAPI({
            url: `${url}${id}?isVerified=0&compression=0`, method: 'get'
        }, getPageResult);
    }
    useLayoutEffect(() => {
        if(searchParams.get("id"))
            getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleGalleryView = (type) => {
        navigate(`/preview-gallery?id=${searchParams.get("id")}&type=0&page=${type}`);
    }
    // const handleControlPanelVisibility = useCallback((resp) => {
    // }, []);
    // as now we are hiding music icon from preview, if need update state here and toggle hide-music class
    return (
        <div className={`modern-gallery-preview hide-music`}> 
            {(!loading) &&   
                <GalleryPreviewPlugin 
                    galleryData={galleryData} 
                    galleryType={searchParams.get("page") ?? 'mono'}
                    isAppV1={isAppV1}
                    initalTimes={initalTimes}
                    layoutId={layoutId}
                    multiBgData={multiBg}
                    metaBgData={metaBg}
                    screenData={monoStyles}
                    galleryInformation={galleryInfo}
                    handleGalleryView={handleGalleryView}
                />
            }
        </div>
    )
}

export default GalleryPreview;