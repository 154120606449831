import { memo, useCallback, useEffect, useState } from "react";
import "./TileView.scss";
import { useRef } from 'react';
import logoSrc from "Assets/Images/Logo - Missing.svg";
import { useStore } from "App/hooks-store/store";
import SkeltonCard from "../SkeltonCard";
import { convertRemToPixels } from "App/Helper/aspectRatioCalc";

const d3 = require('d3');
const TileView = ({data, tileclick,searchSlugName=null, tooltipHtml, rootChanged, heightPercentage = 100, dataLoading = false, showTitles = true, centreTitles = false, hideRoot = false, hideLastLevelText = false}) => {  
  const chartRef = useRef();
  const { leftMenuCollapse } = useStore()[0];
  const [lastSelection, setLastSelection] = useState(searchSlugName);
  const [skeletonHeight, setSkeletonHeight] = useState(0);  
useEffect(()=>{
  setLastSelection(searchSlugName)
},[searchSlugName])
  const   createTreeChart = useCallback(() => {   
    //28 padding, 32 height adjustment in resulting svg translation
    const width = document.getElementById('mainContent')?.offsetWidth - 27;
    let height = (window.innerHeight - document.querySelector('.header-wrapper').clientHeight - 28 - (hideRoot?0:32)) * heightPercentage/100;
    //if (height < 800) height -=20; //ensure height fits on a small screen
    var paddingAllowance = 10;

    const getFontSize = (d) => {        
      return 18;
    }

    const getLowVal = d => {     
      let width = (x(d.x1) - x(d.x0))
      let chars = d.data?.name?.length??100;
      if (width<chars*getFontSize(d)*0.42)
      {
        let maxchars = (width-getFontSize(d)*0.4)/getFontSize(d)/0.4;
        return [(d.data.name?.substring(0,maxchars) + '...')]??'';  
      }
      return [d.data.name]??'';      
    };
    const name = d =>{
      let depth = d?.depth;
      return d
        .ancestors()
        .reverse()
        .map(d =>{
          if(Object?.keys(d?.data)?.length === 2 && depth !== 0){
            const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
            return isMac ? `⬅ ${d.data.name}` : `🡸 ${d.data.name}`;
          }
          return d.data.name})
        .join(" / ")};
    // const name = d => d.data.segment;
    function tile(node, x0, y0, x1, y1) {
      d3.treemapBinary(node, 0, 0, width, height);
      for (const child of node.children) {
        child.x0 = x0 + (child.x0 / width) * (x1 - x0);
        child.x1 = x0 + (child.x1 / width) * (x1 - x0);
        child.y0 = y0 + (child.y0 / height) * (y1 - y0);
        child.y1 = y0 + (child.y1 / height) * (y1 - y0);
      }
    }
    let layout = d3.treemap().tile(tile);
    
    const treemap = data =>
    {      
      let param = d3
      .hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);
      return layout(
        param
      );
    }
        
    let chartelement = chartRef.current;
    if(chartelement)
    chartelement.innerHTML="";
    const svg = d3
      .select(chartelement)
      .append("svg")
      .attr("viewBox", [0.5, hideRoot?0:-30.5, width, hideRoot?height:height + 30])
      .style("font", "16px ZenKaku");

    let x = d3.scaleLinear().rangeRound([0, width]);
    let y = d3.scaleLinear().rangeRound([0, height]);        
      
    let group;
    if (data?.children?.length) {
      let localLastSelection = lastSelection;
      // if (localLastSelection == null && data?.children.length) {
      //   //set the default node if most of your collection is in one category      
      //   let largestCategory = data?.children.sort((a,b)=>a.count < b.count ? 1 : -1)?.[0];           
      //   if ((largestCategory?.count/data?.children.reduce((sum, value) => sum + value.count,0)) >= 1) {      
      //     localLastSelection = largestCategory?.name;
      //     setLastSelection(localLastSelection)
      //     let largestSubCategory = largestCategory.children.sort((a,b)=>a.count<b.count?1:-1)[0];
      //     if ((largestSubCategory.count/largestCategory.children.reduce((sum, value) => sum + value.count,0))>= 1)                   
      //     {
      //       localLastSelection = largestSubCategory.name;
      //       setLastSelection(localLastSelection)          
      //     }          
      //   }      
      // }

      let hasDetailText = data?.children?.filter(d => d.detailText).length > 0;
      if (hasDetailText)
      {
          svg.attr("class","hasdetailtext");
      }

      const tree = treemap(data);  
      let defs = svg.append("defs");
      let captiongrad = defs.append("linearGradient").attr("id","captiongrad").attr("x1","0%").attr("x2","0%").attr("y1","100%").attr("y2","0%");
      captiongrad.append("stop").attr("offset","0%").attr("stop-color","#00000000");
      captiongrad.append("stop").attr("offset","50%").attr("stop-color","#000000AA");
      captiongrad.append("stop").attr("offset","100%").attr("stop-color","#000000");      
      let shadow = defs.append("filter").attr("id","shadow").attr("x","0%").attr("y","-40%").attr("width","140%").attr("height","140%");
      shadow.append("feGaussianBlur").attr("stdDeviation","3 3").attr("result","shadow");
      shadow.append("feOffset").attr("dx","0").attr("dy","1");
      let goldgrad = defs.append("linearGradient").attr("id","goldgrad").attr("x1","0%").attr("x2","100%").attr("y1","0%").attr("y2","0%");
      goldgrad.append("stop").attr("offset","0%").attr("stop-color","#CB9B51");
      goldgrad.append("stop").attr("offset","22%").attr("stop-color","#CB9B51");
      goldgrad.append("stop").attr("offset","45%").attr("stop-color","#F6E27A");
      goldgrad.append("stop").attr("offset","50%").attr("stop-color","#F6F2C0");
      goldgrad.append("stop").attr("offset","55%").attr("stop-color","#F6E27A");
      goldgrad.append("stop").attr("offset","78%").attr("stop-color","#CB9B51");
      goldgrad.append("stop").attr("offset","100%").attr("stop-color","#CB9B51");
      group = svg.append("g").call(render, tree);
      if (localLastSelection != null) {        
        const lastNode = tree
          .descendants()
          .find(e => e.data.name === localLastSelection);
        zoomin(lastNode,0);
      }
    }
    function render(group, root) {
      const node = group
        .selectAll("g")
        .data(root.children.concat(root))
        .join("g");

      node
        .filter(d => (d !== root))        
        .attr("class", d => "tile " + (d !== root && d.data?.detailText ? "hasdetailtext" : ""))

      node
        .attr("cursor", "pointer")
      
      node
        .filter(d => (d === root ? d.parent : d.children))                
        .on("click", d => {
          if (d === root) {
            setLastSelection(null)          
            zoomout(root);
          } else {
            setLastSelection(d.data.name)                    
            zoomin(d);
          }
        });
      
      d3?.selectAll("body > .toolTip").remove();
      var tool = d3
        .select("body")
        .append("div")
        .attr("class", "toolTip");

      let tooltipevent = (d) => {
        let text = tooltipHtml?.(d)??`${d.data.name??''}`;
        let tooltipSize = Math.max(d.data.name?.length,21)*6 + 100;
        let x = d3.event.clientX > window.innerWidth-tooltipSize?d3.event.clientX-tooltipSize+20:d3.event.clientX + 20;        
        let y = d3.event.clientY>window.innerHeight-120?d3.event.clientY - 100:d3.event.clientY - 20;        
        tool.style("left", x + "px");
        tool.style("top", y + "px");
        tool.style("display", "inline-block");
        tool.style("position", "absolute");
        tool.style("color", "#fff");
        tool.style("background", "#00000099");
        tool.style("padding", "1em");
        tool.style("border-radius", "0.5em");
        tool.style("font-family", "ZenKaku");
        tool.html(text);
      }
      let clipid = 1;
      let clip = node
        .append("clipPath")
        .attr("id", d => (d.clipUid = ("clip" + clipid++))) 

      clip.append("rect")
        .attr("x", 4)      
        .attr("y", 4)  
        .attr("width", d => x(d.x1) - x(d.x0)-8)      
        .attr("height", d => y(d.y1) - y(d.y0)-8)              
           
      let zoom = 1.5;

      node.append("rect")
        .attr("x", 4)      
        .attr("y", 4)  
        .attr("width", d => x(d.x1) - x(d.x0)-8)      
        .attr("height", d => d === root?0:y(d.y1) - y(d.y0)-8)   
        .attr("stroke", d => d.data.image256==null&&d !== root?"white":"transparent")
        .attr("fill", d => d.data.image256==null&&d !== root?"#222222":"transparent")
        .attr("cursor", "pointer")
        .on("click", function(d) {
          if (d.data.image256==null&&d !== root)
          {
            tool.style("display", "none");                        
            tileclick(d);                        
          }          
        })
        
      let nodeimage = node.append("image")
          .attr("clip-path", d => "url(#" + d.clipUid + ")")
          .attr("width", d => {
            if (d !== root&&d.data.image256)
            {
              let rectwidth = x(d.x1) - x(d.x0);
              let rectheight = y(d.y1) - y(d.y0);
              rectheight*=zoom;
              if (rectwidth > rectheight)
              {
                return rectwidth;
              }
              else
              {
                return rectheight;
              }              
            }
            else
            {
              return 0;
            }
          })
          .attr("height", d => {            
            if (d !== root && d.data.image256)
            {
              let rectwidth = x(d.x1) - x(d.x0);
              let rectheight = y(d.y1) - y(d.y0);  
              rectwidth*=zoom;                                
              
              if (rectwidth > rectheight)
              {
                return rectwidth;
              }
              else
              {
                return rectheight;
              }    
            }
            else
            {
              return 0;
            }
          })
          .attr("x", d => {
            let rectwidth = x(d.x1) - x(d.x0);
            let rectheight = y(d.y1) - y(d.y0);                             
            rectheight*=zoom;
            
            if (rectheight>rectwidth)
            {
              let offsetratio = rectheight-rectwidth;
              let offsetwidth = -offsetratio/2;
                    
              return offsetwidth
            }       
            return 0;
          })
          .attr("y", d => {
            let rectwidth = x(d.x1) - x(d.x0);
            let rectheight = y(d.y1) - y(d.y0); 
            rectwidth*=zoom;          
                       
            if (rectwidth>rectheight)
            {
              let offsetratio = rectwidth-rectheight;
              let offsetheight = -offsetratio/2;
                
              return offsetheight;
            }
            return 0;
          })          
          .attr("xlink:href", d=> {
            let max = Math.max(x(d.x1) - x(d.x0), y(d.y1) - y(d.y0))*zoom;
            let url = d !== root?(max>512? d.data.image1024:max>256?d.data.image512:d.data.image256):"";
            if (url?.startsWith("https://null") 
                || !url
            //this url is picked up inside photography, but doesn't actually resolve to an image, so I've excluded it...
                || url === "https://asset10lnvgvcw5ssptnlju66cvxv2l87udtcqz3aqr9.cur8.nftcdn.io/preview?size=1024&tk=vE895gFlQCulmgioBnS6TVAyDEWnBo3NwIJHPIMaTCE")
            {
              url = logoSrc;
            }
            return url;
          })
          .attr("cursor", "pointer")
          .on("click", function(d) {
            tool.style("display", "none");                        
            tileclick(d);                        
          })
          .on("mouseout", function(d) {
            tool.style("display", "none");
          });
      
      if (tooltipHtml)
      {
        nodeimage.on("mousemove", tooltipevent);
      }      

      if (centreTitles)
      {
        function getCentreFontSize(d) {          
          if (d === root) return "1.1em";
          if (!showTitles) return "0";
          if (hideLastLevelText && !d.children) return 0; 
          const width = x(d.x1) - x(d.x0),
            height = y(d.y1) - y(d.y0);
    
          let textLines = d.data.name.split("\r\n");
          let lineLength = Math.max(...textLines.map(l => l.length));                
          let size = Math.min(
            width / (lineLength*0.8), //limit the width according to the longest line
            height / 2, //limit the height to half
            Math.sqrt(width * width + height * height) / 9, //used to ensure the text doesn't fill the tile
            convertRemToPixels(2.5) //limit the size to 2.5rem
          );                   
          return size;
        }

        //shadow
        node
        .append("text")
        .attr("class","titletext")
        .attr("clip-path", d => d.clipUid)        
        .attr("style","filter: url(#shadow); fill:black")
        
        .attr("font-weight", d => (d === root ? "normal" : "bold"))
        .attr("font-size", d => getCentreFontSize(d)*1.04)
        .attr("text-anchor", d => (d === root ? null : "middle"))
        .attr("transform", d =>
          d === root
            ? null
            : `translate(${(x(d.x1) - x(d.x0)) / 2}, ${(y(d.y1) - y(d.y0)) / 1.9 +
            (d.data?.name?.indexOf("\r\n")>-1?10:0)})`
        )
        //.attr("display",d => (d === root ? "block" : "none"))
        .selectAll("tspan")
        .data(d =>
          d === root
              ? name(d).toUpperCase()?.split(/(?=\/)/g)
              : getLowVal(d)[0].toUpperCase().split("\r\n")??''      
        )        
        .join("tspan")
        .attr("x", 0)
        .attr(
          "y",
          (d, i, nodes) => {
            if (nodes?.length <= 1)
            {
              return "0.1em";
            }
            return `${(i === nodes?.length - 1) * 0.3 + (i - nodes?.length / 2) * 0.9}em`
          }          
        )        
        .text(d => d);

        //text
        node
        .append("text")
        .attr("class","shadowtext")
        .attr("clip-path", d => d.clipUid)          
        .attr("fill", d=> (d===root?"white":!d.data.image?d.data.textColour ==='gold'?"url(#goldgrad)":"white":"transparent"))        
        .attr("font-weight", d => (d === root ? "normal" : "bold"))
        .attr("font-size", d => getCentreFontSize(d))
        .attr("text-anchor", d => (d === root ? null : "middle"))
        .attr("transform", d =>
          d === root
            ? null
            : `translate(${(x(d.x1) - x(d.x0)) / 2 - 4}, ${(y(d.y1) - y(d.y0)) / 1.9 +
              (d.data?.name?.indexOf("\r\n")>-1?10:0)
              })`
        )
        //.attr("display",d => (d === root ? "block" : "none"))
        .selectAll("tspan")
        .data(d =>
          d === root
              ? name(d).toUpperCase()?.split(/(?=\/)/g)
              : getLowVal(d)[0].toUpperCase().split("\r\n")??''      
        )        
        .join("tspan")
        .attr("x", 3)
        .attr(
          "y",
          (d, i, nodes) => {
            if (nodes?.length <= 1)
            {
              return "0.1em";
            }
            return `${(i === nodes?.length - 1) * 0.3 + (i - nodes?.length / 2) * 0.9}em`
          }   
        )        
        .text(d => d);
        
        //detailtext
        node
        .append("text")
        .attr("class","detailtext")
        .attr("clip-path", d => d.clipUid)          
        .attr("fill", d=> (d===root?"white":!d.data.image?d.data.textColour ==='gold'?"url(#goldgrad)":"white":"transparent"))        
        .attr("font-weight", d => (d === root ? "normal" : "bold"))
        .attr("font-size", d => getCentreFontSize(d)*0.5)
        .attr("text-anchor", d => (d === root ? null : "middle"))
        .attr("transform", d =>
          d === root
            ? null
            : `translate(${(x(d.x1) - x(d.x0)) / 2 - 4}, ${(y(d.y1) - y(d.y0)) / 1.9 +
              (d.data?.detailText?.indexOf("\r\n")>-1?10:0)
              })`
        )
        //.attr("display",d => (d === root ? "block" : "none"))
        .selectAll("tspan")
        .data(d =>
          d === root
              ? ''
              : d.data?.detailText?.split("\r\n")??''      
        )        
        .join("tspan")
        .attr("x", 3)
        .attr(
          "y",
          (d, i, nodes) => {
            if (nodes?.length <= 1)
            {
              return "0.1em";
            }
            return `${(i - nodes?.length / 2) * 1.1}em`
          }   
        )        
        .text(d => d);

      node
        .selectAll("text")
        .attr("x", "0.5em")
        .classed("text-title", d => d === root)
        .classed("text-tile", d => d !== root)
        .filter(d => d === root)
        .selectAll("tspan")
        .attr("y", "1.3em")
        .attr("x", "");
      }
      if (!centreTitles)
      {
        if (showTitles)
        {
          node.append("rect")
            .attr("x", 4)      
            .attr("y", d=> {          
              return 0
            })  
            .attr("width", d => x(d.x1) - x(d.x0))      
            .attr("height", d => {           
              return d === root?0:getFontSize(d)*2
            })           
            .attr("fill", d => "url(#captiongrad)")
            .attr("display",d => (d.data.hideText?"none":"block"))        
            .attr("cursor", "pointer")
            .classed("rect-text-tile", d => d !== root)
            .on("click", function(d) {
              if (d.data.image256==null&&d !== root)
              {
                tool.style("display", "none");                        
                tileclick(d);                        
              }          
            })      
        }

        node
          .append("text")          
          .attr("clip-path", d => d.clipUid)
          .attr("fill", "white")
          .attr("font-size", d => {
            if (d === root) return "1.1em";     
            if (!showTitles) return "0";     
            return getFontSize(d);
          })
          .attr("text-anchor", d => (d === root ? null : "left"))
          .attr("transform", d => {
            console.log("hideText",d.data.hideText)
            let fontsize = getFontSize(d);

            return d === root
            ? null
            : `translate(5, ${fontsize*1.05})`          
          })
          .attr("display",d => (d === root ? "block" : (d.data.image256&&d.data.hideText)||(d.data.children==null&&d.data.value<=0)?"none":"block"))
          .selectAll("tspan")
          .attr("clip-path", d => d.clipUid)
          .data(d =>
            d === root
              ? name(d)?.split(/(?=\/)/g)
              : getLowVal(d)            
          )        
          .join("tspan")
          .attr("x", 3)
          .attr(
            "y",
            (d, i, nodes) =>
            d === root?`${(i === nodes?.length - 1) * 0.3 + (i - nodes?.length / 2) * 0.9}em`:'0'
          )        
          .attr("xml:space", "preserve")
          .text(d => d);      
          
        node
          .selectAll("text")
          .attr("x", "0.5em")
          .classed("text-title", d => d === root)
          .classed("text-tile", d => d !== root)
          .filter(d => d === root)
          .selectAll("tspan")
          .attr("y", "1.3em")
          .attr("x", "");
      }
              
      group.call(position, root);
    }
    function position(group, root) {
      group
        .selectAll("g")
        .attr("transform", d =>
          d === root ? `translate(0,-30)` : `translate(${x(d.x0)},${y(d.y0)})`
        )
        .select("rect")
        .attr("width", d => (d === root ? width+1-paddingAllowance : x(d.x1) - x(d.x0)-paddingAllowance))
        .attr("height", d => (d === root ? 30 : y(d.y1) - y(d.y0)-paddingAllowance));
    }

    // When zooming in, draw the new nodes on top, and fade them in.
    function zoomin(d,duration) {
      if (d)
      {                
        x.domain([d.x0, d.x1]);
        y.domain([d.y0, d.y1]);
        const group0 = group.attr("pointer-events", "none");
        if (rootChanged)
        {
          let newData = rootChanged(d.data);
          let parent = d.parent;
          d = treemap(newData);
          d.parent = parent;
          x = d3.scaleLinear().rangeRound([0, width]);
          y = d3.scaleLinear().rangeRound([0, height]);  
        }        
        if (d.children)
        {
          const group1 = (group = svg.append("g").call(render, d));
          svg
            .transition()
            .duration(duration??750)
            .call(t =>
              group0
                .transition(t)
                .remove()
                .call(position, d.parent)
            )
            .call(t =>
              group1
                .transition(t)
                .attrTween("opacity", () => d3.interpolate(0, 1))
                .call(position, d)
            );
        }        
      }      
    }

    // When zooming out, draw the old nodes on top, and fade them out.
    function zoomout(d) {
      if (d)
      {        
        x.domain([d.parent.x0, d.parent.x1]);
        y.domain([d.parent.y0, d.parent.y1]);
        const group0 = group.attr("pointer-events", "none");
        if (rootChanged)
        {
          let newData = rootChanged(d.data);
          let parent = d.parent;
          d = treemap(newData);
          d.parent = parent;
          x = d3.scaleLinear().rangeRound([0, width]);
          y = d3.scaleLinear().rangeRound([0, height]);            
        }
        const group1 = (group = svg.insert("g", "*").call(render, d.parent));
        svg
          .transition()
          .duration(750)
          .call(t =>
            group0
              .transition(t)
              .remove()
              .attrTween("opacity", () => d3.interpolate(1, 0))
              .call(position, d)
          )
          .call(t => group1.transition(t).call(position, d.parent));
      }
    }
    
    setSkeletonHeight(chartRef?.current?.clientHeight);
    return svg.node();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, lastSelection, showTitles, leftMenuCollapse]);

  useEffect(() => {
    return () => {
      //unmount
      d3.selectAll("body > .toolTip").remove();
    }
  }, [])

  useEffect(() => {    
    createTreeChart();   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTitles]);

  useEffect(() => {
    setTimeout(() => {
      //when menu toggle's, giving time to toggle to calculate width of container
      createTreeChart(); 
    }, 700);   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftMenuCollapse]) //props update
  useEffect(() => {
    // setLastSelection(null)
    createTreeChart();   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) //props update
  
  return (
    <>    
      {dataLoading && <SkeltonCard show={dataLoading} inline={false} noAbsolute={false} height={skeletonHeight}></SkeltonCard>}
      <div ref={chartRef} className={`tileview ${dataLoading && 'opacity-0'}`}></div>
    </>
  )
}
export default memo(TileView);
