import { toMilliseconds } from "App/Helper/getTime";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import MultiSlider from "./Component/MultiSlider";
import MonoSlider from "./Component/MonoSlider";
const GallerySlider = forwardRef((props, ref) => {
    const sliderRef = useRef(null);
    // const manualSlideChange = useRef(false); //if manual move, show loader untill reach
    const [autoPlayTimer, setAutoPlayTimer] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [nextSlide, setNextSlide] = useState(0);
    const [lastSlide, setLastSlide] = useState(0);
    const [hideCaptionOnSlideMove, setHideCaptionOnSlideMove] = useState(false);
    const beforeChange = useCallback((from, to) => {
        setLastSlide(from);
        setCurrentSlide(to);
        setHideCaptionOnSlideMove(true);
        setTimeout(() => {
            setHideCaptionOnSlideMove(false);
        }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const afterChange = useCallback((index) => {
    }, []); 
    useEffect(() => {
        let slideLen = props?.sliderList?.length;
        let nextSlide = (currentSlide === (slideLen -1) ? 0 : (currentSlide + 1))
        setNextSlide(nextSlide);
    }, [currentSlide, props?.sliderList?.length])
    const settings = useMemo(() => {
        return {
            dots: false,
            infinite: props?.sliderList?.length > 1,
            slidesToScroll: 1,
            slidesToShow: 1,
            initialSlide: 0,
            speed: 5000,
            pauseOnHover: false,
            draggable: false,
            touchMove: false,
            arrows: false,
            fade: props?.animation === 'fade',
            autoplay: autoPlayTimer > 0,
            autoplaySpeed: autoPlayTimer,
            beforeChange: beforeChange,
            afterChange: afterChange,
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPlayTimer, props?.sliderList?.length, props?.animation]) 
    useImperativeHandle(ref, () => ({
        toggleSlidePause : () => {
            pauseSlider();
        },
        toggleSlidePlay : (timer) => {
            playSlider(timer);
        },
        goToPrevSlide : () => {
            callPrevSlide();
        },
        goToNextSlide : () => {
            callNextSlide();
        },
        goToSlide : (slideNo) => {
            callSlideNo(slideNo);
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[]);   

    const callPrevSlide = useCallback(() => {
        sliderRef.current.slickPrev();
        pauseSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const callNextSlide = useCallback(() => {
        sliderRef.current.slickNext();
        pauseSlider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const playSlider = useCallback((timer) => {
        sliderRef.current?.slickPlay();
        const { hr, min, sec } = timer;
        let ms = toMilliseconds(hr, min, sec);
        setAutoPlayTimer(ms);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const pauseSlider = useCallback(() => {
        sliderRef.current?.slickPause();
    },[]);

    const callSlideNo = useCallback((slideNo) => {
        sliderRef.current?.slickGoTo(slideNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(() => {
        if(props?.sliderList?.length)
            props?.setcurrentPlayingAsset(props?.sliderList?.[currentSlide]?.asset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide, props?.sliderList])
    const shouldLoadSlide = useMemo(() => {
        return [currentSlide, lastSlide, nextSlide]
    }, [currentSlide, lastSlide, nextSlide])
    return (
        <Slider ref={sliderRef} {...settings} className={`mordern-gallery-slider ${props?.animation}`}>
            {props?.isMulti ? props?.sliderList?.map((item, index) => {
    return (
        (shouldLoadSlide?.includes(index)) ?
        <MultiSlider item={item} i={index} data-index={`${Math.random}`} key={`${Math.random}`} 
                    onChainControl={props?.onChainControl} 
                    multiInnerClassName={props?.multiInnerClassName} 
                    bgSrc={props?.bgSrc} 
                    // selectedLayout={props?.selectedLayout}
                 /> : <div key={index}></div>
            )}
            ): props?.sliderList?.map((item, index) => (shouldLoadSlide?.includes(index)) ? <MonoSlider item={item} i={index} key={index} playAudio={currentSlide === index}
                imageRatioCalculated={props?.imageRatioCalculated} hideCaptionOnSlideMove={hideCaptionOnSlideMove} />: <div key={index}></div>)}
        </Slider>
    )
});
export default memo(GallerySlider);