import { useState } from "react";
import editIcon from "Assets/Icons/edit-svg.svg";
import trashIcon from "Assets/Images/Bin.svg";
import editHover from "Assets/Icons/edit_hover.svg";
import trashHover from "Assets/Images/BinHover.svg";

const EditDelete = ({ editHandlerFn, deleteHandlerFn, isEditable , isDeletable }) => {
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isTrashHovered, setIsTrashHovered] = useState(false);

  const handleEditMouseEnter = () => {
    setIsEditHovered(true);
  };

  const handleEditMouseLeave = () => {
    setIsEditHovered(false);
  };

  const handleTrashMouseEnter = () => {
    setIsTrashHovered(true);
  };

  const handleTrashMouseLeave = () => {
    setIsTrashHovered(false);
  };
  return (
    <div className={`icons-overlay-edit-delete`}>
      {!!isEditable&&<div
        onMouseEnter={handleEditMouseEnter}
        onMouseLeave={handleEditMouseLeave}
        onClick={editHandlerFn}
      >
        <img src={isEditHovered ? editHover : editIcon} alt="Edit" />
      </div>}
      {!!isDeletable&&<div
        onMouseEnter={handleTrashMouseEnter}
        onMouseLeave={handleTrashMouseLeave}
        onClick={deleteHandlerFn}
      >
        <img src={isTrashHovered ? trashHover : trashIcon} alt="Trash" />
      </div>}
    </div>
  );
};
export default EditDelete;
