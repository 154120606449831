import React from 'react'
import styles from "./Baner-sm.module.scss"

const BanerSm = (props) => {
    return (
        <div className={`${styles["baner-sm"]} banner-sm ${props?.className}`}>
            {props.children}
        </div>
    )
}

export default BanerSm
