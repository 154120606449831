import "./ReleaseDetails.scss";
import CommunityGalleryDetails from "./Components/CommunityDetails"
import { useLocation } from "react-router-dom";
const ReleaseDetails = ({ page="",formData, galleryType = 0, fetchProfile, openBtnAction='', showOpenBtn=false, onClose, communityData, fromCommunity, setActiveTab }) => {
  const { pathname } = useLocation();
  const showCommunityGallery = pathname.includes("community") ? true : false;
  return (
      <CommunityGalleryDetails page={page} onClose={onClose} nft={formData} showDate={showCommunityGallery} showOpenBtn={showOpenBtn}
      openBtnAction={openBtnAction} communityData={communityData} fromCommunity={fromCommunity} setActiveTab={setActiveTab}/>
  );
};

export default ReleaseDetails;
