import useScreenSize from "App/hooks/use-screen-size";
import { useState } from "react";
import editIcon from "Assets/Icons/edit-svg.svg";
import editHover from "Assets/Icons/edit_hover.svg";
import close from "Assets/Icons/close.svg";
import copyIcon from "Assets/Icons/copy-icon-dark.svg";
import "./WalletList.scss";

import EditSection from "./editSection";
import { useStore } from "App/hooks-store/store";
import { copyClipHandler, splitWord } from "App/Helper/utilities";
import FormatField from "App/Components/Ui/FormatField";
import CopyIcon from "App/Components/IconsComponents/CopyIcon";
import EditIcon from "App/Components/IconsComponents/EditIcon";
import CloseIcon from "App/Components/IconsComponents/CloseIcon";

const Listing = ({ wallet, isEdit, index, onDeleteConfirm, editWallet }) => {
  const screenSize = useScreenSize()?.width;
  const [edit, setEdit] = useState(false);
  const dispatch = useStore(false)[1];
  const { blockchains } = useStore()[0];
  const editHandler = () => setEdit((prev) => !prev);
  const onCancel = () => setEdit(() => false);
  const copyAddress = () => {
    copyClipHandler(wallet.walletAddress);
    // dispatch("showToast", {
    //   toast: { toastMode: "success", message: "Wallet address copied to clipboard" },
    // });
  };
  return (
    <li
      key={wallet.walletAddress}
      className="justify-between wallet-full flex gap-1"
    >
      <div className="flex gap-3 address-wrap w-full items-center justify-between">
        <div className="flex gap-1 w-full wallet-add">
          <img className="wallet-icon"
                src={blockchains?.filter(b=>b.id == wallet?.blockchainTypeId)?.[0]?.icon}
                title={blockchains?.filter(b=>b.id == wallet?.blockchainTypeId)?.[0]?.name} alt=""/>
          
          <span
            className={`${
              isEdit ? "edit-mode" : ""
            } wallet-name min-w-fit wallet-list-count grid items-center`}
          >
            <FormatField
              type="sub"
              maxWidth={20}
              value={wallet.walletName ?? `Wallet Nº${index + 1} `}
              /> 
            &nbsp;:
          </span>
          {splitWord(wallet?.walletAddress, 20)}
          {/* <img
            onClick={copyAddress}
            className="cursor-pointer w-4 ml-2.5"
            alt="Img"
            src={copyIcon}
          /> */}
          <CopyIcon className={"cursor-pointer w-4 ml-2.5"} onClick={copyAddress} />
        </div>
        <div className="flex">
          {screenSize > 550 && (
            <div className="flex w-28">
              <span className=" wallet-nfts items-center">
                {wallet?.nftCount} Tokens
              </span>
            </div>
          )}

          {isEdit && (
            <div className="flex">
              <div className="flex gap-[0.75rem] ml-auto edit-icons">
                <span className="cursor-pointer flex items-center">
                  {/* <img src={editHover} alt="edit"  /> */}
                  <EditIcon onClick={editHandler} />
                </span>
                <span className="cursor-pointer grid items-center">
                  <CloseIcon onClick={onDeleteConfirm}
                    dataWallet={wallet.id}/>
                  {/* <img
                    src={close}
                    alt="close"
                    onClick={onDeleteConfirm}
                    data-wallet={wallet.id}
                  /> */}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {edit && (
        <EditSection
          onCancel={onCancel}
          wallet={wallet}
          editWallet={editWallet}
          index={index}
        />
      )}
    </li>
  );
};

export default Listing;
