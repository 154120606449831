import { capitalizeFirstLetter } from "App/Helper/utilities";
import DeleteWarning from "App/Pages/NftGallery/Component/DeleteWarning";
import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import { useCallback, useEffect, useState } from "react";

const DeleteGallery = (props) => {
    const { triggerAPI } = useHttp();
    const [deleteWarning, setDeleteWarning] = useState(false);
    const dispatch = useStore(false)[1];
    useEffect(() => {
        setDeleteWarning(props?.show)
    }, [props?.show])
    const closeDeleteWarnHandler = () => {
        setDeleteWarning(false);
        props?.deleteResult(-1);
    }
    const deleteGalleryResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner');
        dispatch('showToast', { toast: { toastMode: 'success', message: capitalizeFirstLetter(message) } });
        props?.deleteResult(1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const deleteButtonClickHandler = useCallback(() => {
        if (props?.dltId) {
            dispatch('showSpinner');
            let url = 'gallery/user/delete';
            triggerAPI({
                url: `${url}`, method: 'delete', data: { id: props?.dltId }
            }, deleteGalleryResult,()=>{
                dispatch("hideSpinner");
            });
            props.setReloadCount && props.setReloadCount((prev) => prev + 1);
        }
        else {
            props?.deleteResult(0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteGalleryResult, dispatch, props?.dltId, triggerAPI])
    return (
        deleteWarning && <DeleteWarning onCancel={closeDeleteWarnHandler} onDelete={deleteButtonClickHandler} />
    )
}
export default DeleteGallery;