import './DiscoverTopPanel.scss';
import twitterIcon from 'Assets/Icons/social/Twitter.svg';
import discordIcon from 'Assets/Icons/social/Discord.svg';
import youtubeIcon from 'Assets/Icons/social/YouTube.svg'
import { useCallback, useEffect, useMemo, useState } from 'react';
import useHttp from 'App/hooks/use-http';
import { useParams } from 'react-router-dom';
import { useStore } from 'App/hooks-store/store';
import userBanner from 'Assets/Icons/community_background.png'
import { appendUserName } from 'App/Helper/utilities';
import DetailsSkeltonCard from 'App/Components/Ui/detailsSkeleton';
import useAuth from 'App/hooks/use-auth';
import LogoAndName from 'App/Components/LogoAndName';
import instagramIcon from 'Assets/Icons/social/Instagram.svg'
import websiteIcon from 'Assets/Icons/social/websiteIcon.svg'
import SkeletonLoader from 'App/Components/Ui/SkeletonLoader';

const DiscoverTopPanel = (props) => {
    const [{currentUserId}, dispatch] = useStore();
    const isLoggedIn = useAuth()[0]
    const { triggerAPI } = useHttp();
    const { userId } = useParams();
    const [profileData, setProfileData] = useState(null);
    const [isFollowed, setIsFollowed] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if(props?.fromOther) {
            setProfileData(props?.profileData)
        } else {
            getCreatorDetail();
        }
        setIsFollowed(props?.profileData?.isFollowing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.profileData, props?.fromOther, userId])
    const getCreatorDetailResponse = useCallback((res) => {
       // dispatch('hideSpinner');
        res.data.name = res?.data?.username; //no more using name
        setProfileData(res?.data);
        if(props?.fromCollector){
            setIsFollowed(res?.data?.isFollowingCollector);
            props?.onSetCollectorDetails && props?.onSetCollectorDetails(res?.data);
        } else {
            setIsFollowed(res?.data?.isFollowed);
        }
       let timer= setTimeout(()=>{
            setLoader(false)
        },200)
        clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getCreatorDetail = useCallback(() => {
        //dispatch('showSpinner');
        setLoader(true)
        triggerAPI({
            url: `user/creator/get/${userId}`, method: 'get'
          }, getCreatorDetailResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[triggerAPI, getCreatorDetailResponse, dispatch, userId])

    
    const handleFollowForCollector = (collectorId, isFollowedCollector) => {
        const data = { collectorId };
        const url = isFollowedCollector ? 'user/collector-unFollow' : 'user/collector-follow';
        dispatch('showSpinner');
        triggerAPI(
            {
                url: url,
                data,
                method: 'post'
            },
            (res) => {
                dispatch('hideSpinner');
                setIsFollowed(!isFollowed)
            },
            () => {
                dispatch('hideSpinner');
            }
        );
    }
        
    const handleFollow= () => {
        if (props?.fromCollector) {
            handleFollowForCollector(profileData?.id, isFollowed);
        } else {
            const data = {
                owner: profileData?.user?.id,
            }
            const url = isFollowed ? 'user/unfollow' : 'user/follow';
            const method = isFollowed ? 'DELETE' : 'POST';
            dispatch('showSpinner');
            triggerAPI({
                url: url, method, data
            }, (res) => {
                dispatch('hideSpinner');
                setIsFollowed(!isFollowed)
            }, () => {
                dispatch("hideSpinner");
            });
        }
    }
    const bannerImg = useMemo(() => {
      if (profileData) {
        return profileData?.bannerPicUrl ?? userBanner;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData?.bannerPicUrl]);

    const profileImg = useMemo(() => {
        if (profileData) {
            setTimeout(()=>{
                setLoader(false)
            },200)
          return profileData?.profilePicUrl ?? LogoAndName();
        }else{
            setLoader(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [profileData?.profilePicUrl]);

    return (
        <>
         {(loader || props?.fetchingDataFromParent) ? <div className={`sm:flex sm:flex-col hidden relative ${props?.loaderClass}`}>
          <DetailsSkeltonCard
                show={true}
                height={"16.3125em"}
                count={1}
                inline={true}
                details={"banner-profile-img"}
                noAbsolute
                parentDiv={"banner-profile-skeleton"}
                containerClassName={`flex mt-0 banner-profile-skeleton-banner`}
                />
                <div className="w-full flex flex-col px-[1rem] -bottom-[40%] absolute">
                    <div className="w-full mb-5">
                        <SkeletonLoader className={`w-[10%] mr-[90%] h-5 rounded-lg`} count={1} />
                    </div>
                    <SkeletonLoader className={`w-[100%] h-2 rounded-lg`} count={1} />
                    <SkeletonLoader className={`w-[40%] mr-[60%] h-2 rounded-lg`} count={1} />
                </div>
          </div> :
        <div className={`${props.className} top-panel-container  flex-col`}>
            <div className={`${!profileData?.bannerPicUrl && 'no-icon'} top-banner relative w-full flex flex-col justify-end`}
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(13, 15, 17, 1) 80%, rgba(13, 15, 17, 1) 100%), url(${bannerImg})`
            }}>  
                        {props.isImageTag ?
                            <img
                                alt={`no-icon`}
                                src={profileData?.profilePicUrl ?? LogoAndName()}
                                className={`${!profileData?.profilePicUrl && 'no-icon'}  fix_pixelated-image absolute`}
                            />
                            :
                            <div className={`${!profileData?.profilePicUrl && 'no-icon'} profile-icon absolute`} style={{ backgroundImage: `url(${profileImg})` }}>
                            </div>
                        }

                <div className='profile-details flex justify-between items-center'>
                    <div className='profile-data flex gap-[2.14286rem] items-center'>
                        <div className='profile-name'>
                            <span>{profileData?.name}</span>
                        </div>
                        {(isLoggedIn && (currentUserId !== profileData?.user?.id) && (props?.fromCollector ? currentUserId !== profileData?.id : true) && props?.self) &&
                            <div className='flex follow-section'>
                                <div className='follow-btn-container' onClick={handleFollow}>
                                    <span>{isFollowed ? 'Following' : 'Follow'}</span>
                                </div>
                            </div>
                        }
                    {(props?.fromOther && !props?.self) && 
                        <div className='flex follow-section' onClick={props?.editClick}>
                            <div className='follow-btn-container'>
                                <span>{`Edit`}</span>
                            </div>
                        </div>
                    }
                    </div>
                    <div className='flex gap-[0.625rem] items-center'>
                    {profileData?.twitter &&
                     <a href={appendUserName(profileData?.twitter, 'twitter')} target='_blank' rel='noreferrer' className='flex items-center w-[0.75rem] h-[0.8125rem]' ><img src={twitterIcon} alt='twitter' /></a>}
                        {profileData?.discord && 
                     <a href={appendUserName(profileData?.discord, 'discord')} rel='noreferrer' target='_blank' className='flex items-center h-[1rem] w-[1rem]'>
                        <img src={discordIcon} alt='discord' />
                    </a> 
                    }
                    {profileData?.youtube && 
                    <a href={appendUserName(profileData?.youtube, 'youtube')} rel='noreferrer' target='_blank' className='flex items-center h-[1rem] w-[1rem]' >
                        <img src={youtubeIcon} alt='' />
                    </a> 
                    }
                    {profileData?.instagram &&
                     <a href={appendUserName(profileData?.instagram, 'instagram')} target='_blank' rel='noreferrer' className='flex items-center h-[1rem] w-[1rem] opacity-80' >
                            <img src={instagramIcon} alt='instagram'/>
                    </a>}
                    {profileData?.website &&
                     <a href={profileData?.website} target='_blank' rel='noreferrer' className='flex items-center h-[1rem] w-[1rem]' >
                            <img src={websiteIcon} alt='website'/>
                    </a>}
                    </div>
                </div>
            </div>
            <div className='profile-description'>
            {!!profileData?.description ?
                <div className='description'>
                        {profileData?.description && <>{`${profileData?.description?.length < 300?profileData?.description:`${profileData?.description?.slice(0, 300)}...`}`} 
                            {!!props?.learnMoreLink&&<span className="view-less clickable-links" data-id={props?.learnMoreLink} onClick={props.setSelectedTab}>&nbsp;Learn More </span>}</>}
                </div> : 
                <div className='description'>
                  <span>No description</span>  
                </div>}
            </div>
        </div>}
        </>
    )
}
export default DiscoverTopPanel;