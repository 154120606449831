/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Main.module.scss";
import Header from "../Header";
import NavBar from "App/Components/Navbar";
import RoutesMain from "../../MainRoutes";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Toaster from "App/Components/Ui/Toaster";
import Spinner from "App/Components/Ui/Spinner";
import { useStore } from "App/hooks-store/store";
import useAuth from "App/hooks/use-auth";
import homeMenu from "Assets/Icons/TopMenu/home-icon-menu.svg";
import userInfo from "Assets/Icons/TopMenu/userInfo.svg";
import wallet from "Assets/Icons/TopMenu/mob-wallet.svg";
import { categorySort, scrollToTop } from "App/Helper/utilities";
import { useLocation, useNavigate } from "react-router-dom";
import useScreenSize from "App/hooks/use-screen-size";
import collectionIcon from 'Assets/Icons/my-collection-icon.svg'
import collectorsIcon from 'Assets/Icons/collectors-icon.svg'
import useHttp from "App/hooks/use-http";
import displayIcon from 'Assets/Icons/display-icon.svg'
import Twitter from "Assets/Icons/x.svg";
import Youtube from "Assets/Icons/utube.svg";
import Discord from "Assets/Icons/w-discord.svg";
import activites from "Assets/Icons/activities.svg";
import Read from 'Assets/Icons/read-icon.svg'
import Listen from 'Assets/Icons/listen-icon.svg'
import Community from 'Assets/Images/user-group.svg';
import company from "Assets/Images/cur8-sm.svg";
import knowledgeBase from "Assets/Images/knowledge.svg";
import monet from "Assets/Images/monet-img.png";
import anywall from "Assets/Images/anywall.png";
import artist from 'Assets/Icons/Art.svg';
import onchain from "Assets/Icons/onchain-link.svg";
import photographer from "Assets/Icons/Photography.svg";
import music from "Assets/Icons/music.svg";
import profile from "Assets/Icons/profile.svg";
import createAccount from "Assets/Icons/indication.svg";
import splashIcon from "Assets/Images/splash-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getColelctionSpinner } from "App/ReduxStore/Slicers/spinSlice";
import MusicPlayer from "App/Components/MusicPlayer";
import Draggable from 'react-draggable';
import UserGuidePopup from "App/Components/Ui/UserGuidePopup";
import PageLoader from "App/Components/PageLoader";
import TemporaryRoutes from "App/MainRoutes/TemporaryRoutes";
import FloatingCreateGallery from "./Components/Status/FloatingCreateGallery";
import storage from "App/Helper/storage";
import { setCurrentFormValues, setFormInitiated } from "App/ReduxStore/Slicers/gallerySlice";
import { getInitial } from "App/Helper/formValidation";
const Main = () => {
  const isLoggedIn = useAuth()[0];
  const { pathname } = useLocation();
  const headerRef = useRef();
  const sideMenuRef = useRef();
  const [sidebarTransitionCompleted,setSidebarTransitionCompleted] = useState(true)
  const collectionSpinner = useSelector(getColelctionSpinner);
  const navigate = useNavigate();
const dispatchRedux = useDispatch();
  const { triggerAPI } = useHttp();
  const [
    {
      fullPageLoader,
      spinnerMode,
      toast,
      leftMenuCollapse,
      currentWallet,      
      collections, 
      collectionLoadingStatus,
      collectionWalletData    
    },
    dispatch,
  ] = useStore();
  const screenSize = useScreenSize()?.width;
  const [category, setCategory] = useState([]);
  const [walletHasNFTs, setwalletHasNFTs] = useState(false);  
  const [galleryCount,setGalleryCount] = useState(null);
  const [allowedCountToCreateGallery,setAllowedCountToCreateGallery] = useState(null);
  const handleB2CLogin = useAuth()[6];    
  useEffect(() => {          
    setwalletHasNFTs(!!currentWallet?.NFTs?.length);          
}, [currentWallet])
  const getCategoryResponse = useCallback(
    (res) => {
      const cateList = categorySort([...res?.data?.list])
      setCategory(cateList);
    },
    [dispatch]
  );

  const token = storage().get('token');

  const getGalleryResult = useCallback((res) => {
    const { count } = res.data;
    setGalleryCount(()=>count ? Number(count) : count)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  const getGalleryList = useCallback(() => {
   
    if(token) {
  

            triggerAPI({
                url: `gallery/user/count`, method: 'get'
            }, getGalleryResult);
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [getGalleryResult, triggerAPI])


const getProfileResult = useCallback((res,callback) => {
  dispatch('setGalleryCreateCount', res?.data?.availableGalleriesCount);
  setAllowedCountToCreateGallery(res?.data?.availableGalleriesCount);
  if(res?.data?.availableGalleriesCount > galleryCount){
    callback && callback(true)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch])

const getProfile = (callback) => {
  triggerAPI({
      url: `user/profile/get`, method: 'get'
  }, (res)=>getProfileResult(res,callback));
}


useEffect(()=>{
if(((!!(currentWallet?.address) || isLoggedIn) && token) || pathname.includes("my-galleries/view")){
  getGalleryList();
  getProfile();
}
},[token,currentWallet?.address,isLoggedIn,pathname]);

const showCreateGalleryFloatingButton= useMemo(()=>{
  if(!pathname?.includes("my-galleries/add")&&!pathname?.includes("my-galleries/edit")){
if(galleryCount!==null&&allowedCountToCreateGallery!==null&&collectionWalletData?.length){
  if(allowedCountToCreateGallery > galleryCount) {
    return true
  }
  return false
}
return false
  }
  return false
},[galleryCount,allowedCountToCreateGallery,pathname,collectionWalletData])

const getCategoryList = useCallback(() => {
    triggerAPI(
      {
        url: `category/get`,
        data: {
          page: 1,
          items: 1000,
          filter: {},
          search: "",
        },
        method: "post",
      },
      getCategoryResponse
    );
  }, [dispatch, getCategoryResponse, triggerAPI]);

useEffect(()=>{
    if (!pathname?.includes("displaygallery"))
    {
      getCategoryList()
    }    
},[pathname])
  const navElements = useMemo(()=>{
    
  let categoryNav= category?.filter(el=>el?.name?.toLowerCase()!=="other")?.map((val,i)=>{
    let link={
      id: 1000+i,
      element: val?.name,
      link: `explore/${val?.slug}`,
      icon: val?.icon,
      activeLinkBase:`discover/collection/${val?.slug}/details`
    }
    if(i===0){
      link.header= "Explore"
    }
    return link
  })    
  let fSet=[];
  let sSet=[];    
  let resources = []
  let partners = []
  let links = []

  if (process.env.REACT_APP_COMPANY === 'anywall' )
  {
    fSet = [{
        id: 1,
        element: "Home",
        link: "home",
        icon: homeMenu,
      },     
      // {
      //   id: 2,
      //   element: "Manage",
      //   header: "My Collection",
      //   icon: collectionIcon,
      //   walletCheck:true,
      //   link: "curate/collections/my-collections",      
      // },  
      
      // {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",      
      // },    
      {
        id: 7,
        element: "Display",
        header: "My Collection",
        //divider:true,
        icon: displayIcon,
        link: isLoggedIn?"curate/display/my-galleries":"curate/display",
        activeLinkBase:"curate/display"
      },   
      // {
      //   id: 40,
      //   element: "Activities",
      //   link: "curate/activities/posts",
      //   icon: activites,
      //   activeLinkBase:"curate/activities"
      // },   
    ]  

    if(!isLoggedIn) {  // removing tabs except display and adding login when the user is not logged in
   
      fSet=fSet.filter((el)=>el.element==="Home");
      fSet.splice(1,0, {
        id: 2,
        element: "Login/Sign up",
        header: "My Collection",
        icon: profile,
        method: handleB2CLogin,
      })
    }
    if(!isLoggedIn && walletHasNFTs)  { // adding collection  if the user is not logged in but connected wallet
        fSet.splice(2,0,{
          id: 3,
          element: "Manage",
          icon: collectionIcon,
          walletCheck:true,
          link: "curate/collections/my-collections",
        })
    }

    sSet = []               
    //   {
    //     id: 2003,
    //     header: "Discover",
    //     element: "Artists",
    //     link: "discover/artists",
    //     icon: artist,
    //   },      
    //   {
    //     id: 2005,
    //     element: "Photographers",
    //     link: "discover/photographers",
    //     icon: photographer,
    //   }
    // ]
    resources = [
      {
        id: 2008,
        header: "Resources",
        element: "Company Site",
        link: "https://anywall.io",
        icon: anywall,
        newTab:true
      },
      {
        id: 2009,
        element: "Knowledge Base",
        link: "knowledge-base",
        icon: knowledgeBase,
        checkFullUrl:true
      }      
    ]
    
    links = [
      {
        id: 2012,
        header: "Follow Us",
        verticalAlign:true,
        linkLists:[
          { id:1,
            link: "https://twitter.com/AnyWall_Art",
            icon: Twitter,
            newTab:true
          }
        ]      
      }
    ]
  }
  else
  {
    fSet=[{
        id: 1,
        element: "Home",
        link: "home",
        icon: homeMenu,
      },
      // {
      //   id: 1,
      //   element: "Collections",
      //   link: "collections/my-collections",
      //   icon: collectionIcon,
      // },
      // {
      //   id: 2,
      //   element: "Galleries",
      //   link: "curate/my-galleries",
      //   icon: curateSvg,
      // },
      // {
      //   id: 3,
      //   element: "Announcements",
      //   link: "announcements",
      //   icon: announcementsIcon,
      // },
      // {
      //   id: 4,
      //   element: "Events",
      //   link: "events",
      //   icon: eventsSvg,
      // },
      // {
      //   id: 5,
      //   element: "Home",
      //   link: "home",
      //   icon: homeMenu,
      // },
      // {
      //   id: 6,
      //   element: "Collections",
      //   link: "collections/my-collections",
      //   icon: collectionIcon,
      // },
      {
        id: 2,
        element: "Manage",
        header: "My Collection",
        icon: collectionIcon,
        walletCheck:true,
        activeLinkBase:"curate/frame-shop",
        link: "curate/collections/my-collections",
        // subNav: [
        //   {
        //     element: "My Collections",
        //     link: "collections/my-collections",
        //   },
        //   {
        //     element: "My Galleries",
        //     link: "curate/my-galleries",
        //   },
        //   {
        //     element: "Frames",
        //     link: "frame-shop",
        //   },
        // ],
      },
     
      // {
      //   id: 11,
      //   element: "CollectionV2",
      //   icon: collectionIcon,
      //   link: "collections/collectionsv2",
      //   // subNav: [
      //   //   {
      //   //     element: "My Collections",
      //   //     link: "collections/my-collections",
      //   //   },
      //   //   {
      //   //     element: "My Galleries",
      //   //     link: "curate/my-galleries",
      //   //   },
      //   //   {
      //   //     element: "Frames",
      //   //     link: "frame-shop",
      //   //   },
      //   // ],
      // },
      {
        id: 7,
        element: "Display",
        // header: "Play",
        //divider:true,
        icon: displayIcon,
        link: isLoggedIn?"curate/display/my-galleries":"curate/display",
        activeLinkBase:"curate/display"
      },
      //  {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",
      //   // subNav: [
      //   //   {
      //   //     element: "Collections",
      //   //     link: "discover/collections",
      //   //   },
      //   //   {
      //   //     element: "Creators",
      //   //     link: "discover/creators",
      //   //   },
      //   //   {
      //   //     element: "Collectors",
      //   //     link: "discover/collectors",
      //   //   },
      //   // ],
      // },
      // {
      //   id: 3,
      //   element: "Galleries",
      //   icon: curateMenu,
      //   walletCheck:true,
      //   link: "curate/my-galleries",
      //   // subNav: [
      //   //   {
      //   //     element: "Collections",
      //   //     link: "discover/collections",
      //   //   },
      //   //   {
      //   //     element: "Creators",
      //   //     link: "discover/creators",
      //   //   },
      //   //   {
      //   //     element: "Collectors",
      //   //     link: "discover/collectors",
      //   //   },
      //   // ],
      // },
      // {
      //   id: 4,
      //   element: "Frames",
      //   link: "curate/frame-shop",
      //   icon: framesIcon,
      // },
      // {
      //   id: 5,
      //   element: "Posts",
      //   link: "curate/posts",
      //   walletCheck:true,
      //   // externalLink: true,
      //   icon: postsIcon,
      // },
      // {
      //   id: 6,
      //   element: "Events",
      //   link: "curate/events",
      //   walletCheck:true,
      //   // externalLink: true,
      //   icon: eventsSvg,
      // },
    
      // {
      //   id: 7,
      //   element: "Display",
      //   // header: "Play",
      //   divider:true,
      //   icon: displayIcon,
      //   link: "display",
      // },
      {
        id: 9,
        element: "Listen",
        link: "listen/collections/my-collections",
        icon: Listen,
        walletCheck:true
      },
      {
        id: 8,
        element: "Read",
        link: "read/collections/my-collections",
        icon: Read,
        walletCheck:true      
      },
      {
        id: 40,
        element: "Activities",
        link: "curate/activities/posts",
        icon: activites,
         activeLinkBase:"curate/activities"
      },
      // {
      //   id: 5,
      //   element: "Community",
      //   icon: communityMenu,
      //   link: "",
      //   // section: true,
      //   subNav: [
      //     {
      //       element: "Announcements",
      //       link: "announcements",
      //     },
      //     {
      //       element: "Events",
      //       link: "events",
      //     },
      //   ],
      // },]
    ]  

    if(!isLoggedIn) {  // removing tabs except display and adding login when the user is not logged in

      fSet=fSet.filter((el)=>el.element==="Home");
      fSet.splice(1,0, {
        id: 2,
        element: "Login/Sign up",
        header: "My Collection",
        icon: profile,
        method: handleB2CLogin,
      })
    }
    if(!isLoggedIn && walletHasNFTs)  { // adding collection  if the user is not logged in but connected wallet
        fSet.splice(2,0,{
          id: 3,
          element: "Manage",
          icon: collectionIcon,
          walletCheck:true,
          link: "curate/collections/my-collections",
        })
    }

    sSet = [
      //   {
      //   id: fSet?.length+categoryNav?.length+1,
      //   element: "All",
      //   link: "discover/collections",
      //   icon: allSvg,
      //   header: "Explore"
      // },
   
      {
        id: 2002,
        header: "Discover",
        element: "All Communities",
        link: "discover/all-communities",
        icon: Community,
      },
      {
        id: 2003,
        element: "Artists",
        link: "discover/artists",
        icon: artist,
      },
      {
        id: 2004,
        element: "Onchain",
        link: "discover/onchain",
        icon: onchain,
      },
      {
        id: 2005,
        element: "Photographers",
        link: "discover/photographers",
        icon: photographer,
      },
      {
        id: 2006,
        element: "Musicians",
        link: "discover/musicians",
        icon: music,
      },
      {
        id: 2007,
        element: "Collectors",
        link: "discover/collectors",
        icon: collectorsIcon,
      },
      
      // {
      //   id: 2011,
      //   element: "Information",
      //   link: "/information-desk",
      //   icon: infoIcon,
      // },
      // {
      //     id: 5,
      //     element: "Recycler",
      //     link: "https://portal.theartbank.io/recycle",
      //     externalLink: true,
      //     icon: recyleMenu
      // },
    ]
    resources = [
      {
        id: 2008,
        header: "Resources",
        element: "Company",
        link: process.env.REACT_APP_COMPANY_URL,
        icon: company,
        newTab:true
      },
      {
        id: 2009,
        element: "Knowledge Base",
        link: "knowledge-base",
        icon: knowledgeBase,
        checkFullUrl:true
      },
      {
        id: 20009,
        element: "Create an Account",
        link: "knowledge-base/create-an-account",
        icon: createAccount,
        checkFullUrl:true
      }
    ]
    partners = [
      {
        id: 2010,
        header: "Partners",
        element: "Monet Society",
        link: "https://www.splash.club/organization/the-monet-society",
        icon: monet,
        newTab:true
      },
      {
        id: 2011,
        element: "Anywall",
        link: "https://www.anywall.io",
        icon: anywall,
        newTab:true
      },
      {
        id: 20111,
        element: "Splash",
        link: "https://www.splash.club",
        icon: splashIcon,
        newTab:true
      }
    ]
    links = [
      {
        id: 2012,
        header: "Follow Us",
        verticalAlign:true,
        linkLists:[
          { id:1,
            link: "https://twitter.com/Cur8Labs",
            icon: Twitter,
            newTab:true
          },
          {
            id:2,
            link: "https://www.youtube.com/@cur8galleries",
            icon: Youtube,
            newTab:true
          },{
            id:3,
            link: "https://discord.com/invite/vHqfQ5vPFd",
            icon: Discord,
            newTab:true
          },
        ]      
      }
    ]
  }
  // if (!(collections?.filter(c=>c.categoryId===17).length))
  // {
  //   fSet=fSet.filter((el)=>el.element!=="Read")
  // }
  // if (!(collections?.filter(c=>c.categoryId===18).length))
  // {
  //   fSet=fSet.filter((el)=>el.element!=="Listen")
  // }
  if (process.env.REACT_APP_COMPANY === 'anywall')
  {    
    //categoryNav = categoryNav.filter(el=>el.element?.toLowerCase()==='art' || el.element?.toLowerCase()==='photography');    
    categoryNav = [];
  }
   return [
    ...fSet,...categoryNav,...sSet,...resources,...partners,...links    
  ]},[category, isLoggedIn, walletHasNFTs, collections])

  const [navExpand, setNavExpand] = useState(false);
  const [mainNavNotExpanded, setMainNavNotExpanded] = useState(false);
  const [sidebarNotPinned, setSibebarNotPinned] = useState(false);
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [mobileNavElements, setMobileNavElements] = useState([])
  
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      let navEl = navElements;
      let fIndex = navEl.findIndex((el) => el.element === "Community");
      navEl.splice(fIndex);
      if (isLoggedIn) {
        navEl = [
          ...navEl,
          {
            id: 10,
            header: true,
            element: "Collectors",
            link: "discover/collectors",
            icon: collectorsIcon,
          },
          {
            id: 11,
            element: "User",
            subNav: [
              {
                element: "Collectors",
                link: "collectors/profile/my-galleries",
              },
              {
                element: "Creators",
                link: "community",
              },
            ],
            icon: userInfo,
          },
          {
            id: 12,
            element: "Wallets",
            subNav: [
              {
                element: "Manage",
                link: "collectors/wallet-authentication",
              },
              // {
              //   element: "Refresh",
              //   link: "",
              // },
            ],
            icon: wallet,
          },
        ];
      }
      setMobileNavElements(navEl);
    } 
    // else {
    //   let el = navElements;
    //   // let fIndex = el.findIndex((ele) => ele.element === "Collectors");
    //   // el.splice(fIndex + 1, 2);

    //   el = [
    //     ...el,
    //   ];

    //   setNavElements(el);
    // }
  }, [isPortrait, screenSize, isLoggedIn]);
  
  useEffect(() => {
    if (isPortrait && screenSize <= 550) {
      document.getElementById("mainContent").style.filter = navExpand
        ? `grayscale(100%)`
        : "none";
    }else{
      document.getElementById("mainContent").style.filter =  "none";
    }
  }, [navExpand,isPortrait,screenSize]);

  const navExpandHandler = () => {
    setTimeout(() => {
      scrollToTop();
    }, 1000);
    setNavExpand((prevState) => {
      return !prevState;
    });
  };
  const mainNavExpandHandler = () => {
   if(sidebarNotPinned){
    setMainNavNotExpanded((prevState) => {
      return !prevState;
    });
   }
  };
  
  const mainNavExpandHandleShow = () => {
    setMainNavNotExpanded(false);
  };
  useEffect(() => {
    dispatch("setLeftMenuCollapse", mainNavNotExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainNavNotExpanded]);
  const checkFilter = useMemo(() => {
    return (
      Number(document.getElementById("header-bar")?.offsetHeight || 0) > 60
    );
  }, [document.getElementById("header-bar")?.offsetHeight]);
  const updateHeight = useCallback(() => {
    const root = document.documentElement;
    if (headerRef.current) {
      root?.style.setProperty(
        "--top-menu-height",
        `${headerRef.current.offsetHeight}px`
      );
    }
    if (sideMenuRef.current) {
      let sideWidth = sideMenuRef.current.offsetWidth;
      root?.style.setProperty("--right-menu-width", `${sideWidth}px`);
    }
  }, [leftMenuCollapse]);
  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener(
      "resize",
      () => {
        setTimeout(() => {
          updateHeight();
        }, 1000);
      },
      false
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      updateHeight();
    }, 1500);
  }, [leftMenuCollapse]);

  useEffect(() => {
    setNavExpand(false);
  }, []);

  const onPinSidebarHandler=()=>{
    setSibebarNotPinned((pre)=>!pre)
  }

//   useEffect(()=>{
// let mainNav=document.getElementById("sidebar");
// if(mainNavNotExpanded){
//   mainNav.style.position="static"
// }else {
//   mainNav.style.position="relative"
// }
//   },[mainNavNotExpanded])
const mouseEnteronSidebarHandler = () => {
  if(sidebarNotPinned&&sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return false;
    });
    let timer= setTimeout(()=>{
      setSidebarTransitionCompleted(true)
    },500)
    return ()=>clearTimeout(timer)
   }
}
const mouseLeavefromSidebarHandler = () => {
  if(sidebarNotPinned&&sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return true;
    });
    let timer= setTimeout(()=>{
      setSidebarTransitionCompleted(true)
    },500)
    return ()=>clearTimeout(timer)
   }else if(sidebarNotPinned&&!sidebarTransitionCompleted){
    setSidebarTransitionCompleted(false)
    setMainNavNotExpanded(() => {
      return true;
    });
      setSidebarTransitionCompleted(true)
   }
}
const createdGalleryButtonDisabled= useMemo(()=>{
if(collectionLoadingStatus!=="finished"){
  return true
}
return false
},[collectionLoadingStatus])

const clearAll = useCallback(() => {
  dispatch('setCurrentGalleryUpdateId', '');
  dispatch('setCurrentSelectedNfts', [])
  dispatch('setCurrentList', [])
  dispatch('setCurrentGallery', {})
  dispatch('setCurrentFormValues', getInitial({}))
  dispatchRedux(setCurrentFormValues(getInitial({})))
  return ()=>{
     
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
const createGalleryButtonHandler=()=>{
  getProfile((status)=>{
     if(status&&!createdGalleryButtonDisabled){
            navigate("/curate/display/my-galleries/add/collection");
            dispatchRedux(setFormInitiated(true));
            clearAll();
     }
  });
}
  return (
    <>
    <div className="sm:hidden block h-full">
      <TemporaryRoutes/>
    </div>
    <div className={`flex-col h-full sm:flex hidden`}>
      <Spinner show={!!spinnerMode || !!collectionSpinner} className="spinner" size={150} />
      <Toaster {...toast} />
      <UserGuidePopup/>
      {fullPageLoader && <PageLoader className={styles['full-page-loader']} />}
      <div
        className={`header-wrapper flex items-center relative ${
          navExpand && "grayscale"
        }`}
        ref={headerRef}
      >
        <Header
          onHamburgerClick={navExpandHandler}
          className={styles["menu-bar"]}
        />
      </div>
      <div className={`${styles["content-area-height"]} content-area`}>
        <div className={`${styles["min-content-height"]} flex h-full relative`}>
          <><div
            className={`flex h-full flex-col ${styles["nav-bar"]} nav-bar ${
              styles["desktop-nav"]
            } ${mainNavNotExpanded ? styles["active"] : ""}`}
            ref={sideMenuRef}
            onMouseEnter={mouseEnteronSidebarHandler}
            onMouseLeave={mouseLeavefromSidebarHandler}
          >
            <NavBar
              classList=" border-y-0 h-full"
              NavTitle="Home"
              mainNavNotExpanded={mainNavNotExpanded}
              sidebarNotPinned={sidebarNotPinned}
              onMainNavCollapse={mainNavExpandHandler}
              Elements={navElements}
              isMainNav={true}
              setSibebarNotPinned={setSibebarNotPinned}
              onPinSidebarHandler={onPinSidebarHandler}
              mainNavExpandHandleShow={mainNavExpandHandleShow}
            ></NavBar>
            {/* <div className="footer-wrapper flex flex-1  items-center">
              <Footer mainNavExpand={mainNavExpand} />
            </div> */}
          </div>
          <div
            onClick={navExpandHandler}
            className={`${styles["nav-overlay"]} ${
              navExpand && styles["nav-active"]
            }`}
          ></div>
          <div
            className={`flex h-full flex-col ${styles["nav-bar"]} ${
              styles["mobile-nav"]
            } ${navExpand && styles["nav-active"]} ${
              isLoggedIn ? styles["nav-in"] : styles["nav-out"]
            }`}
          >
            <NavBar
              onNavCollapse={navExpandHandler}
              classList=" border-y-0 h-full"
              NavTitle="Home"
              Elements={mobileNavElements}
            ></NavBar>
          </div>
          </>               
          <div
            className={`${styles["content"]} ${
              pathname.includes("rewards") || pathname.includes("purchase")
                ? styles["pt-4-3"]
                : ""
            } ${!checkFilter && styles["filter-less"]} ${
              mainNavNotExpanded ? styles["active"] : ""
            }`}
            id="mainContent"
          >
            <RoutesMain />
            {process.env.REACT_APP_COMPANY !== 'anywall' && !!collections?.filter(c=>c.categoryId === 18).length && 
              <Draggable>
                <div className={`${styles["draggable-music-icon"]} ${styles["floating-section-wrapper"]} ${!showCreateGalleryFloatingButton&&"!right-[1.25rem]"} floating-section-wrapper`}>
                <MusicPlayer toplevel={true}></MusicPlayer>
                </div>
              </Draggable>
              
            }
            {showCreateGalleryFloatingButton&&<div className={`${styles["create-gallery-icon"]} ${styles["floating-section-wrapper"]} floating-section-wrapper`}>
              <FloatingCreateGallery disabled={createdGalleryButtonDisabled} onClickHandler={createGalleryButtonHandler}/>
            </div>}
            {/* {!hideAlphaMessage && (
              <div className={`${styles["footer-alpha-warning"]}`}>
                <img
                  className={`${styles["info-icon"]}`}
                  src={infoIcon}
                  alt="info icon"
                />
                <div>
                  Our site is in alpha testing. All collector and creator
                  accounts will be retained, but other data may be lost during
                  the beta migration. Thank you for your patience.
                </div>
                <img
                  src={closeIcon}
                  onClick={hideAlphaMessageHandler}
                  alt=""
                  className={`${styles["close-icon"]} cursor-pointer`}
                />
              </div>
            )}
            )} */}
          </div>
        </div>
      </div>
      
      </div>
      </>
  );
};
export default Main;
