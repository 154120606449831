import { useEffect, useState } from "react"
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import useShapes from "App/hooks/use-shapes";
import websiteIcon from 'Assets/Icons/social/websiteIcon.svg'
import Twitter from "Assets/Icons/social/Twitter.svg";
import Youtube from "Assets/Icons/social/YouTube.svg";
import Discord from "Assets/Icons/social/Discord.svg";
import instagramIcon from 'Assets/Icons/social/Instagram.svg'
import { appendUserName } from "App/Helper/utilities";

const DetailsGalleryTab = props => {
    const [shapes, setShapes] = useState(shapesEmpty);
    const {getShapes } = useShapes();
    useEffect(() => {
        let ratios = props?.galleryDetails?.Shapes?.map(s=>s.aspectRatio);
        setShapes(getShapes(ratios)); 
      }, [props?.nft?.Shapes]);
    
    return (<div className="flex flex-col items-start p-[1rem] pt-2 overflow-auto">
    <div className="flex flex-col  pb-[1.5rem] text-[#B8C0CC] text-[.875rem]  font-normal leading-normal tracking-[.0175rem]">Description</div>
    <div className="flex flex-col  border-[#191D23] border-b pb-[1.5rem] text-[#F7F8F9] text-[.875rem]  font-normal leading-normal tracking-[.0175rem] text-left w-[100%] whitespace-pre-wrap overflow-x-hidden break-all">{props?.galleryDetails?.description || 'No description'}</div>
    <div className="flex flex-col  py-[1.5rem]  text-[#B8C0CC] text-[.875rem]  font-normal leading-normal tracking-[.0175rem]">Information</div>
    <div className="flex flex-col gap-2">
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right text-[.875rem]">Pieces</div>
            <div className="text-[.875rem]">{props?.galleryDetails?.assets?.length}</div>
        </div>
        <div className="flex gap-4">
            <div className="w-[5rem] text-[#B8C0CC] text-right text-[.875rem] ">Shapes</div>
            <div className={`flex`}>
                <img
                  className={`w-[1.5rem] shapes-img`}
                  alt="Img"
                  src={shapes}
                />
              </div>
        </div>
        <div className="flex gap-4 items-center">
        <div className="w-[5rem] text-[#B8C0CC] text-right items-center text-[.875rem]">Socials</div>
            <div className="flex gap-2 flex-wrap items-center ">
            {props?.communityData?.twitter ? (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.communityData?.twitter, 'twitter')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Twitter} alt="images" />
                </a>
                </div>
            )
            :
            (props?.galleryDetails?.userData || props?.galleryDetails?.profileData?.twitter) && (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.galleryDetails?.userData?.twitter || props?.galleryDetails?.profileData?.twitter, 'twitter')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Twitter} alt="images" />
                </a>
                </div>
            )
        }
            {props?.communityData?.discord ? (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.communityData?.discord, 'discord')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Discord} alt="images" />
                </a>
                </div>
            )
            :
           ( props?.galleryDetails?.userData?.discord || props?.galleryDetails?.profileData?.discord) && (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.galleryDetails?.userData?.discord || props?.galleryDetails?.profileData?.discord, 'discord')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Discord} alt="images" />
                </a>
                </div>
            )}
            {props?.communityData?.youtube ? (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.communityData?.youtube, 'youtube')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Youtube} alt="images" />
                </a>
                </div>
            ) :
            (props?.galleryDetails?.userData?.youtube || props?.galleryDetails?.profileData?.youtube) && (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.galleryDetails?.userData?.youtube || props?.galleryDetails?.profileData?.youtube, 'youtube')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={Youtube} alt="images" />
                </a>
                </div>
            )}
            {props?.communityData?.instagram ? (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.communityData?.instagram, 'youtube')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={instagramIcon} alt="images" />
                </a>
                </div>
            ) :
            (props?.galleryDetails?.userData?.instagram || props?.galleryDetails?.profileData?.instagram) && (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={appendUserName(props?.galleryDetails?.userData?.instagram || props?.galleryDetails?.profileData?.instagram, 'instagram')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={instagramIcon} alt="images" />
                </a>
                </div>
            )}

              {props?.communityData?.website && (
                <div>
                <a
                    className="flex gap-1 items-center cursor-pointer"
                    href={props?.communityData?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img className='w-[1rem]' src={websiteIcon} alt="images" />
                </a>
                </div>
            )}
            </div>
        </div>  
    </div>
 </div>)
}

export default DetailsGalleryTab