import { initStore } from "./store"


const configureStore = () => {
    const actions = {
        setProfilePic: (current) => {
            return {
                profilePic: !current.profilePic
            }
        },
        setUserId: (current, data) => {
            return {
                currentUserId: data
            }
        },
        setGalleryCreateCount: (current, count) => {
            return {
                galleryCreateCount: count
            }
        },
        setSubscriptionLimitReached: (current, status) => {
            return {
                subscriptionLimitReached: status
            }
        },
        setPremiumGalleryWallEnabled: (current, status) => {
            return {
                premiumGalleryWallEnabled: status
            }
        },
        setAnyWallDisplayGalleryCount: (current, count) => {
            return {
                anyWallDisplayGalleryCount: count
            }
        },
    }
    initStore(actions, {
        profilePic: false,
        currentUserId: 0,
        anyWallDisplayGalleryCount:0,
        galleryCreateCount: -1,
        subscriptionLimitReached:false,
        premiumGalleryWallEnabled:false
    });
}
export default configureStore;