import "./subscription.scss";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useCallback } from "react";
import useHttp from "App/hooks/use-http";
import SubscriptionCard from "./Components/SubscriptionCard";
import monentLgIcon from "Assets/Icons/monet-lg-icon.svg";
import { useStore } from "App/hooks-store/store";
import { useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "App/Helper/utilities";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const Subscription = () => {
  const [currentTab, setCurrentTab] = useState("Monet Members");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStatus = searchParams.get("status");
  const [plans, setPlans] = useState([]);
  const [totalMonet, setTotalMonet] = useState(0);
  const [apiCount, setAPICount] = useState(0);
  const [totalMonetWidth, setTotalMonetWidth] = useState(0);
  const [showPaymentMsg, setShowPaymentMsg] = useState(false);
  const [skeltonLoader, setSkeltonLoader] = useState(true);
  // eslint-disable-next-line
  const [undefned, dispatch] = useStore(false);
  // const tabs = ["Collectors", "Monet Members"];
  const tabs = ["Monet Members"];

  const { triggerAPI } = useHttp();

  const selectSubscription = useCallback(
    (subscriptionPlanId) => {
      dispatch("showSpinner");
      const data = {
        subscriptionPlanId: subscriptionPlanId,
      };
      triggerAPI(
        {
          url: `user/subscription-plan`,
          data,
          method: "post",
        },
        (ressub) => {
          const responce = ressub?.data;
          window.location.replace(responce.invoice_url);

          //dispatch("hideSpinner");
        }
      );
    },
    [dispatch, triggerAPI]
  );
  const connectedSubscriptionPlanResult = useCallback(
    () => {
      //setTotalMonet(600000);
      triggerAPI(
        {
          url: `user/subscription-plan`,
          method: "get",
        },
        (ressub) => {
          const plans = ressub?.data?.subscriptionPlans;
          setPlans(plans);
          dispatch("hideSpinner");
          setSkeltonLoader(false)
          const total = ressub?.data?.totalMonet;
          setAPICount((pre)=>pre+1)
          setTotalMonet(total);
        }
      );
    },
    [triggerAPI, dispatch]
  );

  useEffect(()=>{
    if (apiCount===1 && totalMonet > 10000) {
      setCurrentTab(`Monet Members`);
    }
  },[apiCount,totalMonet])

  useEffect(() => {
    connectedSubscriptionPlanResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  useEffect(() => {
    if (totalMonet > 0) {
      let xWidth = (totalMonet * 100) / 900000;
      setTotalMonetWidth(xWidth);
    }
  }, [totalMonet]);
  useEffect(() => {
    if (!showPaymentMsg) {
      setShowPaymentMsg(true);
      searchParams.delete("status");
      setSearchParams(searchParams);
      if (searchStatus) {
        if (searchStatus === "success") {
          dispatch("showToast", {
            toast: {
              toastMode: "success",
              message: capitalizeFirstLetter("Thank you for subscribing!"),
            },
          });
        } else if (searchStatus === "failed") {
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message: capitalizeFirstLetter("Payment Failed"),
            },
          });
        }
      }
    }
  }, [searchStatus, dispatch, showPaymentMsg, searchParams, setSearchParams]);
  let percentageFinder=useMemo(()=>{
if(parseInt(totalMonet)>900000){
  return 100
}else{
  return (parseInt(totalMonet)/900000)*100
}
  },[totalMonet])
  return (
    <div className="subscriptionmaindiv sm:gap-0 flex flex-col gap-[1rem] sm:mb-0 mb-[2.5rem]">
      <div className="title text-center sm:block hidden">
        <h3>Subscription Plans</h3>
      </div>
      <div className="flex subscription-tab">
        {tabs &&
          tabs.map((tab) => (
            <div key={tab}>
              <button
                onClick={() => setCurrentTab(tab)}
                className={["tab-btn", currentTab === tab ? "active" : ""].join(
                  " "
                )}
              >
                {tab}
              </button>
            </div>
          ))}
      </div>
      {currentTab === "Monet Members" && (
        <div className="monetprogress">
          <label className="title">Monet Holdings</label>
          <div className="progress-div">
            <label>0</label>
            <div className="totalMonetdiv">
              <label
                // style={{
                //   width:
                //     totalMonetWidth > 5
                //       ? `${totalMonetWidth - 5}%`
                //       : `${totalMonetWidth}%`,
                // }}
                style={{left:`${percentageFinder}%`}}
                className="totalMonetLabel flex gap-1 w-full items-center"
              >
                <img src={monentLgIcon} className="token-icon"  alt="icon"/>{parseInt(totalMonet).toLocaleString()}
              </label>
              <progress id="file" value={totalMonet} max="900000"></progress>
            </div>
            <label>900,000</label>
          </div>
        </div>
      )}
       {currentTab !== "Monet Members" && ( 
        <div className="h-[5rem]"></div>
       )}
      <div
        className={[
          "flex Subscriptionlist",
          currentTab === "Monet Members" ? "hidemargin" : "",
        ].join(" ")}
      >
         {skeltonLoader ? <div className="flex gap-[1.5rem]">
          {[1,2,3].map((loader)=><SkeletonLoader key={loader} className={`h-[35rem] w-[23rem]`} count={1}/>)}
          </div>  :
        plans.map((plan) => (
          <SubscriptionCard
            selectSubscription={(subscriptionPlanId) =>
              selectSubscription(subscriptionPlanId)
            }
            currentTab={currentTab}
            plan={plan}
            key={plan.id}
          />
        ))}
      </div>
      {currentTab === "Monet Members" && (
        <p className="block about-monet max-w-[71rem] m-auto">
          Monet is the loyalty and rewards program of the Monet Society. Monet
          Members enjoy benefits and priveleges when using the Cur8 platform and
          with other Monet Society Network partners. To learn more about the
          Monet Society and the Monet Membership program, please
          visit <a
        href="https://monetsociety.io/"
        rel="noreferrer"
        target="_blank"
        className="monet_link"
      >
       The Monet Society
      </a>.
        </p>
      )}
      <a
        href="https://www.monetsociety.io/membership"
        rel="noreferrer"
        target="_blank"
        className="sm:hidden block learn-more clickable-links"
      >
        Learn More
      </a>
    </div>
  );
};
export default Subscription;
