import { useState, useEffect } from "react";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";
import './MetaSettings.scss';
import CustomImage from "App/Components/Ui/CustomImage";
import { metaWallListData } from "App/Helper/utilities";
const MetaSettings = () => {
    const metWallList = metaWallListData();    
    const spinnerShow = false;
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const [image, setImage] = useState(metWallList?.find(item => item?.id === currentForm?.metaBg)?.imageUrl256);
    const onClickHandler = (e) => {
        const newImage = e.currentTarget?.dataset?.image
        setImage(newImage)
        dispatchRedux(setCurrentFormValues({...currentForm, metaBg: Number(e.currentTarget?.dataset?.id)}));
    }
    useEffect(() => {
        if(!currentForm?.metaBg) {
            setImage(metWallList[0]?.imageUrl256)
            dispatchRedux(setCurrentFormValues({...currentForm, metaBg: Number(metWallList[0]?.id)}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='tab-top-container relative'>
                <div className={'opacity-100 h-full meta-preivew'} style={{backgroundImage: `url('${image}')`}}>
                </div>
            </div>
            <div className="flex flex-col items-start glry-arrang overflow-hidden gallery-preview gap-4">
                <div className="w-full background-list-wrapper mt-[0.5rem] overflow-y-auto overflow-x-hidden">
                    <div className="sm:mx-[0.875rem] mx-0 sm:pr-0 pr-[0.45rem] pb-[0.375rem] flex justify-between items-center">
                        <span className=" text-left pb-1 flex w-full background-text">Environment</span>
                    </div>
                    <div className="flex wall-style sm:mx-[0.625rem] mx-0 pt-[0.625rem] w-full ">
                        {(!spinnerShow && !metWallList?.length) && 
                            <div className="py-[2rem] flex justify-center w-full mr-[0.625rem] non-highlight-color">
                                No background walls found
                            </div>
                        }
                        {(spinnerShow) &&
                            <div className="w-full" >
                                <SkeltonCard show={spinnerShow} height="4rem" count={3} noAbsolute={true} inline={true} containerClassName={`grid w-full cursor-pointer overflow-auto sm:mr-[1.5rem] mr-0 w-full`} />
                            </div>
                        }
                        {(!spinnerShow && metWallList?.length) &&
                            <div className="grid w-full cursor-pointer overflow-auto pb-4 sm:mr-[1.5rem] mr-0" >
                                {metWallList?.map((wall, index) =>
                                    <div key={index} onClick={onClickHandler} data-image={wall?.imageUrl256} data-id={wall?.id} className={`wall-image-gallery`}>
                                        <CustomImage src={wall?.imageUrl256} className={`${currentForm?.metaBg === wall?.id ? 'add' : 'wall-border'}`} skeltonCardHeight={'4rem'} />
                                    </div>
                                )}
                            </div> 
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MetaSettings