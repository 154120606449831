/* eslint-disable react-hooks/exhaustive-deps */
import "./CollectionDetailRight.scss";
import Border from "../../../Border";
import copyIcon from "Assets/Icons/copy-icon-grey.svg";
import { useStore } from "App/hooks-store/store";
import FormatField from "App/Components/Ui/FormatField";
import userAvatar from "Assets/Images/Logo.svg";
// import twitterIcon from "Assets/Icons/social/Twitter.svg";
// import discordIcon from "Assets/Icons/social/Discord.svg";
import DownArrow from 'Assets/Icons/down-arrow.svg'
import DetailRightPanel from "../..";
import { useState, useEffect } from "react";
import Card from "App/Components/Card";
import adaIcon from "Assets/Icons/ada_svg_grey.svg";
import shapesEmpty from "Assets/Images/shapesEmpty.svg";
import useShapes from "App/hooks/use-shapes";
import useHttp from 'App/hooks/use-http'
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";
import { formatLargeNumber, numberWithCommas } from "App/Helper/utilities";

const CollectionDetailRight = (props) => {
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [shapes, setShapes] = useState(shapesEmpty);
  const { getShapes } = useShapes();
  const [
    {              
        filterData      
    },
    dispatch,
  ] = useStore(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    let clearTimer;
    if(!!props?.selectedCollection){
      clearTimer = setTimeout(()=>setLoader(false), 1200);
    }
    return ()=> clearTimeout(clearTimer)
  }, [props?.selectedCollection]);


  const copyClipHandler = () => {
    let textArea = document.createElement("textarea");
    textArea.value = props?.selectedCollection?.collectionId;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = ".09px";
    textArea.style.opacity = "0";

    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    // dispatch("showToast", {
    //   toast: { toastMode: "success", message: "Copied to clipboard" },
    // });
    document.body.removeChild(textArea);
  };

  const truncateText = (text, maxWords) => {
    if (!text) return ''; 
  
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '... ';
    }
    return text;
  };


  function formatDate(dateString) {
    if (isNaN(new Date(dateString))) {
      return "-";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  useEffect(() => {    
    let ratios = props?.selectedCollection?.shapes?.length ? props?.selectedCollection?.shapes.map(shape => Number(shape.aspectRatio)) : props?.selectedCollection?.aspectRatios?.split(','); 
    setShapes(getShapes(ratios));
  }, [props?.selectedCollection])
  const showBannerHandler = ()=>{
    let bannerImg= props?.selectedCollection?.community ? props?.selectedCollection?.filePath256 : props?.selectedCollection?.showcase || false;
    return bannerImg
  }
  return (
    <DetailRightPanel>
        <div className= {` pt-4 bg-[#0D0F11] w-full`}>
      <div className= {` sm:flex gap-4  collection-details-right`}>
                
                    {props?.isCollection ? 
                    <img src={props?.selectedCollection?.community ? props?.selectedCollection?.filePath256 : props?.selectedCollection?.showcase || userAvatar} alt='collection' className={`h-[11rem] aspect-square rounded-md ${showBannerHandler()?"object-cover":"object-contain"}`}/>
                    :
                    <div className="h-[11rem] aspect-square rounded-md">
                    <Card
                    cardProp={props.selectedCollection}
                    type="Gallery"
                    preview={true}
                    hideDetails={props.hideDetails}
                    className='no-info'
                    handlePreview={props?.handlePreview}
                  />
                  </div>
                 }
                <div className={` ${props.className} collection-detail-container`}>
                    <div className='flex justify-between'>
                        <div>
                            <div className='collection-title sm:mt-0 mt-4 flex gap-[1rem] items-end'>
                                <span>{props?.selectedCollection?.name}</span>

                             {props?.selectedCollection?.community?.name &&   <div className='items-end'>
                                    <p className="community-name ">by {props?.selectedCollection?.community?.name}</p>
                            </div>}
                            </div>
                            <Border className={'right-panel-v-border'} />
                        </div>
                        <div className='flex gap-[0.625rem] items-start'>
                        {/* <img
                        src={backIcon}
                        onClick={props.backHandler}
                        alt="back-icon"
                        className="cursor-pointer"
                        /> */}
                        {!props?.hideCloseOptn&&                   
                        <div className="clickable-links"
                        style={{
                          
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          textDecorationLine: "underline",
                          cursor: "pointer",}} onClick={props.backHandler}>
                            {props?.isCollection ? 'All Collections' : 'All Galleries'}
                            </div>}
                        {/* {props?.selectedCollection?.community.twitter && <a href={props?.selectedCollection?.community.twitter} target='_blank' rel='noreferrer' ><img src={twitterIcon} alt='' className=' ' /></a>}
                            {props?.selectedCollection?.community.discord && 
                        <a href={props?.selectedCollection?.community.discord} rel='noreferrer' target='_blank' >
                            <img src={discordIcon} alt='discord'  className=''/>
                        </a> 
                        } */}
                        {/* {profileData?.youtube && 
                        <a href={profileData?.youtube} rel='noreferrer' target='_blank' >
                            <img src={youtubeIcon} alt='' />
                        </a> 
                        } */}
                        </div>
                    </div>
                    <div className='collection-attributes-container flex flex-col'>
                        <div className='collection-attributes flex w-full max-h-[3.5rem]'>

                            {/* Floor box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Floor</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      {props?.selectedCollection?.metrics[0]?.floorPrice ? <span className={`flex items-center gap-1`}> <img
                                          className={`common_image_style`}
                                          alt="Img"
                                          src={adaIcon}
                                      /> {props?.selectedCollection?.metrics[0]?.floorPrice}</span> : <p className="data-value">-</p>}
                                  </div>)}
                              </div>
                              {/* Volume box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Volume</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      {props?.selectedCollection?.metrics[0]?.allVolume ? <span className={`flex items-center gap-1`}> <img
                                          className={`common_image_style`}
                                          alt="Img"
                                          src={adaIcon}
                                      /> {formatLargeNumber(props?.selectedCollection?.metrics[0]?.allVolume) ?? '-'}</span> : <p className="data-value">-</p>}
                                  </div>)}
                              </div>
                              {/* Pieces box */}
                              <div className={`common_coll__data-box`}>
                                  <p className={`data-title`}>Pieces</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value flex`}>
                                      {numberWithCommas(props?.selectedCollection?.metrics[0]?.supply ?? props?.selectedCollection?.assetCount) ?? "-"}
                                  </div>)}
                              </div>
                              {/* Owners box */}
                              {props?.isCollection &&
                                  <div className={`common_coll__data-box`}>
                                      <p className={`data-title`}>Owners</p>
                                      {loader ? (
                                          <SkeletonLoader height={15} count={1} inline={true} />
                                      ) : (<div className={`data-value flex`}>
                                          {numberWithCommas(props?.selectedCollection?.metrics[0]?.owners ?? props?.selectedCollection?.ownerCount) ?? "-"}
                                      </div>)}
                                  </div>
                              }
                              {/* Shapes box */}
                              <div className={`common_coll__data-box data-box-last`}>
                                  <p className={`data-title`}>Shapes</p>
                                  {loader ? (
                                      <SkeletonLoader height={15} count={1} inline={true} />
                                  ) : (<div className={`data-value`}>
                                      <img
                                          className={`shapes_box-style`}
                                          alt="Img"
                                          src={shapes}
                                      />
                                  </div>)}
                              </div>

                              {/* Collection id box */}
                              {/* <div className={`common_coll__data-box`}>
                                  <div className={`data-title`}>Collection ID</div>
                                  <div className='value'>
                                      {props?.selectedCollection?.collectionId &&
                                          <div className="data-value flex gap-1 items-center">
                                              <img
                                                  className="common_image_style"
                                                  alt="Img"
                                                  src={adaIcon}
                                              />
                                              <div className="value pt-[.16rem]">
                                                  <FormatField type="substring" maxLength={20} startLength={4} endLength={4} value={props?.selectedCollection?.collectionId} />
                                              </div>
                                              <img
                                                  onClick={copyClipHandler}
                                                  className="cursor-pointer common_image_style"
                                                  alt="Img"
                                                  src={copyIcon}
                                              />
                                          </div>
                                      }
                                  </div>
                              </div> */}
                            {/* {props.isCollection &&  */}
                            {/* <> */}
                            {/* <Border className={'right-panel-v-border'} /> */}
                            {/* <div className='attribute-section flex flex-col'>
                                <div className='lable'>Formats</div>
                                <div className='value'>{filterData?.collectionFormat?.filter(f=>props?.selectedCollection?.formats?.split(',')?.indexOf(f.id.toString())>-1)?.map(f=>f.name).join('/') ?? 'NA'}</div>
                                <div className='value'>{props?.selectedCollection?.formats?.map(f=>f.name).join('/') ?props?.selectedCollection?.formats?.map(f=>f.name).join('/'): 'NA'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Types</div>
                                <div className='value'>{filterData?.collectionType?.filter(t=>props?.selectedCollection?.types?.split(',')?.indexOf(t.id.toString())>-1)?.map(f=>f.name).join('/') ?? 'NA'}</div>
                                <div className='value'>{props?.selectedCollection?.types?.map(f=>f.name).join('/') ?props?.selectedCollection?.types?.map(f=>f.name).join('/'): 'NA'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Editions</div>
                                <div className='value'>{props?.selectedCollection?.collectionEditions?.name ?? 'NA'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Storage</div>
                                <div className='value'>{props?.selectedCollection?.collectionStorage?.name ?? 'NA'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Terms</div>
                                <div className='value'>{props?.selectedCollection?.collectionTerms?.liscenceName ?? 'NA'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Mint Date</div>
                                <div className='value'>{props?.selectedCollection?.mintDate?formatDate(props?.selectedCollection?.mintDate):'-'}</div>
                            </div>
                            <Border className={'right-panel-v-border'} />
                            <div className='attribute-section flex flex-col'>
                                <div className='lable'>Lock Date</div>
                                <div className='value'>{props?.selectedCollection?.lockDate?formatDate(props?.selectedCollection?.lockDate):'None'}</div>
                            </div> */}
                            {/* </>} */}
                        </div>
                    </div>
                    <div className='collection-description'>
                        {props?.selectedCollection?.description ?
                            <>
                                {truncateText(props.selectedCollection.description, 50)}..
                                <span 
                                    className="learn-more-link ml-[.5rem] clickable-links" 
                                    onClick={() => props?.setActiveTab("tab2")}
                                >
                                    Learn More
                                </span>
                            </>
                            : <></>
                            // <SkeletonLoader height={'3rem'} count={1}/>
                        }
                    </div>
            {/* <div className='w-full bottom-[1.42857rem]'>
                        <div className='open-btn w-full'>
                            <RoundedButton OnClick={() => {}} Name="Buy" Type="7" />
                        </div>
                    </div> */}
               </div>
            </div>
            </div>
        </DetailRightPanel>
)}


export default CollectionDetailRight;