import ImagePopup from "App/Components/Ui/ImagePopup"
import { getSplice } from "App/Helper/utilities"
import { useStore } from "App/hooks-store/store"
import { useState } from "react"
import { useEffect } from "react"
// import { ClipLoader } from "react-spinners"
import GalleryPreviewImage from "../AddedNfts/Components/GalleryPreviewImage"
import "./GalleryScreenshot.scss"
import { useSelector } from "react-redux"
import { getCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice"
import PageLoader from "App/Components/PageLoader"

const GalleryScreenshot = (props) => {
    const [sliderValue, setSliderValue] = useState([])
    const [screenshotFrame, setScreenshotFrame] = useState({});
    const [layoutSelected, setLayoutSelected] = useState(null);
    const [{ currentSelectedNfts, currentFrameDetails, layoutData }] = useStore(false);
    const currentForm = useSelector(getCurrentFormValues)
    const onScreenCapture = (e) => {
        setScreenshotFrame((prev) => {
            return {
                ...prev,
                ...e
            }
        })
    }
    const emitScreenshotFiles = (screenshotFrame) => {
        props?.screenCapture(screenshotFrame)
    }
    useEffect(() => {
        if (currentSelectedNfts?.length) {
            let sliceCount = layoutSelected ? layoutSelected?.count : 8;
            const result = getSplice(currentSelectedNfts, sliceCount)
            setSliderValue([result[0]])
        }
    }, [currentSelectedNfts, layoutSelected])
    useEffect(() => {
        setLayoutSelected(props?.show ? layoutData?.find(item=>item.id === currentForm?.layoutId) : null);        
    }, [props?.show, layoutData, currentForm?.layoutId])


    useEffect(() => {
        emitScreenshotFiles(screenshotFrame)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenshotFrame])
    
    return (
        <ImagePopup show={props?.show}
            cssClass="screenshot-popup relative" onClose={props?.onClose} >
            <div className=" max-w-[28rem] h-full w-full" >            
                {[...sliderValue]?.map((el, i) => {
                    return <div key={i} >
                        <GalleryPreviewImage loadSmallSize={true} frameDetails={currentFrameDetails} clearCache={false}
                            screenshotFrame={screenshotFrame}
                            selectedLayout={layoutSelected}
                            setScreenshotFrame={setScreenshotFrame} fileName={`file${i}`}
                            onScreenCapture={onScreenCapture} layoutType='Card-8' layoutCount={el?.length}
                            src={props?.src}
                            className={props?.className} selectedNfts={el} imageRatioCalculated={props?.imageRatioCalculated} />
                    </div>
                })}
                <PageLoader />
            </div>
        </ImagePopup >

    )
}

export default GalleryScreenshot