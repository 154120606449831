import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allUserCollection: [],
    currentView:""
}

export const userCollectionSlice = createSlice({
  name: 'userCollection',
  initialState,
  reducers: {
    setAllUserCollection: (state, action) => {
        state.allUserCollection = [...state?.allUserCollection, ...action.payload];
    },
    setcurrentView: (state, action) => {
      state.currentView = action.payload;
  },
  },
})

// Action creators are generated for each case reducer function
export const { setAllUserCollection ,setcurrentView} = userCollectionSlice.actions;
export const getAllUserCollection = ({userCollection}) => userCollection?.allUserCollection;
export const getcurrentView = ({userCollection}) => userCollection?.currentView;
export default userCollectionSlice.reducer;