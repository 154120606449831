import { removeDuplicateByElement } from "App/Helper/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setNftDrop: (current, nftDrop) => {
            let currentData = current?.nftDrop ?? [] ;
            const data = [...currentData, ...nftDrop.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                nftDropLoaded: 0,
                nftDropLoading: nftDrop.loading,
                nftDrop: finalData,
                nftDropCount: nftDrop.count
            }
        },
        setNftDropAssetsList: (current, assetsList) => {
            return {
                collectionAssetList: assetsList,

            }
        },
        updateNftDropCount: (current) => {
            if (current.nftDropLoaded < current.nftDropCount)
            return {
                nftDropLoaded: current.nftDropLoaded + 1
            }
        },
        updateNftDropLoadingStatus: (current,status) => {
            return {
                nftDropLoadingStatus: status
            }
        },         
        updateNftDropRawData: (current,data) => {
            return {
                nftDropRawData: data
            }
        },        
        updateNftDropAssetCount: (current, data) => {
            return {
                nftDropAssetCount: data
            }
        }, 
        reFetchNftDrop: (current) => {
            return {
                reFetchNftDrop:  {mode:true}
            }
        },
        favNftToggle: (current, object) => {
            let find = current.collections.find(collec => collec.policy === object.policy)
            if (find)
                find.isCollectionFavourite = object.value
            return {
                collections: [...current.collections]
            }
        },
        favNftAssetToggle: (current, object) => {
            let find = current.collections.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                collections: current.collections
            }
        }
    }
    initStore(actions, {
        collectionAssetList: [],
        nftDropLoading: false,
        nftDropLoaded: 0,
        nftDrop: [],
        nftDropRawData: [],
        nftDropAssetCount: 0,
        nftDropCount: 0,
        nftDropLoadingStatus:'idle',
        reFetchNftDrop: {mode:false}
    });
}
export default configureStore;