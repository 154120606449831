/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "App/hooks-store/store";
import { useLocation } from "react-router-dom";
import PreviewDummy from "Assets/Images/PreviewDummy.svg";
import GalleryImages from "App/Components/Ui/GalleryFrameImages";
import { Fragment, useEffect, useMemo, useState } from "react";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import Card from "App/Components/Ui/Card";
import GalleryPreviewSelection from "App/Pages/NftGallery/Component/GalleryPreviewSelection";
import SliderGallery from "App/Components/Ui/SliderGallery";
import UserDetails from "../../../UserDetails";
import ReleaseDetails from "../../../ReleaseDetails";
import PreviewGallery from "../..";
import useSwipe from "App/Components/Swipe";
import useScreenSize from "App/hooks/use-screen-size";
import SkeletonLoader from "App/Components/Ui/SkeletonLoader";

const PreviewDetailRight = (props) => {
  const screenSize = useScreenSize()?.width;
  const { currentTabSelected } = useStore(false)[0];
  const [selectedNft, setSelectedNft] = useState([]);
  let tabsGallery = ["Gallery"];
  let tabsRelease = ["Overview", "Release Details"];
  const { pathname } = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [tabSelected, setTabSelected] = useState(
    props?.galleryType === 1 || currentTabSelected === "My Creations"
      ? tabsRelease[0]
      : tabsGallery[0]
  );
  const onTabChange = (e) => {
    setSelectedNft([]);
    setTabSelected(typeof e === "string" ? e : e?.currentTarget?.dataset.tab);
  };
  const handlePreview = (id, previewType) => {
    const type =
      pathname.includes("home") || pathname.includes("/new-releases")
        ? props?.galleryType
        : currentTabSelected === "My Creations"
        ? 1
        : 0;
    if (previewType === "3d") {
      if (props?.gallery) {
        const data = new URLSearchParams({
          id: props?.galleryUpdateId,
          type,
          page: "meta",
        });
        window.open(`/preview-gallery?${data}`, "_blank");
      }
    } else if (previewType === "2d") {
      if (props?.gallery) {
        const data = new URLSearchParams({
          id: props?.galleryUpdateId,
          type: type,
        });
        window.open(`/preview-gallery?${data}`, "_blank");
      }
    } else if (previewType === "2ds") {
      const data = new URLSearchParams({
        id: props?.galleryUpdateId,
        type,
        page: "multi",
      });
      window.open(`/preview-gallery?${data}`, "_blank");
    }
  };
  const onSwipedDown = () => {
    // if(screenSize<=550&& props?.type==="collectors") props?.closeDetailGallery&& props.closeDetailGallery()
  };
  const onSwipedLeft = () => {
    if (screenSize <= 550 && props?.type === "collectors")
      onTabChange("Pieces");
  };
  const onSwipedRight = () => {
    if (screenSize <= 550 && props?.type === "collectors")
      onTabChange("Gallery");
  };
  const onSwipedUp = () => {};
  const swipeHandlers = useSwipe({
    onSwipedDown,
    onSwipedLeft,
    onSwipedRight,
    onSwipedUp,
  });
  const heightUpdate =  useMemo(() => {
    const elementA = document.getElementsByClassName('gallery-preview-wall')[0];
    const height = elementA?.clientHeight;
    return `${height}px`
  }, [document.getElementsByClassName('gallery-preview-wall')[0]]);
  return (
    <div className="slider-user" {...swipeHandlers}>
      {!!(props?.spinnerShow||!props?.currentNft?.length) ? <SkeletonLoader className={`h-[11.25rem] w-[20rem]`} count={1}/>
      :<>
      <div
        className={`${
          props?.currentNft?.length > 8 ? "card-half" : "card-half-8"
        } ${
          screenSize <= 550 && props?.page === "discover" && selectedNft?.length
            ? "hidden"
            : "block"
        }`}
      >
        <SkeltonCard
          show={props?.spinnerShow}
          height="11.25rem"
          noAbsolute={true}
          inline={true}
          containerClassName={`flex h-full`}
        />
        {!props?.spinnerShow && (
          <Card height={heightUpdate?.includes("undefined")?"180px":heightUpdate} className=" preview preview-gallery preview-new-slider">
            {props?.currentNft?.length ? (
              <div className="preview-dimension">
                {props?.galleryUpdateId && (
                  <Fragment>
                    <div className="absolute content-trigger rounded-[.5rem]">
                      <GalleryPreviewSelection
                        sidebar
                        createdBy={props?.createdBy}
                        thumbnailBackground={props?.images}
                        handlePreview={handlePreview}
                        files={props?.files}
                        galleryID={props?.galleryUpdateId}
                        noOpenBtn={true}
                        metaBgImage={props?.metaThumb}
                      />
                    </div>
                  </Fragment>
                )}
               <div className="w-[20rem] h-full ">
                  <SliderGallery
                    src={
                      props?.selectedWall ? props?.selectedWall : props?.images
                    }
                    selectedLayout={props?.selectedLayout}
                    className="gallery-preview-wall flex justify-center items-center "
                    sliderClass="gallery-slider-2 h-full"
                    selectedNfts={props?.currentNft}
                  />
                </div>
              </div>
            ) : (
              <img src={PreviewDummy} alt="NoImage" />
            )}
          </Card>
        )}
      </div>
      <div
        className={`view-frame-images view-gallery-dis  flex ${
          screenSize <= 550 && props?.page === "discover" && selectedNft?.length
            ? "block"
            : "hidden"
        }`}
      >
        <div className="flex justify-center items-center view-images">
          <GalleryImages
            clearCache={false}
            isDetailedView={true}
            galleryNew={selectedNft[0]}
            src={selectedNft && selectedNft[0]?.nftcdnimage512}
            className="gallery-selected-image"
            nftGalleryNew={true}
            frameName={selectedNft[0]?.name}
          />
        </div>
      </div>
      </>
    }
      <div className="detail-wrapper">
        <div
          className={` ${
            screenSize < 551 && props?.currentNft?.length > 8 ? "" : ""
          } user-preview`}
        >
          {tabSelected === "Overview" ? (
            <UserDetails
              formData={props?.formValue}
              galleryType={props?.galleryType}
              fetchProfile={props?.viewFromOther}
            />
          ) : screenSize <= 550 &&
            props?.type === "collectors" &&
            tabSelected === "Pieces" ? (
            <PreviewGallery
              galleryUpdateId={props?.previewGallery?.data?.id}
              viewFromOther={true}
              selectedWall={""}
              type={props?.type}
              enableEdit={false}
              setSelectedNft={setSelectedNft}
              galleryType={props?.previewGallery?.data?.type}
              fromDiscover={true}
              
            />
          ) : (
            <ReleaseDetails
              showOpenBtn={props?.showOpenBtn}
              openBtnAction={props?.openBtnAction}
              page={props?.page}
              formData={props?.formValue}
              galleryType={props?.galleryType}
              fetchProfile={props?.viewFromOther}
              onClose={props?.toGallery}
              communityData={props?.communityData}
              fromCommunity={props?.fromCommunity}
              setActiveTab={props?.setActiveTab}
            />
          )}
          {screenSize < 650 && tabSelected === "Overview" && (
            <div className="nfts-selected mr-3 pt-2">
              <div className="flex added-nfts nfts-selected-box w-full">
                <SkeltonCard
                  show={props?.spinnerShow}
                  height="10.4rem"
                  count={2}
                  noAbsolute={true}
                  inline={true}
                  containerClassName={` ${
                    props?.displayChange === "lg" ? "grid-lg" : "grid"
                  }  pb-4 gap-2 scroller side-scroller h-full`}
                />

                {!props?.spinnerShow && (
                  <div
                    className={` ${
                      props?.displayChange === "lg" ? "grid-lg" : "grid"
                    }  pb-4 gap-2 scroller side-scroller`}
                  >
                    {props?.currentNft?.map((selectedNft, i) => (
                      <Fragment key={i}>
                        <div
                          className="collection-card relative flex flex-col p-1"
                          data-asset={selectedNft?.asset}
                          onClick={props?.imageOnSelect}
                        >
                          <GalleryImages
                            compress={true}
                            frameToggle={props?.frameToggle}
                            nftGalleryNew={true}
                            index={i}
                            galleryNew={selectedNft}
                            src={selectedNft?.nftcdnimage256}
                            className="nft-gallery-card-image "
                          />
                          {props?.captionToggle && (
                            <div className="flex absolute nft-selected-name ">
                              <span
                                className={`${
                                  props?.displayChange === "sm"
                                    ? "name name-sm"
                                    : "name name-lg"
                                }`}
                              >
                                {selectedNft?.name
                                  ? selectedNft?.name
                                  : "No Name"}
                              </span>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewDetailRight;