import "./TopHeader.scss";
import xLgIcon from "Assets/Icons/TopMenu/tile-xlarge-icon.svg";
import dropDown from "Assets/Icons/TopMenu/dropdown-icon.svg";
import folder from "Assets/Icons/TopMenu/folder-icon.svg";
import file from "Assets/Icons/TopMenu/file-icon.svg";
import filter from "Assets/Icons/TopMenu/filter-icon.svg";
import refresh from "Assets/Icons/TopMenu/refresh-icon.svg";
import BorderRight from "./Components/BorderRight";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useStore } from "App/hooks-store/store";
import SkeltonCard from "../Ui/SkeltonCard";
import DropdownSelect from "../Ui/DropdownSelect";
import Select from "../Ui/Select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchFilter from "../Ui/SearchFilter";
import Twitter from "Assets/Icons/social-twitter.svg";
import Youtube from "Assets/Icons/social-youtube-blue.svg";
import Discord from "Assets/Icons/social-discord.svg";
import all from "Assets/Images/all.svg";
import read from "Assets/Images/read.svg";
import unread from "Assets/Images/unread.svg";
import MultiFilter from "./Components/MultiFilter";
import UserGroupDropdown from "../Ui/UserGroupDropdown";
import ReusableMultiDropDown from "../Ui/ReusableMultiDropDown";
import useAuth from "App/hooks/use-auth";
import TileIcon from "../IconsComponents/TileIcon";
import TileLargeIcon from "../IconsComponents/TileLargeIcon";
import TileMediumIcon from "../IconsComponents/TileMediumIcon";
import TileListIcon from "../IconsComponents/TileListIcon";
import FrameIcon from "../IconsComponents/FrameIcon";
import FilterIcon from "../IconsComponents/FilterIcon";
import SortIcon from "../IconsComponents/SortIcon";
import HeartIcon from "../IconsComponents/HeartIcon";
import { useDispatch } from "react-redux";
import { setcurrentView } from "App/ReduxStore/Slicers/userCollectionSlice";
import EditIcon from "../IconsComponents/EditIcon";
import DeleteIcon from "../IconsComponents/DeleteIcon";
import CaptionIcon from "../IconsComponents/CaptionIcon";
import BackIcon from "../IconsComponents/BackIcon";
import UserGroupIcon from "../IconsComponents/UserGroupIcon";
import InformationIcon from "../IconsComponents/InformationIcon";

const { clearDB } = require("App/hooks-store/store");

const TopHeader = () => {
  const navigate = useNavigate();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [
    {
      topMenuIcons,
      showMenuIcons,
      showTopMenus,
      topMenuList,
      topActiveMenu,
      activeMenuIcons,
      menuLoading,
      topdropDownOptions,
      topSortingOptions,
      topTileWeightingOptions,
      infoDeskIcons,
      subCategoryOptions,
      enableScrollMenu,
      userGroupOptions,
      categoryListOptions      
    },
    dispatch,
  ] = useStore(false);
  const isLoggedIn = useAuth()[0]
  const { pathname } = useLocation();
  const { communityid } = useParams();
  const reduxDispatch = useDispatch();
  const isCollectionPage = pathname.includes("collections")||pathname.includes("frame-shop")||(pathname.includes("my-galleries")&&!communityid) ? true : false;
  const brdBack = topMenuIcons?.back;
  const readUnreadDisplay = topMenuIcons?.all||topMenuIcons?.unread||topMenuIcons?.read;
  // View options
  const brdDisplay =
    topMenuIcons?.lgView ||
    topMenuIcons?.smView ||
    topMenuIcons?.listView ||
    topMenuIcons?.xlgView;
    // favorites, sort search
  const brdSorting =
    topMenuIcons?.filter ||
    topMenuIcons?.filterSort ||
    topMenuIcons?.sorting ||
    topMenuIcons?.favourite ||
    topMenuIcons?.search;
    // Collection
  const brdDrop = topMenuIcons?.dropDown||topMenuIcons?.collectionRefresh;
   // File , folder
  const brdFolder = topMenuIcons?.folder || topMenuIcons?.file;
  // Caption and frame
  const brdAstOption = topMenuIcons?.caption || topMenuIcons?.frame;
// Refresh
const brdExOption = false;
// const brdExOption = topMenuIcons?.refresh;
// Delete
const brdActions = topMenuIcons?.delete || topMenuIcons?.edit;
// const brdActions = false;
  
  const handleDisplayType = (e) => {
    const displayType = e?.currentTarget?.dataset?.index;
    dispatch("setActiveIcons", { displayType,frameShop:false });
    reduxDispatch(setcurrentView("collection"))
    if(pathname?.includes("frame-shop")){
      navigate("curate/collections/my-collections");
    }
  };
  const handleFolderOption = (e) => {
    const folderOption = e?.currentTarget?.dataset?.index;
    dispatch("setActiveIcons", { folderOption });
  };
  const handleDropDown = (e) => {
    const dropDown = e?.dropDown;
    dispatch("setActiveIcons", { dropDown });
  };
  const handleUserGroup = (activeOption) => {
    dispatch("setActiveIcons", { userGroup: activeOption });
  };
  const handleCategoryDropdown = (activeIds) => {
    dispatch("setActiveIcons", { CategoryActiveIds: activeIds });
  };
  const handleFavourite = (e) => {
    dispatch("setActiveIcons", { favourite: !activeMenuIcons?.favourite });
  };
  const handleFrame = (e) => {
    dispatch("setActiveIcons", { frame: !activeMenuIcons?.frame });
  };
  const handleCaption = (e) => {
    dispatch("setActiveIcons", { caption: !activeMenuIcons?.caption });
  };
  const handleSort = (e, isMulti) => {
    if(isMulti){
      dispatch("setActiveIcons", { sort: e });
    } else {
      dispatch("setActiveIcons", { sort: e?.value });
    }
  };
  const handleWeighting = (e) => {
    dispatch("setActiveIcons", { weighting: e?.value });
  };
  const handleBackBtn = (e) => {
    dispatch("setActiveIcons", { back: true });
  };

  const onSearching = useCallback((e) => {
    setShowSearchBar(prev => !prev);
    if (showSearchBar && e?.currentTarget?.dataset?.id) {
      dispatch("setActiveIcons", { search: "" });
    } else {
      dispatch("setActiveIcons", { search: activeMenuIcons?.search });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showSearchBar, activeMenuIcons?.search]);

  const onSearch = (e) => {
    dispatch("setActiveIcons", { search: e });
  };

  const handleMenu = (e,url) => {
    const index = e?.currentTarget?.dataset?.index;
    dispatch("setActiveMenuOptions", parseInt(index));
    topMenuList?.length&&dispatch("setActiveMenuOptionsData",topMenuList[parseInt(index)]);
    if(url&&pathname!==url){
      navigate(url)
    }
  };

  const handleEdit = () => {
    dispatch("setActiveIcons", { edit: true });
  };

  const handleDelete = () => {
    dispatch("setActiveIcons", { delete: true });
  };
  
const handleRefresh = () => {
  if(!activeMenuIcons?.refresh)
    dispatch("setActiveIcons", { refresh: true });
}
const handleCollectionRefresh = async () => {
  await clearDB(true);
  window.location.reload();  
}
const handleFilterSort = useCallback((e) => {
  dispatch("setActiveIcons", { filterSort: !activeMenuIcons?.filterSort });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[activeMenuIcons?.filterSort])
const handleFilter = (e) => {
  dispatch("setActiveIcons", { filter: e });
}
const handleUserGuidePopup = (e) => {
  dispatch("setActiveIcons", { userGuide: { open: true, dynamicPath: pathname }});
};
useEffect(() => {
  setShowSearchBar(false);
}, [pathname])
useLayoutEffect(() => {
  const root = document?.documentElement;
  let height = (showMenuIcons || showTopMenus) ? 'calc(100vh - 3rem)' : '100vh';
  root?.style.setProperty(
      "--main-page-height", `${height}`
  );
}, [showMenuIcons, showTopMenus, pathname])

useEffect(()=>{
  setShowSearchBar(activeMenuIcons?.search)
},[activeMenuIcons?.search,pathname]);

const visibleUserGuideForSomePages = useMemo(()=>{
  return (pathname === `/curate/posts` || pathname === `/curate/events` || pathname ===(`/curate/collections/my-collections`))
},[pathname])

const frameShopHandler=(e)=>{
  reduxDispatch(setcurrentView("frame-shop"))
  dispatch("setActiveIcons", { frameShop: true,displayType:"frame-shop" });
  navigate("/curate/frame-shop");
}
  return (
    <div className={`${((showMenuIcons || showTopMenus ) && `top-menu-bar ${menuLoading && 'top-menu-bar-loading'}`)} 
    flex gap-[1.5rem] items-center relative ${enableScrollMenu && "scroll-header"} 
    ${(topMenuIcons?.back && enableScrollMenu)&&"scroll-header-back"} ${(!showMenuIcons && !showTopMenus && !menuLoading)? "hidden" :"h-[2rem]"}`}>
      <SkeltonCard
        show={isLoggedIn&&(menuLoading)}
        height="2.66rem"
        inline={false}
        containerClassName={` flex skelton-banner`}
      />
      {!!showMenuIcons && (
        <div className={`menu-icons flex items-center  gap-[1.5rem]   ${menuLoading && 'opacity-0'}`}>
          {brdBack && (
            <>
              <div className="back flex gap-4 cursor-pointer">
                {topMenuIcons?.back && (
                  // <img src={back} alt="icon" className="img-icon" onClick={handleBackBtn} />
                  <BackIcon onClick={handleBackBtn} />
                )}
              </div>
              {((brdDisplay &&
                (topMenuIcons?.lgView ||
                  topMenuIcons?.smView ||
                  topMenuIcons?.xlgView ||topMenuIcons?.frameShop||
                  topMenuIcons?.listView)) ||
                (brdDrop && (topMenuIcons?.dropDown||topMenuIcons?.collectionRefresh)) ||
                (brdFolder && (topMenuIcons?.folder || topMenuIcons?.file)) ||
                (brdAstOption &&
                  (topMenuIcons?.frame || topMenuIcons?.caption)) ||
                (brdSorting &&
                  (topMenuIcons?.favourite ||
                    topMenuIcons?.filter ||
                    topMenuIcons?.filterSort ||
                    topMenuIcons?.sorting ||
                    topMenuIcons?.search)) ||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh)  || (topMenuIcons?.userGuidePopup) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons)))&& <BorderRight />}
            </>
          )}
               {readUnreadDisplay && (
            <>
              <div className={`display-types flex sm:gap-[0.75rem] gap-[1rem] cursor-pointer active-icons ${pathname==="/curate/posts"&&"w-[56%] sm:w-auto"}`}>
                {topMenuIcons?.all && (
                  <img
                    src={all}
                    className={
                      `img-icon ${activeMenuIcons?.displayType === "all" ? "activeIcon" : ""}`
                    }
                    alt="icon"
                    onClick={handleDisplayType}
                    data-index={"all"}
                  />
                )}
                {topMenuIcons?.unread && (
                  <img
                    src={unread}
                    className={
                      `img-icon ${activeMenuIcons?.displayType === "unread" ? "activeIcon" : ""}`
                    }
                    alt="icon"
                    onClick={handleDisplayType}
                    data-index={"unread"}
                  />
                )}
                {topMenuIcons?.read && (
                  <img
                    src={read}
                    className={
                      `img-icon ${activeMenuIcons?.displayType === "read" ? "activeIcon" : ""}`
                    }
                    alt="icon"
                    onClick={handleDisplayType}
                    data-index={"read"}
                  />
                )}
              </div>
              { readUnreadDisplay  && <BorderRight />}
            </>
          )}
          {brdDisplay && (
            <>
               <div className={`display-types items-center flex sm:gap-[.5714rem] gap-[1rem] cursor-pointer active-icons`}>
         
                {topMenuIcons?.lgView && (
                  // <img
                  //   src={lgIcons}
                  //   className={
                  //     `img-icon ${activeMenuIcons?.displayType === "lg" ? "activeIcon" : ""}`
                  //   }
                  //   alt="icon"
                  //   onClick={handleDisplayType}
                  //   data-index={"lg"}
                  // />
                  <TileLargeIcon onClick={handleDisplayType}
                  dataIndex={"lg"}
                  className={`${activeMenuIcons?.displayType === "lg" ? "activeIcon" : ""}`} />
                )}
                {topMenuIcons?.smView && (
                  // <img
                  //   src={smIcons}
                  //   className={
                  //     `img-icon ${activeMenuIcons?.displayType === "sm" ? "activeIcon" : ""}`
                  //   }
                  //   alt="icon"
                  //   onClick={handleDisplayType}
                  //   data-index={"sm"}
                  // />
                  <TileMediumIcon onClick={handleDisplayType}
                  dataIndex={"sm"}
                  className={`${activeMenuIcons?.displayType === "sm" ? "activeIcon" : ""}`} />
                )}
                {topMenuIcons?.xlgView && (
                  <img
                    src={xLgIcon}
                    className={
                      `img-icon ${activeMenuIcons?.displayType === "xlg" ? "activeIcon" : ""}`
                    }
                    alt="icon"
                    onClick={handleDisplayType}
                    data-index={"xlg"}
                  />
                )}
                {topMenuIcons?.chartView && (
                  // <img
                  //   src={chartIcon}
                  //   className={
                  //     `img-icon ${activeMenuIcons?.displayType === "tile" ? "activeIcon" : ""}`
                  //   }
                  //   alt="icon"
                  //   onClick={handleDisplayType}
                  //   data-index={"tile"}
                  // />
                  <TileIcon onClick={handleDisplayType}
                     dataIndex={"tile"}
                     className={`${activeMenuIcons?.displayType === "tile" ? "activeIcon" : ""}`} />
                )}
                {topMenuIcons?.listView && (
                  // <img
                  //   src={listIcon}
                  //   className={
                  //     `img-icon ${activeMenuIcons?.displayType === "list" ? "activeIcon" : ""}`
                  //   }
                  //   alt="icon"
                  //   onClick={handleDisplayType}
                  //   data-index={"list"}
                  // />
                  <TileListIcon onClick={handleDisplayType}
                  dataIndex={"list"}
                  className={`${activeMenuIcons?.displayType === "list" ? "activeIcon" : ""}`} />
                )}
                {
                 topMenuIcons?.frameShop && ( <FrameIcon dataIndex={"frameShop"} onClick={frameShopHandler}
                  className={`${activeMenuIcons?.frameShop ? "activeIcon" : ""}`} />
                )}
              </div>
              {((brdDrop && (topMenuIcons?.dropDown||topMenuIcons?.collectionRefresh)) ||
                (brdFolder && (topMenuIcons?.folder || topMenuIcons?.file)) ||
                (brdAstOption &&
                  (topMenuIcons?.frame || topMenuIcons?.caption)) ||
                (brdSorting &&
                  (topMenuIcons?.favourite ||
                    topMenuIcons?.filter ||
                    topMenuIcons?.filterSort ||
                    topMenuIcons?.sorting ||
                    topMenuIcons?.search))|| (topMenuIcons?.userGuidePopup) ||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {brdDrop && (
            <>
              <div className="dropdown-types flex gap-4 cursor-pointer mt-[2px] sm:mt-0">
                {topMenuIcons?.dropDown && (
                  <div className="">
                    <DropdownSelect
                      showCount={isCollectionPage}
                      controlName="dropDown"
                      image={dropDown}
                      walletSorting={topMenuIcons?.walletSorting}
                      mainClassName="col-gal-select"
                      className="font-13 text-[#B8C0CC] pl-[.1875rem]"
                      borderLess
                      onChange={handleDropDown}
                      value={activeMenuIcons?.dropDown}
                      options={topdropDownOptions}
                      sortDisable={topMenuIcons?.walletDisable}
                    >
                    </DropdownSelect>
                  </div>
                )}
              </div>
         {!!topMenuIcons?.collectionRefresh && <div className="dropdown-types flex gap-4 cursor-pointer mt-[2px] sm:mt-0">
               
                  <div className="">                                
                    <img
                    src={refresh}
                    alt="icon"
                    className={`img-icon`}
                    onClick={handleCollectionRefresh}                    
                  />
                  </div>
                
              </div>}
              {((brdFolder && (topMenuIcons?.folder || topMenuIcons?.file)) ||
                (brdAstOption &&
                  (topMenuIcons?.frame || topMenuIcons?.caption)) ||
                (brdSorting &&
                  (topMenuIcons?.favourite ||
                    topMenuIcons?.filter ||
                    topMenuIcons?.filterSort ||
                    topMenuIcons?.sorting ||
                    topMenuIcons?.search)) || (topMenuIcons?.userGuidePopup )||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {brdFolder && (
            <>
              <div className="file-types flex sm:gap-[0.42857rem] gap-[1rem] cursor-pointer active-icons">
                {topMenuIcons?.folder && (
                  <img
                    src={folder}
                    alt="icon"
                    className={
                      `img-icon ${activeMenuIcons?.folderOption === "Collections" ? "activeIcon" : ""}`
                    }
                    onClick={handleFolderOption}
                    data-index={"Collections"}
                  />
                )}
                {topMenuIcons?.file && (
                  <img
                    src={file}
                    alt="icon"
                    className={
                      `img-icon ${activeMenuIcons?.folderOption === "Assets" ? "activeIcon" : ""}`
                    }
                    onClick={handleFolderOption}
                    data-index={"Assets"}
                  />
                )}
              </div>
              {((brdAstOption &&
                (topMenuIcons?.frame || topMenuIcons?.caption)) ||
                (brdSorting &&
                  (topMenuIcons?.favourite ||
                    topMenuIcons?.filter ||
                    topMenuIcons?.filterSort ||
                    topMenuIcons?.sorting ||
                    topMenuIcons?.search)) || (topMenuIcons?.userGuidePopup)||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {brdAstOption && (
            <>
              <div className="flex sm:gap-[0.42857rem] gap-[1rem] items-center cursor-pointer active-icons caption-controls">
                {topMenuIcons?.frame && (
                  // <img
                  //   src={frame}
                  //   alt="icon"
                  //   className={
                  //     `img-icon ${activeMenuIcons?.frame  ? "activeIcon" : ""}`
                  //   }
                  //   onClick={handleFrame}
                  // />
                  <FrameIcon onClick={handleFrame}
                  className={`${activeMenuIcons?.frame ? "activeIcon" : ""}`} />
                )}
                {topMenuIcons?.caption && (
                  // <img
                  //   src={caption}
                  //   alt="icon"
                  //   className={
                  //     `img-icon ${activeMenuIcons?.caption  ? "activeIcon" : ""}`
                  //   }
                  //   onClick={handleCaption}
                  // />
                  <CaptionIcon className={`${activeMenuIcons?.caption  && "activeIcon"}`} onClick={handleCaption}/>
                )}
              </div>
              {((brdSorting &&
                (topMenuIcons?.favourite ||
                  topMenuIcons?.filter ||
                  topMenuIcons?.filterSort ||
                  topMenuIcons?.sorting ||
                  topMenuIcons?.search)) || (topMenuIcons?.userGuidePopup)||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {/* User group (Follow filter) */}
          {(topMenuIcons?.filterCategoryDropdown ||topMenuIcons?.userGroup ) &&<> {topMenuIcons?.userGroup && isLoggedIn && <UserGroupDropdown onChange={handleUserGroup}  imageIcon={UserGroupIcon} options={userGroupOptions} visibility={topMenuIcons?.userGroup}/>}
          {/* Filter for Posts and Events Category */}
          {topMenuIcons?.filterCategoryDropdown && isLoggedIn && <ReusableMultiDropDown onChange={handleCategoryDropdown}  imageIcon={FilterIcon} options={categoryListOptions} visibility={topMenuIcons?.filterCategoryDropdown}/>}
          {((brdSorting &&
                (topMenuIcons?.favourite ||
                  (topMenuIcons?.filter && isLoggedIn) ||
                  topMenuIcons?.filterSort ||
                  topMenuIcons?.sorting ||
                  (topMenuIcons?.search && isLoggedIn))) || (topMenuIcons?.userGuidePopup && isLoggedIn)||
                (brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
          </>}
          {brdSorting && (
            <>
              <div className={`sorting-types flex sm:gap-[1.5rem] gap-[1.35rem] items-center cursor-pointer h-full active-icons ${pathname.includes("discover")?"w-full sm:w-auto":pathname==="/posts"&&"w-[20%] sm:w-auto"}`}>
                {topMenuIcons?.favourite && (
                  // <img
                  //   src={favourite}
                  //   alt="icon"
                  //   className={`img-icon ${activeMenuIcons?.favourite ? "activeIcon" : ""} ${topMenuIcons?.favDisable ? 'disabled' : ''}`}
                  //   onClick={!topMenuIcons?.favDisable ? handleFavourite : () => {}}
                  // />
                  <HeartIcon onClick={!topMenuIcons?.favDisable ? handleFavourite : () => {}}
                  className={`img-icon ${activeMenuIcons?.favourite ? "activeIcon" : ""} ${topMenuIcons?.favDisable ? 'cursor-not-allowed' : ''}`} />
                )}
                                {/* Fav */}
                {topMenuIcons?.filterSort && 
                // <img src={filter} onClick={handleFilterSort}
                //   className={`img-icon ${activeMenuIcons?.filterSort ? "activeIcon" : ""}`} alt="icon" />
                  <FilterIcon onClick={handleFilterSort}
                  className={`img-icon ${activeMenuIcons?.filterSort ? "activeIcon" : ""}`} />
                  }
                {topMenuIcons?.filter && <MultiFilter image={filter} selectedValue={activeMenuIcons?.filter ?? {}} options={subCategoryOptions} onSelected={handleFilter} />}
                {/* Ffilter */}
                {(topMenuIcons?.sorting || topMenuIcons?.sortFilter )&& (
                  <>
                    <Select
                      imageIcon={topMenuIcons?.sortFilter?FilterIcon:SortIcon}
                      disableSort={topMenuIcons?.sortingDisable}
                      mainClassName={`top-sort-select  w-auto non-filter-icon ${topMenuIcons?.sortFilter&&"filter-box"}`}
                      className="font-12 text-[#B8C0CC] pl-[.1875rem]"
                      borderLess
                      onChange={handleSort}
                      value={activeMenuIcons?.sort}
                      options={topSortingOptions}
                    />
                  </>
                )}
                {(topMenuIcons?.tileWeighting)&& (
                  <>
                    <Select
                      imageIcon={topMenuIcons?.sortFilter?FilterIcon:SortIcon}
                      disableSort={topMenuIcons?.sortingDisable}
                      mainClassName={`top-sort-select  w-auto non-filter-icon ${topMenuIcons?.sortFilter&&"filter-box"}`}
                      className="font-12 text-[#B8C0CC] pl-[.1875rem]"
                      borderLess
                      onChange={handleWeighting}
                      value={activeMenuIcons?.weighting}
                      options={topTileWeightingOptions}
                    />
                  </>
                )}
                {/* sorting */}
                {topMenuIcons?.search && (
                  <>
                    
                       {/* <img src={search} alt="icon" className={`img-icon ${topMenuIcons?.searchDisable ? 'disabled' : ''}`} onClick={!topMenuIcons?.searchDisable ? onSearching : ()=> {}} /> */}
              
                        <SearchFilter
                          hideLabel={true}
                          activeMenuIcons={activeMenuIcons}
                          value={activeMenuIcons?.search}
                          onClick={onSearching}
                          showSearchBar={showSearchBar}
                          className="top-menu-search non-filter-icon"
                          onSearch={onSearch}
                        />
                      </>
                
                  
                )}
              </div>
              {((brdActions && (topMenuIcons?.edit || topMenuIcons?.delete)) ||
                (brdExOption && topMenuIcons?.refresh) || (topMenuIcons?.userGuidePopup)||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length ||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {brdActions && (
            <>
            <div className="flex sm:gap-[0.42857rem] gap-[1rem]  cursor-pointer">
              {topMenuIcons?.edit && (
                // <img src={editIcon} className={`img-icon`} onClick={handleEdit} alt="icon" />
                <EditIcon onClick={handleEdit} />
              )}
              {topMenuIcons?.delete && (
                // <img src={trashIcon} className={`img-icon`} onClick={handleDelete} alt="icon" />
                <DeleteIcon onClick={handleDelete} />
              )}
            </div>
            {(
                (brdExOption && topMenuIcons?.refresh) ||
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length || (topMenuIcons?.userGuidePopup)||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
          {/* EDIT AND DELETE IN MY GALLERIES */}
          {brdExOption && (
            <>
            <div className="flex gap-4 cursor-pointer refresh-section">
              {topMenuIcons?.refresh && <img src={refresh} alt="icon" className={`${activeMenuIcons?.refresh ? 'active' : ''}`} onClick={handleRefresh} />}
            </div>
            {(
                
                (showTopMenus &&
                  ((brdBack && topMenuIcons?.back) ||
                    topMenuList?.length || (topMenuIcons?.userGuidePopup)||
                    infoDeskIcons))) && <BorderRight />}
            </>
          )}
       
        </div>
      )}

      {!!showTopMenus && (brdBack||topMenuIcons?.back||(topMenuList?.length || infoDeskIcons))&&(
        <div className={`flex items-center sm:gap-[1.43rem] sm:w-auto gap-[1rem] w-full ${menuLoading && 'opacity-0'}`}>
          {brdBack && (
            <>
              {topMenuIcons?.back && <div className="back flex gap-4 cursor-pointer">
                
                  {/* <img src={back} className={`img-icon`} alt="icon" onClick={handleBackBtn} /> */}
                  <BackIcon onClick={handleBackBtn} />
                
              </div>}
              {((topMenuList?.length || infoDeskIcons) || (topMenuIcons?.userGuidePopup || visibleUserGuideForSomePages)) && <BorderRight />}
            </>
          )}
          <div className="menu-list">
            <div className={`menu-container flex sm:gap-[1.5rem] gap-[0.625rem] cursor-pointer`}>
              {topMenuList?.map((menu, index) => {
                return (
                  <span
                    className={index === topActiveMenu ? "active" : "inactive"}
                    data-index={index}
                    onClick={(e)=>handleMenu(e,menu?.url)}
                    key={index}
                  >
                    {menu?.element}
                  </span>
                );
              })}
              {(infoDeskIcons ||( topMenuIcons?.userGuidePopup || visibleUserGuideForSomePages)) && <BorderRight />}
              {infoDeskIcons && (
                <>
                  <span className={""}>
                    <a
                      href="https://twitter.com/Cur8Labs"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img className="" src={Twitter} alt="twitter" />
                    </a>
                  </span>
                  <span className={""}>
                    <a
                      href="https://discord.gg/vHqfQ5vPFd"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img className="" src={Discord} alt="discover" />
                    </a>
                  </span>

                  <span className={""}>
                    <a
                      href="https://www.youtube.com/@cur8galleries"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <img src={Youtube} alt="twitter" />
                    </a>
                  </span>
                  <BorderRight />
                  <span className="support-button">
                    <a
                      href="https://discord.gg/K7Ds8fetKn"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      Support
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {(topMenuIcons?.userGuidePopup || visibleUserGuideForSomePages) && 
      <><div className={`flex items-center gap-[1.5rem] ${menuLoading ? 'opacity-0' : `opacity-100`}`}>
        <div className={``}>
          {/* <img src={InfoIcon} onClick={handleUserGuidePopup} className={`img-icon cursor-pointer`} alt="icon" /> */}
          <InformationIcon defaultColor={`#A0ABBB`} onClick={handleUserGuidePopup} />
        </div>
        </div> 
        </>}
    </div>    
  );
};

export default TopHeader;