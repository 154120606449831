import TemporaryMobileView from "App/Pages/TemporaryMobileView";
import { Route, Routes } from "react-router-dom";

const TemporaryRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<TemporaryMobileView />} />
      <Route path="*" element={<TemporaryMobileView />} />
    </Routes>
  );
};

export default TemporaryRoutes;
