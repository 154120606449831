import { useStore } from "App/hooks-store/store";
import useHttp from "App/hooks/use-http";
import axios from "axios";

const useProfileApiHelper = () => {
  const dispatch = useStore(false)[1];
  let { triggerAPI } = useHttp();

  const getProfile = (callback) => {
    triggerAPI(
      {
        url: `user/profile/get`,
        method: "get",
      },
      (res) => {
        dispatch("setGalleryCreateCount", res?.data?.availableGalleriesCount);
        dispatch(
          "setSubscriptionLimitReached",
          res?.data?.isSubscriptionLimitReached
        );
        dispatch(
          "setPremiumGalleryWallEnabled",
          res?.data?.isAvailablePremiumWall
        );
        dispatch(
          "setAnyWallDisplayGalleryCount",
          res?.data?.anywallDisplayCount
        );
        callback && callback(res?.data);
      }
    );
  };

  const getPlaces = async (searchQuery) => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { input: searchQuery },
      headers: {
        'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
        'x-rapidapi-host': process.env.REACT_APP_RAPID_API_HOST,
      }
    };

    try {
      const response = await axios.request(options);
      const placesOptions = [];
      response?.data?.predictions?.forEach((place) => {
        if (place?.terms?.length >= 2 && place?.terms?.length <= 4) {
          placesOptions.push({ id: place?.place_id, name: place?.description, value: place?.description, terms: place?.terms })
        }
      })
      return placesOptions;
    } catch (error) {
      return [];
    }
  }

  return { getProfile, getPlaces };
};
export default useProfileApiHelper;
