import "./TemporaryMobileView.scss";

const TemporaryMobileView = () => {
  return (
    <div className="temporary-mobile-view w-full">
      <div className="content">
        <h1>{`Please use the ${
          process.env.REACT_APP_COMPANY === "anywall" ? "Anywall" : "Cur8"
        } app on desktop.`}</h1>
      </div>
    </div>
  );
};

export default TemporaryMobileView;
