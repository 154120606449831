import useHttp from "App/hooks/use-http";
import { useEffect, useState } from "react";
import SliderGallery from "App/Components/Ui/SliderGallery";
import { useStore } from "App/hooks-store/store";
import SkeltonCard from "App/Components/Ui/SkeltonCard";
import BackgroundWallFilter from "App/Components/Ui/BackgroundWallFilter";
import useLayoutData from "App/hooks/use-layout";
import LayoutDesign from "./Components/Layouts";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentFormValues, setCurrentFormValues } from "App/ReduxStore/Slicers/gallerySlice";
import CustomImage from "App/Components/Ui/CustomImage";
import LockComponent from "App/Components/Ui/LockComponent";

const GalleryPreview = (props) => {
    const [list, setList] = useState();
    const [totalWallLists, setTotalWallLists] = useState([]);
    const { triggerAPI } = useHttp();
    const [{premiumGalleryWallEnabled}] = useStore(false);
    const [image, setImage] = useState(props?.selectedWall);
    const [spinnerShow, setSpinnerShow] = useState(true)
    const [selectedLayout, setSelectedLayout] = useState(null)
    const [layoutList, setLayoutList] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [noColor, setNoColor] = useState(false);
    const { getLayoutFromStore } = useLayoutData();
    const dispatch = useStore(false)[1];
    const dispatchRedux = useDispatch();
    const currentForm = useSelector(getCurrentFormValues)
    const onClickHandler = (e) => {
        if(!(!premiumGalleryWallEnabled&&e.currentTarget?.dataset?.types==="premium")){
            const newImage = e.currentTarget?.dataset?.image
        setImage(newImage)
        props?.OnSelectWall(newImage)

        inputOnChange({ twoDWallBackgroundId: Number(e.currentTarget?.dataset?.id) })
        }
    }
    const getWallResult = (res,fullList) => {
        const list = res.data
        const [dataset = {}] = res.data;
        setSpinnerShow(false);
       if(fullList){
        setTotalWallLists(list);
       }else{
        dispatch('setCurrentWallList', res?.data)
        setList(list)
        if (!currentForm?.id) {
            const newImageWall = dataset?.imageUrl
            if (!props?.selectedWall) {
                setImage(newImageWall)
                props?.OnSelectWall(newImageWall)
                inputOnChange({ twoDWallBackgroundId: dataset?.id })
            }
           // !noColor&& setColor(dataset?.color)
        }
       }

        //else{
           // !noColor&& setColor(currentFormValues?.thumbnailBackground?.color)
       // }
    }
    useEffect(() => {
        getLayoutFromStore((res) => {
            setLayoutList(res);
        })        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getWallDetails = (fullList) => {
        let data={color:"",style:""}
        if(!fullList){
            data={...data,color: props?.color, style: props?.style }
        }
        setSpinnerShow(true)
        triggerAPI({
            url: `background-thumbnail/user/get`, data: data, method: 'post'
        }, (res)=>{
            getWallResult(res,fullList)
        });
    }

    useEffect(() => {
        // if(props?.colorFetched)
            getWallDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.style, props?.color, props?.colorFetched])
    useEffect(() => {
            getWallDetails(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const inputOnChange = (e) => {
        dispatchRedux(setCurrentFormValues({...currentForm, ...e}));
    }
    useEffect(() => {
        if (currentForm?.twoDWallBackgroundId && currentForm?.id && !image) {
            const value = totalWallLists?.find((val) => val.id === currentForm?.twoDWallBackgroundId)?.imageUrl
            setImage(value)
            props?.OnSelectWall(value)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm?.id, totalWallLists])

    useEffect(() => {
        if (props?.selectedNfts?.length && layoutList?.length) {
            let count = props?.selectedNfts?.length >= 8 ? 8 : props?.selectedNfts?.length;
            let temp = props?.selectedNfts?.length !== currentForm?.assets?.length ? layoutList?.find(item => item?.count === count) : layoutList?.find(item => currentForm?.layoutId === item?.id) || layoutList?.find(item => item?.count === count)
                setSelectedLayout(temp);
                dispatchRedux(setCurrentFormValues({...currentForm, layoutId: temp?.id, Layout: temp}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.selectedNfts?.length, layoutList])
    
    
    return (


        <div className="flex flex-col items-start glry-arrang overflow-hidden gallery-preview gap-[1.25rem]">
           <div className="selection-head flex pt-[0.6rem] sm:hidden " >
                <div>
                    Gallery ({props?.selectedNfts?.length})
                </div>
            </div>
            <div className="tab-top-container gallery-preview-section w-full">
                {(!image && spinnerShow) ? <SkeltonCard noAbsolute={true} show={true} height="18.75rem" inline={true} containerClassName={`flex preview-skelton`} /> :
                    props?.selectedNfts?.length === 0 ?
                        <div className="py-5 flex justify-center">
                            No Nfts Added
                        </div> : image && props?.selectedNfts?.length !== 0 &&
                        <div className="preview-slider h-[18.75rem]">
                            <SliderGallery selectedLayout={selectedLayout} 
                                addedGallery={true} 
                                frameDetails={props?.frameDetails} 
                                src={props?.selectedWall} 
                                className="gallery-preview-wall flex justify-center items-center" 
                                selectedNfts={props?.selectedNfts}
                                sliderClass="gallery-slider-1"
                            />
                        </div>
                }
            </div>
            <div className="w-full background-list-wrapper overflow-y-auto overflow-x-hidden">
                <div className="sm:mx-[0.875rem] mx-0 sm:pr-0 pr-[0.45rem] pb-[1rem] filter-field flex justify-between flex-col gap-4 items-center mb-3">
                    <LayoutDesign layoutList={layoutList?.filter(item => item?.count <= props?.selectedNfts?.length)} selected={selectedLayout} setSelectedLayout={setSelectedLayout} count={props?.selectedNfts?.length >= 8 ? 8 : props?.selectedNfts?.length} />
                </div>
                <div className="sm:mx-[0.875rem] mx-0 sm:pr-0 pr-[0.45rem] pb-[0.375rem] flex justify-between items-center">
                    <span className=" text-left pb-1 flex w-full background-text">Background</span>
                    <BackgroundWallFilter setNoColor={setNoColor} colorFetched={props?.colorFetched} setColorFetched={props?.setColorFetched} style={props?.style} color={props?.color} setStyle={props?.setStyle} setColor={props?.setColor} />
                </div>
                <div className="flex wall-style sm:mx-[0.625rem] mx-0 pt-[0.625rem] w-full ">
                    {(!spinnerShow && !list?.length) && 
                        <div className="py-[2rem] flex justify-center w-full mr-[0.625rem] non-highlight-color">
                            No background walls found
                        </div>
                    }
                    {(spinnerShow) &&
                        <div className="w-full sm:mr-[1.5rem] mr-0" >
                            <SkeltonCard show={spinnerShow} height="4rem" count={9} noAbsolute={true} inline={true} containerClassName={`grid w-full cursor-pointer overflow-auto sm:mr-[1.5rem] mr-0 w-full`} />
                        </div>
                    }
                    {(!spinnerShow && list?.length) &&
                        <div className="grid w-full cursor-pointer overflow-auto overflow-x-hidden pb-[3rem] sm:mr-[1.5rem] mr-0" >
                            {list?.map((wall, index) =>
                                <div key={index} onClick={onClickHandler} data-types={wall?.types} data-image={wall?.imageUrl512} data-id={wall?.id} className={`wall-image-gallery relative ${!premiumGalleryWallEnabled&&wall?.types==="premium"&&"!cursor-not-allowed"}`}>
                                    <CustomImage src={wall?.imageUrl256} className={`${currentForm?.twoDWallBackgroundId === wall?.id ? 'add' : 'wall-border'}`} skeltonCardHeight={'4rem'} />
                               {!premiumGalleryWallEnabled&&wall?.types==="premium"&&<LockComponent message="Premium backgrounds only available for Premium Curator subscription plans."/>}
                                </div>
                            )}
                        </div> 
                    }

                </div>
            </div>
        </div>
    )
}

export default GalleryPreview