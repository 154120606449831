import { getCaptionControl } from "App/ReduxStore/Slicers/galleryPreviewSlice";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

const GalleryCaption = (props) => {
    const captionControl = useSelector(getCaptionControl);

    const [breakWord, setBreakWord] = useState('');
    const [QRLink, setQRLink] = useState('');

    const isOneWord = (word) => {
        const words = word?.split(' ');
        if (words?.length === 1) {
            setBreakWord('break-all');
        } else {
            setBreakWord('');
        }
    }

    const handleSetQRLink = (collectionId, communitySlug, categorySlug, fullURL) => {

        let assetURL = `discover/collection/${categorySlug}/details`;

        if (communitySlug) {
            assetURL = `discover/all-communities/${communitySlug}/collection`;
        }

        setQRLink(`${fullURL}/${assetURL}/${collectionId}`);
    }
    
    useEffect(()=>{
        if(!!props?.cardData?.userName){
            isOneWord(props?.cardData?.userName)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.cardData?.userName])

    useEffect(()=>{
        let fullURL = window !== undefined ? window?.location?.href : '';
        fullURL = fullURL?.split('/preview')[0];
        if ((!!props?.cardData?.communitySlug || !!props?.cardData?.categorySlug) && !!props?.cardData?.collectionId) {
            handleSetQRLink(props?.cardData?.collectionId, props?.cardData?.communitySlug, props?.cardData?.categorySlug, fullURL);
        } else {
            setQRLink(`${fullURL}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.cardData?.communitySlug, props?.cardData?.categorySlug]);

    return (
        <div className={`${props?.captionCardClass ?? ''} caption-container
            ${captionControl?.defaultPos === 'left' ? 'left-[5%]' : 'right-[5%]'} 
            absolute ${captionControl?.captionCard ? 'opacity-1 show' : 'opacity-0'}`}>

            <div className="w-full flex justify-between gap-4">
                {props?.isMono && captionControl?.QRCodeShow && <div className="p-1" style={{ maxHeight: 100, maxWidth: 100 }}>
                    <QRCode
                        className={`scaleUp-effect-1 transition-all`}
                        style={{ height: "100%", width: "100%" }}
                        value={QRLink}
                        bgColor={`transparent`}
                        fgColor={`#FFFFFF`}
                    />
                </div>}
                <div className="w-full flex flex-col justify-between h-auto  gap-4">

                    <span>{props?.cardData?.cardName}</span>

                    {props?.cardData?.assetName ? <span className="nft-name">{props?.cardData?.assetName}</span> : <span />}

                    <span className="created-name community-name">by
                        <span className={`capitalize ${breakWord}`}>{props?.cardData?.userName ? ` ${props?.cardData?.userName}` : ' Unknown'}</span>
                    </span>
                </div>
            </div>

        </div>
    )
}
export default memo(GalleryCaption);