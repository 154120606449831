import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'App/hooks-store/store';
import GalleryImages from "App/Components/Ui/GalleryFrameImages"
import SkeltonCard from 'App/Components/Ui/SkeltonCard';
import InputSlider from 'App/Components/Ui/InputSlider';
import InputColor from 'App/Components/Ui/InputColor';
import { getCurrentFormValues, setCurrentFormValues } from 'App/ReduxStore/Slicers/gallerySlice';
import { useDispatch, useSelector } from 'react-redux';
import './MonoSettings.scss';

const MonoSettings = (props) => {

    const whiteColor = '#ffffff';
    const blackColor = '#000000';
    const [{ currentSelectedNfts }] = useStore(false);
    const [imagesFrame, setImagesFrame] = useState({})
    const [loaderPreview, setLoaderPreview] = useState(false)
    const currentForm = useSelector(getCurrentFormValues)
    const dispatchRedux = useDispatch();
    const styleTypeTabs = useMemo(() => {
        return [{
            id: 1,
            name: 'Zoomed',
            value: 'ful-scr'
        }, {
            id: 2,
            name: 'Framed',
            value: 'frm',
        }, {
            id: 3,
            name: 'Customized',
            value: 'custom',
        }]
    }, [])
    const [activeTab, setActiveTab] = useState(currentForm?.monoStyleType ?? styleTypeTabs[0]?.value)
    const frameTypes = useMemo(() => {
        return [
            { id: 1, name: 'On', value: 1 },
            { id: 2, name: 'Off', value: 0 }
        ]
    }, []);
    const [imageLayout, setImageLayout] = useState(
        {
            size: currentForm?.monoCustomized?.imageLayout?.size ?? 0, 
            bgColor: currentForm?.monoCustomized?.imageLayout?.bgColor ?? blackColor
        })
    const [imageBorder, setImageBorder] = useState(
        {
            thickness: currentForm?.monoCustomized?.imageBorder?.thickness ?? 0, 
            bgColor_1: currentForm?.monoCustomized?.imageBorder?.bgColor_1 ?? whiteColor,
            bgColor_2: currentForm?.monoCustomized?.imageBorder?.bgColor_2 ?? whiteColor,
        })
    const [frameType, setFrameType] = useState(currentForm?.monoCustomized?.frameType ?? false)
    
    useEffect(() => {
        const val = currentSelectedNfts?.find((val) => val?.asset === props?.frameAssetNew?.asset)
        setImagesFrame({ ...val })
    }, [props?.frameAssetNew, currentSelectedNfts])
    const handleTabs = useCallback((e) => {
        let id = e.currentTarget?.dataset.id;
        setActiveTab(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm])
    const handleImageSize = useCallback((value) => {
        setTimeout(() => {
            setImageLayout(prev => { return {...prev, size: value}});
        }, 500);
    }, [])
    const onImageBgChange = useCallback((value) => {
        setImageLayout(prev => { return {...prev, bgColor: value}});
    }, [])
    const onImageThickChange = useCallback((value) => {
        setTimeout(() => {
            setImageBorder(prev => { return {...prev, thickness: value}})
        }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm, dispatchRedux])
    const onImageBg1Change = useCallback((value) => {
        setImageBorder(prev => { return {...prev, bgColor_1: value}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm])
    const onImageBg2Change = useCallback((value) => {
        setImageBorder(prev => { return {...prev, bgColor_2: value}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentForm, dispatchRedux])
    const handleFrameType = useCallback((e) => {
        let type = parseInt(e?.currentTarget?.dataset?.value);
        setFrameType(!!type)
    }, [])
    useEffect(() => {
        dispatchRedux(setCurrentFormValues({...currentForm, 
            monoCustomized: {imageLayout, imageBorder, frameType}, monoStyleType: activeTab
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageLayout, imageBorder, frameType, activeTab])
    const showFrame = useMemo(() => {
        return activeTab === 'ful-scr' || (activeTab === 'custom' && !currentForm?.monoCustomized?.frameType);
    }, [activeTab, currentForm?.monoCustomized?.frameType])
    const styles = useMemo(() => {
        let style = {background: blackColor}
        if(activeTab === 'custom') {
            let border = Number(currentForm?.monoCustomized?.imageBorder?.thickness)/2;
            let gradientColor = `linear-gradient(to right, ${currentForm?.monoCustomized?.imageBorder?.bgColor_1 ?? whiteColor}, ${currentForm?.monoCustomized?.imageBorder?.bgColor_2 ?? whiteColor})`;
            style = {...style, 
                        background: `${gradientColor}`, 
                        'borderWidth': `${border}rem`,
                        'borderImage': `${gradientColor} 1`,
                    }
        }
        return style;
    }, [activeTab, currentForm?.monoCustomized])
    const monoBackgroundStyle = useMemo(() => {
        let style = { background: blackColor }
        style = {...style, 
            background: `${currentForm?.monoCustomized?.imageLayout?.bgColor ?? blackColor}`                
        }
        if(activeTab === 'custom') {
            let border = Number(currentForm?.monoCustomized?.imageBorder?.thickness)/2;
            const basePadding = 5;
            let newpadding = basePadding - Number(currentForm?.monoCustomized?.imageLayout?.size ?? 0)
            style = {...style, 
                borderRadius: border > 0 ? '6px' : '0px',
                'padding': `${newpadding}%` ?? '0%',    
            }
        }
            
        return style;
    }, [currentForm?.monoCustomized?.imageLayout?.bgColor, activeTab, currentForm?.monoCustomized?.imageLayout?.size, currentForm?.monoCustomized?.imageBorder?.thickness])
    return (
        <>
            <div className='tab-top-container relative mono-preview'>
                <SkeltonCard show={loaderPreview} noAbsolute={false} height="100%" inline={true}
                    containerClassName="absolute top-0 bottom-0 left-0 right-0 w-full h-full flex frame-spinner justify-center items-center z-9" />
                <div className={`${loaderPreview ? 'opacity-0' : `${activeTab} opacity-100 h-full`} min-h-[15.75rem]`} style={styles}> 
                    <div className='mono-image-container h-full' style={monoBackgroundStyle}>
                        <GalleryImages 
                            key={imagesFrame?.nftcdnimage512+showFrame}
                            setLoaderPreview={setLoaderPreview} className="h-full w-full" 
                            isDetailedView={true} nftGalleryNew={true} noFrame={showFrame} gallery={true} 
                            galleryNew={imagesFrame} src={imagesFrame?.nftcdnimage512} frameClass="" frameName={'Details'} 
                        />
                    </div>
                    
                </div>
            </div>
            <div className={`steps-container mono-settings-body`}>
                <div className='flex'>
                    Layout
                </div>
                <div className={`${activeTab === 'custom' && 'bottom-line'}  tabs`}>
                    {styleTypeTabs?.map(item => <div className={`tab-item ${activeTab === item?.value && 'active'}`}
                    onClick={handleTabs} key={item?.id} data-id={item?.value}>{item?.name}</div> )}
                </div>
                {activeTab === 'custom' && 
                    <>
                        <div className='item-container'>
                            <div className='flex'>Layout</div>
                            <div className='flex gap-4'>
                                <div className="items-data">
                                    <span>Image Size</span>
                                    <div className="flex flex-1">
                                        <InputSlider step={0.5} onChange={handleImageSize} value={currentForm?.monoCustomized?.imageLayout?.size ?? 0} max={5}/>
                                    </div>
                                </div>
                                <div className="items-data">
                                    <span>Background Color</span>
                                    <InputColor value={currentForm?.monoCustomized?.imageLayout?.bgColor ?? blackColor} onChange={onImageBgChange} />
                                </div>
                            </div>
                        </div>
                        <div className='item-container'>
                            <div className='flex'>Border</div>
                            <div className='flex gap-4'>
                                <div className="items-data">
                                    <span>Thickness</span>
                                    <div className="flex flex-1">
                                    <InputSlider step={0.5} onChange={onImageThickChange} value={currentForm?.monoCustomized?.imageBorder?.thickness ?? 0} max={5} />
                                    </div>
                                </div>
                                <div className="items-data">
                                    <span>Colors</span>
                                    <div className="flex gap-4">
                                        <InputColor value={currentForm?.monoCustomized?.imageBorder?.bgColor_1 ?? whiteColor} onChange={onImageBg1Change} />
                                        <InputColor value={currentForm?.monoCustomized?.imageBorder?.bgColor_2 ?? whiteColor} onChange={onImageBg2Change} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item-container btm-brd-none'>
                            <div className='flex'>Frame</div>
                            <div className='flex gap-4'>
                                {frameTypes?.map(type => {
                                    return  <span data-value={type?.value} className={`cursor-pointer frame-toggle ${type?.value === Number(currentForm?.monoCustomized?.frameType ?? 0) && `text-active`}`} 
                                        onClick={handleFrameType} key={type?.id}>
                                        {type?.name}
                                    </span>
                                })}
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default MonoSettings