import React from 'react'
import Label from '../Label'
import Popup from '../Popup'
import RoundedButton from '../RoundedButton'

const WarningBox = props => {
    return (
        <Popup handleClose={props.onCancel}>
            <div className='px-4'>
                <Label className="mb-6 warning-msg">{props.children}</Label>
                <div className='flex justify-around gap-2.5'>
                    {props?.onApprove && <div className="flex-1">
                        <RoundedButton OnClick={props.onApprove} Type="17" Name={props.approveBtn}></RoundedButton>
                    </div>}
                    {props?.cancelBtn && <div className="flex-1">
                        <RoundedButton OnClick={props.onCancel} Type="18" Name={props.cancelBtn}></RoundedButton>
                    </div>}
                </div>
            </div>
        </Popup>
    )
}

export default WarningBox
