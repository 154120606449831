import Card from "App/Components/Card";
import DetailsSkeltonCard from "App/Components/Ui/detailsSkeleton";
import useScreenSize from "App/hooks/use-screen-size";
import { Fragment, useEffect } from "react";
import "./GalleryList.scss";
import privateGalleryBanner from "Assets/Images/private-gallery-banner.png";
import rentalsGalleryBanner from "Assets/Images/rentals-gallery-banner.png";
import NoContent from "App/Pages/Collections/Components/NftCollectionsV3/Components/NoContent";
import { ImageLoader } from "App/Helper/utilities";

const GalleriesList = ({
  showGallerySkelton,
  galleryList,
  tab,
  onGalleryPreviewHandler,
  setPrimaryHandler,
  selectedCollection,
  onCancel,
  handleEdit,
  handlePreview,
  onClickDeleteGallery,
  onGalleryCreateClick,
  onNavigateToExploreRentals
}) => {
  const screensize = useScreenSize()?.width;
  useEffect(()=>{
    ImageLoader(rentalsGalleryBanner);
    ImageLoader(privateGalleryBanner);
  },[])
  return (
    <div className={`gallery-listing ${!showGallerySkelton&&!galleryList?.length&&"h-full flex justify-center items-center"}`}>
      {!showGallerySkelton ? (
        <div>
          {galleryList?.length ? (
            <div
              className={`pt-0 p-4 pr-sm grid gap-2.5 ${
                screensize > 550 ? "home-grid" : ""
              }  gallery-top-sm`}
            >
              {galleryList?.map((info, i) => {
                info = { ...info, createdAt: info?.nftDropStartedAt };
                return (
                  <Fragment key={i}>
                    <div
                      key={i}
                      data-id={info?.id}
                      onClick={(e) =>
                        onGalleryPreviewHandler(e, info?.disableGallery)
                      }
                      className="cursor-pointer"
                    >
                      <Card
                        key={i}
                        cardProp={info}
                        type="Gallery"
                        tab={tab}
                        setPrimaryHandler={setPrimaryHandler}
                        selectedCollection={selectedCollection}
                        preview={!info?.disableGallery}
                        showEdit={info?.disableGallery}
                        handleEdit={handleEdit}
                        handlePreview={handlePreview}
                        onClickDeleteGallery={onClickDeleteGallery}
                        onCancel={onCancel}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <>{["private","public"]?.includes(tab)?
            <NoContent banner={privateGalleryBanner} className="visibleSlowly" text="Beauty is in The Eye of The Be(holder)" brief="Start curating stunning galleries with your assets here" onClick={onGalleryCreateClick} buttonText="Create gallery" />
            // <CreateGalleryBox className="visibleSlowly" onClick={onGalleryCreateClick} />
            :tab==="rentals"? 
            <NoContent className="visibleSlowly" banner={rentalsGalleryBanner} text="Access the World's Collections" brief="Rent beautiful galleries from top creators to display whatever  you want  whenever you want" onClick={onNavigateToExploreRentals} buttonText="Explore rentals" /> :
            <div className="visibleSlowly flex items-center no-data justify-center">{`There are currently no ${tab} galleries.`}</div>}</>
          )}
        </div>
      ) : (
        <div className={`pt-0 p-4 pr-sm`}>
          <DetailsSkeltonCard
            show={true}
            height={"100%"}
            count={9}
            inline={true}
            details={"1-line"}
            noAbsolute
            parentDiv="grid home-grid mt-0 aspect-pt-six details-gap"
            containerClassName={`flex`}
          />
        </div>
      )}
    </div>
  );
};
export default GalleriesList;
